import { useContext } from 'react';
import { Help, Chat } from '@material-ui/icons';
import Link from '@material-ui/core/Link';

import MasterPageFooter from './MasterPageFooter';

import { MasterPageContext } from './masterPageContext';

const FeedbackFooter = ({
  pageLabel = 'Dashboard',
}) => {
  const { appVersion, setFeedbackDialogVisible, setFeedbackDialogTitle } = useContext(MasterPageContext);

  return (
    <MasterPageFooter version={appVersion}>
      <div className="feedback-footer">
        <div className="feedback-footer-item">
          <Help color="primary" />
          &nbsp;
          <span>Learn more about</span>
          &nbsp;
          <Link className="link">{pageLabel}</Link>
        </div>
        <div className="feedback-footer-separator">|</div>
        <div
          className="feedback-footer-item"
          onClick={() => {
            setFeedbackDialogTitle(pageLabel);
            setFeedbackDialogVisible(true);
          }}
        >
          <Chat color="primary" />
          &nbsp;
          <Link className="link">Send Feedback</Link>
        </div>
      </div>
    </MasterPageFooter>
  );
};

export default FeedbackFooter;
