import { useEffect, useMemo, useRef, useState } from "react";
import { Link, Tooltip } from "@material-ui/core";

import "../../styles/scss/table-styles/reusableTable.scss";
import { Shipment } from "../../@type/Shipment";
import { getShipmentsByAdmin } from "../../apis/shipmentsService";
import { roundAfterDecimalPoint } from "../../utils/commonFunctions";
import { useTableStyles } from "../manage-customers/ManageCustomers";
import ReusableControlledTable from "../utilities/ReusableControlledTable";
import { TableSearchFilterWrapper } from "../utilities/TableComponent";
import useDebounce from "../utilities/useDebounce";
import useCustomerLogin from "../../hooks/useCustomerLogin";
import TrackingNumberLink from "../tracking-number-link/TrackingNumberLink";

const Shipment_CARRIER_MAP = {
  CANADAPOST: "CanadaPost",
  UPS: "UPS",
  FEDEX: "FedEx",
  CANPAR: "Canpar",
  USPS: "USPS",
};

// const Shipment_TYPE_MAP = {
//   CONTRACT: "Contract",
//   "NON-CONTRACT": "Non-Contract",
// };

const TRANSACTION_PAYMENT_METHOD_MAP = {
  CREDITCARD: "Credit Card",
  SVCREDIT: "SVCredit",
  SVCASH: "SVCash",
  INVOICE: "Invoice",
};

const TRANSACTION_STATUS_MAP = {
  CREATED: "Created",
  CANCELED: "Canceled",
};
const INSURANCE_TYPE_MAP = {
  NONE: "No Insurance",
  INSURESHIELD: "InsureShield",
  INSUREVISTA: "InsureVista",
};

// const TRANSACTION_STATUS_COLOR_MAP = {
//   [TRANSACTION_STATUS_MAP.CREATED]: "green",
//   [TRANSACTION_STATUS_MAP.CANCELED]: "red",
// };

// const CARRIER_LOGO_SRC_MAP = {
//   CanadaPost: "/images/carrierLogos/CanadaPost.png",
//   UPS: "/images/carrierLogos/UPS.png",
//   FEDEX: "/images/carrierLogos/fedex.png",
//   CANPAR: "/images/carrierLogos/canpar.png",
//   USPS: "/images/carrierLogos/usps.png",
//   SV: "/favicon.png",
// };

const ShipmentHistory = () => {
  const classes = useTableStyles();
  const [isTableLoading, setTableLoading] = useState<
    "Loading" | "Failed" | "Success" | "NoItem"
  >("Success");
  const [shipments, setShipments] = useState<Shipment[]>([]);

  const [selectedCarrierAccount] = useState("");
  const [dateFilter] = useState("");
  const [sortVal, setSortVal] = useState(null);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [itemsTotal, setItemsTotal] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  // const [sortColumn, setSortColumn] = useState<string>(null);
  const [sortType, setSortType] = useState<"ASC" | "DESC" | null>(null);
  const [searchFilter, setSearchFilter] = useState("");
  const [selectedShipmentTypeDropdown] = useState("");
  const [selectedCarrierIndexMap] = useState({});
  const [selectedPaymentMethodIndexMap] = useState({});
  const [selectedStatusIndexMap] = useState({});
  const [selectedInsuranceTypeIndexMap] = useState({});
  // const [Shipment_CARRIER_ACCOUNT_MAP, setShipment_CARRIER_ACCOUNT_MAP] = useState([]);
  const { handleLoginClick } = useCustomerLogin();

  // const [paginChange, setPaginChange] = useState<PaginChange>({
  //   old: 1,
  //   new: 1,
  // });
  // const [currentPagination, setCurrentPagination] = useState<Pagination>({
  //   prev: null,
  //   next: null,
  //   pageSize: 20,
  //   itemsTotal: 0
  // });
  // const [pagination, setPagination] = useState<Pagination>({
  //   prev: null,
  //   next: null,
  //   pageSize: 20,
  //   itemsTotal: 0
  // });

  const searchFilterDebounce = useDebounce(searchFilter, 500);

  const isFirstRun = useRef(true);

  const getShipmentStatus = (row) => {
    const { shipmentStatus } = row;

    switch (shipmentStatus) {
      case "created":
      default:
        return TRANSACTION_STATUS_MAP.CREATED;
      case "canceled":
        return TRANSACTION_STATUS_MAP.CANCELED;
    }
  };

  const getShipments = async () => {
    setTableLoading("Loading");

    let sortValParam = sortVal;

    switch (sortVal) {
      case "tracking.pin":
        sortValParam = "trackingnumber";
        break;
      case "shipmentServiceNameString":
        sortValParam = "service";
        break;
      case "shippingCarrierAccountType":
        sortValParam = "carrieraccount";
        break;
      default:
        break;
    }

    const selectedCarrierIndexes = Object.keys(selectedCarrierIndexMap).filter(
      (e) => selectedCarrierIndexMap[e]
    );
    const selectedCarriers = Object.keys(Shipment_CARRIER_MAP).filter((e, i) =>
      selectedCarrierIndexes.includes(i.toString())
    );

    const selectedPaymentMethodIndexes = Object.keys(
      selectedPaymentMethodIndexMap
    ).filter((e) => selectedPaymentMethodIndexMap[e]);
    const selectedPaymentMethods = Object.keys(
      TRANSACTION_PAYMENT_METHOD_MAP
    ).filter((e, i) => selectedPaymentMethodIndexes.includes(i.toString()));

    const selectedStatusIndexes = Object.keys(selectedStatusIndexMap).filter(
      (e) => selectedStatusIndexMap[e]
    );
    const selectedStatuses = Object.keys(TRANSACTION_STATUS_MAP).filter(
      (e, i) => selectedStatusIndexes.includes(i.toString())
    );
    const selectedInsuranceTypeIndexes = Object.keys(
      selectedInsuranceTypeIndexMap
    ).filter((e) => selectedInsuranceTypeIndexMap[e]);
    const selectedInsuranceType = Object.keys(INSURANCE_TYPE_MAP).filter(
      (e, i) => selectedInsuranceTypeIndexes.includes(i.toString())
    );

    try {
      const requestBody = {
        pageIndex,
        pageSize,
        sortValParam,
        sortType,
        searchText: searchFilterDebounce,
        dateFilter,
        selectedCarriers,
        selectedCarrierAccount,
        selectedShipmentTypeDropdown,
        selectedPaymentMethods,
        selectedStatuses,
        selectedInsuranceType,
      };

      const { data } = await getShipmentsByAdmin(requestBody);

      if (!data?.records?.length) {
        setTableLoading("NoItem");

        return null;
      }

      let totalCADAmount = 0;
      let totalUSDAmount = 0;

      if (!data?.records) {
        setShipments([]);
        setItemsTotal(0);

        return;
      }

      const newData = data.records.map((e) => {
        const { total, currency } = e;

        if (currency === "USD") {
          totalUSDAmount = roundAfterDecimalPoint(totalUSDAmount + total);
        } else {
          totalCADAmount = roundAfterDecimalPoint(totalCADAmount + total);
        }
        const newTotal = `${currency} ${total}`;

        return {
          ...e,
          total: newTotal,
          paymentMethod: TRANSACTION_PAYMENT_METHOD_MAP[e.paymentMethod],
          status: getShipmentStatus(e),
          shipmentServiceNameString: e.shipmentServiceName ?? "",
        };
      });

      setShipments(newData);
      setItemsTotal(data.total_records);
      setTableLoading("Success");
    } catch (error) {
      setTableLoading("Failed");
      console.log(error);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        Cell: ({ row }) => row.original.id,
      },
      {
        Header: "Account No",
        accessor: "accountNo",
        Cell: ({ row }) => (
          <Link onClick={() => handleLoginClick(row.original.accountNo)}>
            {row.original.accountNo}
          </Link>
        ),
      },
      {
        Header: `Shipment ID`,
        accessor: "shipmentId",
        Cell: ({ row }) => row.original.shipmentId ?? "",
      },
      {
        Header: `Tracking Pin`,
        accessor: "trackingNumber",
        Cell: (props) => {
          const { isMultipleShipment, trackingNumber, shippingCarrier } =
            props.row.original;

          if (
            !isMultipleShipment &&
            (props.value === "NO TRACKING PIN" || props.value === "")
          ) {
            return <span>NO TRACKING PIN</span>;
          }

          return (
            <TrackingNumberLink
              value={trackingNumber}
              shippingCarrier={shippingCarrier}
            />
          );
        },
      },
      {
        Header: `Ship Date`,
        accessor: "shipDate",
        Cell: ({ row }) =>
          new Date(row.original.shipDate).toLocaleDateString() ?? "",
      },
      {
        Header: `Service Name`,
        accessor: "shipmentServiceName",
        Cell: ({ row }) => row.original.shipmentServiceName,
      },
      {
        Header: "Base",
        accessor: "baseCharge",
        Cell: ({ row }) => row.original.baseCharge,
      },
      {
        Header: "Fuel",
        accessor: "fuelCharge",
        Cell: ({ row }) => row.original.fuelCharge,
      },
      {
        Header: "Insurance",
        accessor: "totalCoverage",
        Cell: ({ row }) => row.original.totalCoverage,
      },
      {
        Header: "Taxes",
        accessor: "gst",
        Cell: ({ row }) => row.original.gst,
      },
      {
        Header: "Discount",
        accessor: "discount",
        Cell: ({ row }) => row.original.discount,
      },
      {
        Header: "Total",
        accessor: "total",
        Cell: ({ row }) => row.original.total,
      },
      {
        Header: "Profit",
        accessor: "profit",
        Cell: ({ row }) => row.original.profit ?? 0,
      },
      {
        Header: "Status",
        accessor: "shipmentStatus",
        Cell: ({ row }) => row.original.shipmentStatus,
      },

      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    [handleLoginClick]
  );

  // const goToShipmentPageHandler = () => {
  //   resolveSelectedMenuKey(MENU_MAP.SHIPMENTS_CREATE_SHIPMENT.key);
  //   resolveHeaderMenuKey(MENU_MAP.SHIPMENTS_CREATE_SHIPMENT.route);
  //   history.push(MENU_MAP.SHIPMENTS_CREATE_SHIPMENT.route);
  //   setShipmentsPageKey(new Date().toString());
  // };

  useEffect(() => {
    getShipments();
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, pageSize, searchFilterDebounce]);

  return (
    <div className="reusable-table-content">
      <div className="reusable-table-content-header">
        <h4>Shipment History</h4>
      </div>
      <div className="reusable-table-main-content">
        <div style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "50px",
              whiteSpace: "nowrap",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <TableSearchFilterWrapper
                globalSearch={searchFilter}
                setGlobalSearch={(value) => {
                  setSearchFilter(value);
                }}
              />
              {/* <TableSelectFilterWrapper
                emptyBtnName="CARRIER ACCOUNT"
                btnName={selectedCarrierAccount}
                list={Shipment_CARRIER_ACCOUNT_MAP}
                setSelect={(e) => {
                  setSelectedCarrierAccount(e);
                  getShipments();
                }}
              />
              <TableSelectFilterWrapper
                emptyBtnName="SHIPMENT TYPE"
                btnName={selectedShipmentTypeDropdown}
                list={Object.values(Shipment_TYPE_MAP)}
                setSelect={(e) => {
                  setSelectedShipmentTypeDropdown(e);
                  getShipments();
                }}
              /> */}
            </div>
          </div>
        </div>
        <div className="reusable-table-content-wrapper">
          <ReusableControlledTable
            data={shipments}
            columns={columns}
            selectedRowIds={selectedRowIds}
            onSelectedRowIdsChange={setSelectedRowIds}
            isLoading={isTableLoading === "Loading"}
            placeholder={
              isTableLoading === "Failed" ? (
                "Retrieve Shipment Failed."
              ) : (
                <span>There are no shipments yet.</span>
              )
            }
            placeholderStyle={
              isTableLoading === "Failed" ? { color: "red" } : {}
            }
            paginInfo="*All the amount are CAD"
            sortVal={sortVal}
            setSortVal={(e) => {
              setSortVal(e);
              getShipments();
            }}
            sortType={sortType}
            setSortType={(e) => {
              setSortType(e);
              getShipments();
            }}
            itemsTotal={itemsTotal}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            pageSizes={[50, 100]}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        </div>
      </div>
    </div>
  );
};

export default ShipmentHistory;
