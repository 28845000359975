import React, { useState } from "react";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import ImageDisplayDialog from "./ImageDisplayDialog";
import ImageDialog from "../../../utilities/ImageDialog";
import { createStyles, makeStyles } from "@material-ui/core";
import { HeadsetMic } from "@material-ui/icons";
const styles = {
  generalContainer: {
    marginTop: "0px",
    marginLeft: "62px",
    width: "90%",
    border: "solid",
    borderLeftWidth: "7px",
    borderLeftColor: "#c4e4fc",
    borderTopColor: "lightgrey",
    borderRightColor: "lightgrey",
    borderBottomColor: "lightgrey",
    borderTopWidth: "1px",
    borderRightWidth: "1px",
    borderBottomWidth: "1px",
  } as React.CSSProperties,

  cirleSmallButton: {
    border: "solid",
    marginLeft: "10px",
    borderRadius: "100%",
    height: "25px",
    width: "25px",
    borderWidth: "2px",
    borderColor: "lightgrey",
    backgroundColor: "transparent",
  } as React.CSSProperties,

  liner: {
    borderLeft: "solid",
    height: "15px",
    marginLeft: "30px",
    borderLeftColor: "lightgrey",
    borderLeftWidth: ".1rem",
  } as React.CSSProperties,

  attachmentContainer: {
    position: "relative",
    cursor: "pointer",
    color: "blue",
    width: "auto",
    display: "inline-block",
    border: "solid",
    borderColor: "transparent",
  } as React.CSSProperties,

  attachmentLabel: {
    position: "relative",
    width: "auto",
    height: "100%",
    display: "inline-block",
  } as React.CSSProperties,

  attachLabel: {
    cursor: "pointer",
    textDecoration: "underline",
    "&:hover": {
      backgroundColor: "red",
      color: "black",
    },
  } as React.CSSProperties,

  attachmentIcon: {
    position: "relative",
    width: "auto",
    height: "100%",
    display: "inline-block",
  } as React.CSSProperties,
};

const useStyles = makeStyles(() =>
  createStyles({
    linkButton: {
      position: "relative",
      width: "auto",
      height: "100%",
      display: "inline-block",
      color: "#069",
      textDecoration: "underline",
      cursor: "pointer",
      "&:hover": {
        color: "#0099E6",
      },
    },
  })
);

const ThreadDialog = (props) => {
  const [ticketDialogVisible, setTicketDialogVisible] = useState(false);
  const [fileName, setFileName] = useState("");
  const setVisible = (file) => {
    setFileName(file);
    setTicketDialogVisible(true);
  };

  const getAlternativeColor = (
    isAdmin: boolean,
    isRecent: boolean,
    isMain: boolean
  ) => {
    if (isMain) {
      return "#c4e4fc";
    }
    if (isAdmin) {
      if (isRecent) {
        return "#73E090";
      } else {
        return "#c4e4fc";
      }
    } else {
      if (isRecent) {
        return "#73E090";
      } else {
        return "#FC9C9C";
      }
    }
  };

  const classes = useStyles();
  return (
    <div>
      <ImageDialog
        title={`Create New Tickets`}
        maxWidth="md"
        setOpen={setTicketDialogVisible}
        dialogId="feedback-dialog"
        open={ticketDialogVisible}
      >
        <ImageDisplayDialog fileName={fileName} />
      </ImageDialog>

      <div
        style={{
          marginTop: "0px",
          marginLeft: "5px",
          border: "solid",
          borderLeftWidth: "7px",
          borderLeftColor: getAlternativeColor(
            props.isAdmin,
            props.isRecentlyLoaded,
            props.isMain
          ),
          borderTopColor: "lightgrey",
          borderRightColor: "lightgrey",
          borderBottomColor: "lightgrey",
          backgroundColor: "transparent", //FEE6E6
          borderTopWidth: "1px",
          borderRightWidth: "1px",
          borderBottomWidth: "1px",
        }}
      >
        {props.isMain === true ? (
          <div>
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                paddingBottom: "10px",
                borderBottom: "solid",
                borderColor: "lightgray",
                borderWidth: "1px",
              }}
            >
              <div
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "bold",
                  paddingLeft: "10px",
                }}
              >
                {props.summary}
              </div>
              <div
                style={{
                  fontStyle: "italic",
                  marginLeft: "auto",
                  marginRight: "20px",
                  paddingLeft: "10px",
                }}
              >
                At {props.createdAt}
              </div>
            </div>
            <div
              style={{
                paddingLeft: "10px",
                marginTop: "10px",
                marginRight: "20px",
                marginBottom: "10px",
              }}
            >
              <div style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Description
              </div>
              {props.description}
              <div>
                {props.attachments.map((a, index) => (
                  <div
                    style={styles.attachmentContainer}
                    onClick={() => setVisible(a.fileName)}
                  >
                    <div style={styles.attachmentLabel}>
                      <label className={classes.linkButton}>
                        Attachment {index}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div style={{ marginTop: "5px", display: "flex" }}>
              {(() => {
                switch (props.isAdmin) {
                  case false:
                    return (
                      <AccountCircleRoundedIcon
                        style={{ color: "lightgrey" }}
                        fontSize="large"
                      />
                    );

                  default:
                    return (
                      <HeadsetMic
                        style={{ color: "lightgrey" }}
                        fontSize="large"
                      />
                    );
                }
              })()}

              {(() => {
                switch (props.isAdmin) {
                  case true:
                    return (
                      <div style={{ fontWeight: "bold", paddingLeft: "10px" }}>
                        {props.createdBy}:{" "}
                      </div>
                    );

                  default:
                    return (
                      <div style={{ fontWeight: "bold", paddingLeft: "10px" }}>
                        User - {props.createdBy}:{" "}
                      </div>
                    );
                }
              })()}

              <div
                style={{
                  fontStyle: "italic",
                  marginLeft: "auto",
                  marginRight: "20px",
                  paddingLeft: "10px",
                }}
              >
                At {props.createdAt}
              </div>
            </div>
            <div
              style={{
                marginLeft: "45px",
                marginTop: "5px",
                marginRight: "20px",
                marginBottom: "10px",
              }}
            >
              {props.description}
              {props.attachments.map((a, index) => (
                <div style={{ marginLeft: "-5px" }}>
                  <div
                    style={styles.attachmentContainer}
                    onClick={() => setVisible(a.fileName)}
                  >
                    <div style={styles.attachmentLabel}>
                      <label className={classes.linkButton}>
                        Attachment {index + 1}
                      </label>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <div style={styles.liner}></div>
    </div>
  );
};

export default ThreadDialog;
