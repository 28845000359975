import { useState } from 'react'
import { IconButton, Link } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
import moment from 'moment'

import FixedDialogWrapper from '../utilities/FixedDialogWrapper'
import useCustomerLogin from '../../hooks/useCustomerLogin'

const CustomerDetailsDialog = ({ customer }) => {
  const [customerDetailsDialog, setCustomerDetailsDialog] = useState(false);
  const { handleLoginClick } = useCustomerLogin();

  return (
    <>
      <IconButton size='small' color='primary' onClick={() => setCustomerDetailsDialog(s => !s)}>
        <InfoOutlined />
      </IconButton>
      <FixedDialogWrapper
        dialogId="customer-details"
        title="Customer Details"
        maxWidth='md'
        open={customerDetailsDialog}
        setOpen={setCustomerDetailsDialog}
        buttonDefinitions={[]}
      >
        <div style={{ padding: "20px" }}>
          Account No: <Link onClick={() => handleLoginClick(customer?.account_no)}>{customer?.account_no}</Link>
          <br />
          User Level:	{customer?.user_level}
          <br />
          User ID:	{customer?.user_id}
          <br />
          Company: {customer?.address?.companyName}
          <br />
          Contact: {customer?.address?.name}
          <br />
          Address1: {customer?.address?.streetAddress}
          <br />
          Address2: {customer?.address?.streetAddress2}
          <br />
          City: {customer?.address?.city}
          <br />
          Country: {customer?.address?.country}
          <br />
          Code: {customer?.address?.countryCode}
          <br />
          State: {customer?.address?.state}
          <br />
          State Code: {customer?.address?.stateCode}
          <br />
          Postal Code: {customer?.address?.postalCode}
          <br />
          Phone: {customer?.address?.phone}
          <br />
          Email: {customer?.email}
          <br />
          Fax: {customer?.fax}
          <br />
          IP Address: {customer?.ipaddress}
          <br />
          Email: {customer?.email}
          <br />
          Created: {moment(customer?.signupDate).format("YYYY/MM/DD h:mm a")}
        </div>
      </FixedDialogWrapper>
    </>
  )
}

export default CustomerDetailsDialog