import { combineReducers } from "redux";
import { carrierSetupReducer } from "./carrierSetupReducer";
import { dialogReducer } from "./dialogReducer";
import { alertReducer } from "./alertReducer";
import { popoverReducer } from "./popoverReducer";
import { getQuoteReducer } from "./quickQuote/getQuoteReducer";
import { carrierServiceReducer } from "./quickQuote/carrierServiceReducer";
import { storeSetupReducer } from "./storeSetupReducer";
import { ordersReducer } from "./ordersReducer";
import { tokenReducer } from "./tokenReducer";
import { userReducer } from "./userReducer";
import { boxesReducer } from "./boxesReducer";
import { transactionHistoryReducer } from "./transactionHistoryReducer";
import { notificationReducer } from "./notificationReducer";

export const rootReducer = combineReducers({
    dialog: dialogReducer,
    carrierSetup: carrierSetupReducer,
    alert: alertReducer,
    popover: popoverReducer,
    getQuote: getQuoteReducer,
    carrierService: carrierServiceReducer,
    storeSetup: storeSetupReducer,
    orders: ordersReducer,
    token: tokenReducer,
    user: userReducer,
    boxes: boxesReducer,
    transactionHistory: transactionHistoryReducer,
    notification: notificationReducer,
});

export type RootState = ReturnType<typeof rootReducer>;