import {
  Button,
  CircularProgress,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { AttachFile, ChevronLeft, Reply } from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import React, { useEffect, useState } from "react";
import {
  closeTicketById,
  createReplyToTicket,
  getResponsesTicketById,
  getTicketById,
  uploadFileToTicketResponse,
} from "../../../../apis/customerSupportService";
import ThreadDialog from "./ThreadDialog";
import { useAlertActions } from "../../../../redux/actions/alertActions";
import TicketReopenDialog from "./TicketReopenDialog";

// import TicketsDialog from "../../../utilities/TicketsDialog";
// import ImageDisplayDialog from "./ImageDisplayDialog";

const useStyles = makeStyles(() =>
  createStyles({
    headerTitle: {
      marginTop: "6px",
      marginLeft: "10px",
      marginBottom: "8px",
      fontSize: "1.5rem",
    },

    bottomBorderedGrid: {
      borderBottom: "solid",
      borderBottomColor: "black",
      borderBottomWidth: ".5px",
    },

    ticketHeadContainer: {
      marginTop: "10px",
      paddingRight: "30px",
    },

    idClass: {
      marginLeft: "10px",
      marginTop: "5px",
      fontWeight: "bold",
    },

    normalText: {
      marginLeft: "10px",
      marginTop: "5px",
      fontWeight: "normal",
    },

    statusIcon: {
      width: "13px",
      height: "13px",
      marginRight: "5px",
      marginLeft: "5px",
      borderRadius: "50%",
    },

    createdAtStyle: {
      marginTop: "5px",
      fontWeight: "bold",
      marginLeft: "auto",
      marginRight: "-15px",
      "@media (max-width: 1280px)": {
        marginLeft: "65px",
        marginRight: "1px",
      },
    },

    closedDialog: {
      marginRight: "3px",
      marginTop: "40px",
      overflowY: "auto",
      height: `calc(100vh - 270px)`,
      "@media (max-width: 1280px )": {
        marginTop: "80px",
        height: `calc(100vh - 340px)`,
      },
    },

    buttonStyle: {
      height: "30px",
      width: "160px",
      color: "white",
      textTransform: "none",
    },

    sort: {
      cursor: "pointer",
      "&:hover": {
        color: "#0099E6",
        textDecoration: "underline",
      },
      display: "flex",
      flexDirection: "row",
    },
  })
);

const styles = {
  buttonStyle: {
    height: "30px",
    width: "120px",
    textTransform: "none",
    backgroundColor: "green",
  } as React.CSSProperties,

  replyStyle: {
    height: "30px",
    color: "white",
    textTransform: "none",
    backgroundColor: "#047cfc",
  } as React.CSSProperties,

  replyCloseStyle: {
    height: "30px",
    width: "165px",
    fontSize: "small",
    textTransform: "none",
    backgroundColor: "transparent",
    borderColor: "#047cfc",
    color: "#047cfc",
  } as React.CSSProperties,

  attachButtonStyle: {
    height: "40px",
    width: "100px",
    fontSize: "small",
    textTransform: "none",
    backgroundColor: "transparent",
    borderColor: "transparent",
  } as React.CSSProperties,

  header: {
    marginLeft: "10px",
    marginTop: "20px",
  } as React.CSSProperties,

  cirleButton: {
    border: "solid",
    borderRadius: "100%",
    height: "35px",
    width: "35px",
    borderWidth: "2px",
    borderColor: "lightgrey",
    backgroundColor: "lightgrey",
  } as React.CSSProperties,

  cirleSmallButton: {
    border: "solid",
    marginLeft: "10px",
    borderRadius: "100%",
    height: "25px",
    width: "25px",
    borderWidth: "2px",
    borderColor: "lightgrey",
    backgroundColor: "lightgrey",
  } as React.CSSProperties,

  linkStyle: {
    textTransform: "none",
    cursor: "pointer",
    textDecoration: "underline",
  } as React.CSSProperties,

  divider: {
    marginTop: "20px",
    background: "black",
    width: "100%",
  } as React.CSSProperties,

  container: {
    marginTop: "20px",
    marginLeft: "70px",
    marginRight: "60px",
    height: "50px",
    border: "solid",
    borderLeftWidth: "7px",
    borderLeftColor: "#c4e4fc",
    borderTopColor: "lightgrey",
    borderRightColor: "lightgrey",
    borderBottomColor: "lightgrey",
    borderTopWidth: "1px",
    borderRightWidth: "1px",
    borderBottomWidth: "1px",
  } as React.CSSProperties,

  generalContainer: {
    marginTop: "0px",
    marginLeft: "62px",
    width: "90%",
    border: "solid",
    borderLeftWidth: "7px",
    borderLeftColor: "#c4e4fc",
    borderTopColor: "lightgrey",
    borderRightColor: "lightgrey",
    borderBottomColor: "lightgrey",
    borderTopWidth: "1px",
    borderRightWidth: "1px",
    borderBottomWidth: "1px",
    display: "flex",
    flexDirection: "row",
  } as React.CSSProperties,

  replyContainer: {
    marginRight: "5px",
    marginLeft: "5px",
    border: "solid",
    borderTopWidth: "7px",
    borderTopColor: "#c4e4fc",
    borderLeftColor: "lightgrey",
    borderRightColor: "lightgrey",
    borderBottomColor: "lightgrey",
    borderLeftWidth: "1px",
    borderRightWidth: "1px",
    borderBottomWidth: "1px",
    width: "100%",
    height: `calc(100% - 30px)`,
  } as React.CSSProperties,

  replyHeader: {
    height: "40px",
    marginBottom: "auto",
  } as React.CSSProperties,

  textStyle: {
    paddingTop: "10px",
    marginLeft: "10px",
  } as React.CSSProperties,

  textTicketStyle: {
    paddingTop: "10px",
    marginLeft: "10px",
    fontWeight: "bold",
    fontSize: "large",
  } as React.CSSProperties,

  liner: {
    borderLeft: "solid",
    height: "15px",
    marginLeft: "90px",
    borderLeftColor: "lightgrey",
    borderLeftWidth: ".1rem",
  } as React.CSSProperties,

  textAreaStyle: {
    width: "90%",
    height: "20vh",
    marginLeft: "50px",
    padding: "10px",
    border: "solid",
    borderRadius: "5px",
    borderColor: "lightgray",
    borderWidth: "1px",
  } as React.CSSProperties,

  attachment: {
    height: "40px",
    marginTop: "15px",
    marginLeft: "50px",
    width: "100%",
  } as React.CSSProperties,

  attachmentContainer: {
    position: "relative",
    backgroundColor: "#c4e4fc",
    border: "solid",
    borderColor: "transparent",
    borderRadius: "5px",
    width: "auto",
    margin: "1px",
    paddingLeft: "5px",
    display: "inline-block",
  } as React.CSSProperties,

  attachmentLabel: {
    position: "relative",
    width: "auto",
    height: "100%",
    display: "inline-block",
  } as React.CSSProperties,

  attachmentIcon: {
    position: "relative",
    width: "auto",
    height: "100%",
    display: "inline-block",
  } as React.CSSProperties,
};

// getTicketById
const TicketDetails = (props) => {
  const { updateLevel, goToCurrentPage, setTableLoading } = props;
  const classes = useStyles();

  const [createdAt, setCreatedAt] = useState("");
  const [status, setStatus] = useState("");
  const [summary, setSummary] = useState("");
  const [description, setDescription] = useState("");
  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [isReplyLoading, setIsReplyLoading] = useState(false);
  const [isCloseTicketLoading, setIsCloseTicketLoading] = useState(false);
  const [isReplyCloseLoading, setIsReplyCloseLoading] = useState(false);
  const [isRecentlyLoaded, setIsRecentlyLoaded] = useState(false);
  const handleRecentlyLoaded = (val: boolean) => {
    if (val === true) {
      scrollToBottom();
    }

    setTimeout(() => setIsRecentlyLoaded(true), 2000);
    setTimeout(() => setIsRecentlyLoaded(false), 4000);
  };

  const [responseList, setResponseList] = useState([]);
  const [attachmentList, setAttachmentList] = useState([]);

  const [responseMessage, setResponseMessage] = useState("");
  const handleResponseMessage = (e) => {
    setResponseMessage(e.target.value);
  };
  const [isClosed, setIsClosed] = useState(false);

  const { openAlertBar } = useAlertActions();

  const chooseServiceRef = React.useRef(null);
  const GetTicketDetails = (id) => {
    getTicketById(id)
      .then((result) => {
        if (result.data.status) {
          setSummary(result.data.data.summary);
          setStatus(result.data.data.status);
          setDescription(result.data.data.description);
          setCreatedAt(result.data.data.createdAt);
          setAttachmentList(result.data.data.attachments);
          if (result.data.data.status === "CLOSED") {
            setIsClosed(true);
          } else {
            setIsClosed(false);
          }
        } else {
          openAlertBar(result.data.message, false);
          updateLevel(1);
        }
      })
      .catch((error) => {
        console.log("error: " + JSON.stringify(error.response));
        openAlertBar("Something happened", false);
        updateLevel(1);
      });

    getResponsesTicketById(id)
      .then((result) => {
        if (result.data.status) {
          setResponseList(result.data.data);
          setDataIsLoading(false);
        } else {
          openAlertBar(result.data.message, false);
          updateLevel(1);
          setDataIsLoading(false);
        }
      })
      .catch((error) => {
        setDataIsLoading(false);

        console.log("error: " + JSON.stringify(error.response));
        openAlertBar("Something happened", false);
        updateLevel(1);
      });
  };

  const RefreshTicketDetails = (id) => {
    getTicketById(id)
      .then((result) => {
        if (result.data.status) {
          setSummary(result.data.data.summary);
          setStatus(result.data.data.status);
          setDescription(result.data.data.description);
          setCreatedAt(result.data.data.createdAt);
          setAttachmentList(result.data.data.attachments);
          if (result.data.data.status === "CLOSED") {
            setIsClosed(true);
          } else {
            setIsClosed(false);
          }
        } else {
          openAlertBar(result.data.message, false);
          updateLevel(1);
        }
      })
      .catch((error) => {
        console.log("error: " + JSON.stringify(error.response));
        openAlertBar("Something happened", false);
        updateLevel(1);
      });

    getResponsesTicketById(id)
      .then((result) => {
        if (result.data.status) {
          setResponseList(result.data.data);
          setDataIsLoading(false);
          handleRecentlyLoaded(true);
        } else {
          openAlertBar(result.data.message, false);
          updateLevel(1);
          setDataIsLoading(false);
        }
      })
      .catch((error) => {
        setDataIsLoading(false);
        console.log("error: " + JSON.stringify(error.response));
        openAlertBar("Something happened", false);
        updateLevel(1);
      });
  };

  const [openReopenDialog, setOpenReopenDialog] = useState<boolean>(false);
  useEffect(() => {
    setDataIsLoading(true);
    try {
      GetTicketDetails(props.ticketId);
    } catch (err) {
      openAlertBar("Unable to load tickets, Please try again later!", false);
      updateLevel(1);
      setDataIsLoading(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [selectedFiles, setSelectedFiles] = useState([]);

  const getStatusColor = (status: string) => {
    if (status === "OPEN") {
      return "red";
    }

    if (status === "CLOSED") {
      return "green";
    }

    return "yellow";
  };

  const reply = async () => {
    setIsReplyLoading(true);
    if (responseMessage.length > 1) {
      try {
        var data = createReplyToTicket(props.ticketId, {
          message: responseMessage,
        });
        // openAlertBar((await data).data.message, (await data).data.status);
        var responseId = (await data).data.data;
        for (var i = 0; i < selectedFiles.length; i++) {
          const formData = new FormData();
          formData.append(
            "data",
            new Blob([JSON.stringify(selectedFiles[i])], {
              type: "application/json",
            })
          );
          formData.append("file", selectedFiles[i]);

          try {
            uploadFileToTicketResponse(props.ticketId, responseId, formData);
          } catch (error) {
            console.log("error: " + JSON.stringify(error.response));
          }
        }

        setSelectedFiles([]);
        RefreshTicketDetails(props.ticketId);
        setResponseMessage("");
      } catch (error) {
        console.log("error: " + JSON.stringify(error.response));
      }
    } else {
      openAlertBar("Please write something to reply", false);
    }

    setIsReplyLoading(false);
  };

  const scrollToBottom = () => {
    chooseServiceRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const closeOrReopenTicket = async () => {
    setIsCloseTicketLoading(true);
    if (!isClosed) {
      var data = closeTicketById(props.ticketId, "closed");
      openAlertBar((await data).data.message, (await data).data.status);
      GetTicketDetails(props.ticketId);
    } else {
      setOpenReopenDialog(true);
    }
    setIsCloseTicketLoading(false);
  };
  const replyClose = async () => {
    setIsReplyCloseLoading(true);
    if (responseMessage.length > 1) {
      try {
        var data = createReplyToTicket(props.ticketId, {
          message: responseMessage,
        });
        var responseId = (await data).data.data;
        for (var i = 0; i < selectedFiles.length; i++) {
          const formData = new FormData();
          formData.append(
            "data",
            new Blob([JSON.stringify(selectedFiles[i])], {
              type: "application/json",
            })
          );
          formData.append("file", selectedFiles[i]);

          try {
            uploadFileToTicketResponse(props.ticketId, responseId, formData);
          } catch (error) {
            console.log("error: " + JSON.stringify(error.response));
          }
        }

        var datas = closeTicketById(props.ticketId, "closed");
        openAlertBar((await datas).data.message, (await datas).data.status);

        setResponseMessage("");
        setSelectedFiles([]);
        RefreshTicketDetails(props.ticketId);
      } catch (error) {
        console.log("error: " + JSON.stringify(error.response));
      }
    } else {
      openAlertBar(
        "Please enter at least 2 or more characters in reply",
        false
      );
    }
    //ReloadDetails(props.ticketId);
    setIsReplyCloseLoading(false);
  };

  const setRefresh = () => {
    RefreshTicketDetails(props.ticketId);
  };
  const selectFile = (event) => {
    try {
      var fileType = event.target.files[0].name.split(".").pop();
      if (fileType === "png" || fileType === "jpg" || fileType === "bmp") {
        setSelectedFiles((prevArray) => [...prevArray, event.target.files[0]]);
      } else {
        openAlertBar(
          "Please select image files such as .jpg, .png or .bmp ",
          false
        );
      }
    } catch (err) {}
  };

  const clearFile = (name) => {
    const arr = selectedFiles.filter((item) => item.name !== name);
    setSelectedFiles(arr);
  };

  if (dataIsLoading === true) {
    return (
      <div
        style={{
          minHeight: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <CircularProgress />
      </div>
    );
  } else {
    return (
      <div style={{ overflow: "hidden" }}>
        <div className={classes.bottomBorderedGrid}>
          <div className={classes.headerTitle}>Ticket Details</div>
        </div>
        <TicketReopenDialog
          ticketId={props.ticketId}
          open={openReopenDialog}
          setRefresh={setRefresh}
          setOpen={(value) => {
            setOpenReopenDialog(value);
          }}
        />
        <div style={{ overflow: "hidden" }}>
          <Grid
            container
            justify="center"
            className={classes.ticketHeadContainer}
            style={{ height: isClosed ? `10px` : `100%` }}
          >
            <Grid
              container
              item
              direction="row"
              xs={12}
              md={isClosed ? 7 : 12}
              lg={isClosed ? 7 : 4}
            >
              <div style={{ display: "flex", marginLeft: "20px" }}>
                <IconButton
                  style={styles.cirleButton}
                  aria-label="delete"
                  onClick={() => {
                    goToCurrentPage();
                    setTableLoading("Loading");
                    updateLevel(1);
                  }}
                  size="small"
                >
                  <ChevronLeft fontSize="inherit" />
                </IconButton>
                <div className={classes.idClass}>
                  <span style={{ color: "#1054ac" }}>ID: </span>
                  {props.ticketId}
                </div>
                <div className={classes.normalText}>|</div>
                <div
                  className={classes.normalText}
                  style={{ marginTop: "5px" }}
                >
                  <span style={{ color: "#1054ac" }}> Status: </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className={classes.statusIcon}
                    style={{
                      backgroundColor: getStatusColor(status),
                    }}
                  ></div>
                  {status}
                </div>
              </div>
            </Grid>
            <Grid
              container
              item
              direction="row"
              xs={6}
              md={isClosed ? 3 : 6}
              lg={isClosed ? 3 : 4}
            >
              <div className={classes.createdAtStyle}>
                Created At : {formatDate(createdAt)}
              </div>
            </Grid>
            <Grid
              container
              item
              direction="row"
              xs={6}
              md={isClosed ? 2 : 6}
              lg={isClosed ? 2 : 4}
            >
              <div
                style={{
                  marginTop: "5px",
                  marginBottom: "5px",
                  marginLeft: "auto",
                  fontWeight: "normal",
                }}
              >
                <Button
                  variant="contained"
                  className={classes.buttonStyle}
                  style={{
                    backgroundColor: isClosed ? "red" : "green",
                  }}
                  onClick={closeOrReopenTicket}
                  disabled={isCloseTicketLoading}
                >
                  {isCloseTicketLoading && (
                    <CircularProgress style={{ color: "white" }} size={20} />
                  )}
                  {isClosed ? "Reopen Ticket" : "Close Ticket"}
                </Button>
              </div>
            </Grid>
          </Grid>
          <div
            style={{ marginLeft: "60px", marginRight: "33px", height: `100%` }}
          >
            {isClosed ? (
              <div className={classes.closedDialog}>
                <ThreadDialog
                  createdAt={formatDate(createdAt)}
                  summary={summary}
                  description={description}
                  attachments={attachmentList}
                  isMain={true}
                />

                {responseList
                  .map((a, index) => (
                    <div>
                      {index === 0 ? (
                        <ThreadDialog
                          createdAt={formatDate(a.createdAt)}
                          description={a.message}
                          isRecentlyLoaded={isRecentlyLoaded}
                          attachments={a.attachments}
                          isAdmin={a.isAdmin}
                          createdBy={a.createdBy}
                        />
                      ) : (
                        <ThreadDialog
                          createdAt={formatDate(a.createdAt)}
                          description={a.message}
                          isRecentlyLoaded={false}
                          attachments={a.attachments}
                          isAdmin={a.isAdmin}
                          createdBy={a.createdBy}
                        />
                      )}
                    </div>
                  ))
                  .reverse()}

                <div
                  ref={chooseServiceRef}
                  style={{ marginTop: "-10px", marginLeft: "5px" }}
                >
                  <Grid container justify="center">
                    <Grid container item direction="column" xs={5}>
                      <div
                        style={{
                          borderTop: "solid",
                          borderTopColor: "#c4e4fc",
                          marginTop: "10px",
                          borderTopWidth: ".1rem",
                        }}
                      ></div>
                    </Grid>
                    <Grid container item direction="column" xs={2}>
                      <div
                        style={{
                          color: "#1054ac",
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                      >
                        End of Timeline
                      </div>
                    </Grid>
                    <Grid container item direction="column" xs={5}>
                      <div
                        style={{
                          borderTop: "solid",
                          borderTopColor: "#c4e4fc",
                          borderTopWidth: ".1rem",
                          marginTop: "10px",
                        }}
                      ></div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            ) : (
              <Grid
                container
                justify="center"
                spacing={1}
                style={{
                  marginTop: "10px",
                  height: `100%`,
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                <Grid
                  container
                  item
                  direction="column"
                  xs={12}
                  lg={isClosed ? 12 : 8}
                >
                  <div
                    style={{
                      height: `calc(100vh - 270px)`,
                      marginRight: "3px",
                      overflowY: "auto",
                    }}
                  >
                    <ThreadDialog
                      createdAt={formatDate(createdAt)}
                      summary={summary}
                      description={description}
                      attachments={attachmentList}
                      isMain={true}
                    />

                    {responseList
                      .map((a, index) => (
                        <div>
                          {index === 0 ? (
                            <ThreadDialog
                              createdAt={formatDate(a.createdAt)}
                              description={a.message}
                              isRecentlyLoaded={isRecentlyLoaded}
                              attachments={a.attachments}
                              isAdmin={a.isAdmin}
                              createdBy={a.createdBy}
                            />
                          ) : (
                            <ThreadDialog
                              createdAt={formatDate(a.createdAt)}
                              description={a.message}
                              isRecentlyLoaded={false}
                              attachments={a.attachments}
                              isAdmin={a.isAdmin}
                              createdBy={a.createdBy}
                            />
                          )}
                        </div>
                      ))
                      .reverse()}

                    <div
                      ref={chooseServiceRef}
                      style={{ marginTop: "-10px", marginLeft: "5px" }}
                    >
                      <Grid container justify="center">
                        <Grid container item direction="column" xs={5}>
                          <div
                            style={{
                              borderTop: "solid",
                              borderTopColor: "#c4e4fc",
                              marginTop: "10px",
                              borderTopWidth: ".1rem",
                            }}
                          ></div>
                        </Grid>
                        <Grid container item direction="column" xs={2}>
                          <div
                            style={{
                              color: "#1054ac",
                              textAlign: "center",
                              fontWeight: "bold",
                            }}
                          >
                            End of Timeline
                          </div>
                        </Grid>
                        <Grid container item direction="column" xs={5}>
                          <div
                            style={{
                              borderTop: "solid",
                              borderTopColor: "#c4e4fc",
                              borderTopWidth: ".1rem",
                              marginTop: "10px",
                            }}
                          ></div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Grid>
                <Grid
                  container
                  item
                  direction="column"
                  xs={12}
                  lg={4}
                  style={{
                    height: isClosed ? `Opx` : `calc(100vh - 240px)`,
                    visibility: isClosed ? "collapse" : "visible",
                    paddingRight: "10px",
                  }}
                >
                  <div style={styles.replyContainer}>
                    <div style={styles.replyHeader}>
                      <div style={{ display: "flex", paddingTop: "3px" }}>
                        <Reply
                          style={{ color: "lightgray", marginLeft: "10px" }}
                          fontSize="large"
                        />
                        <div
                          style={{
                            paddingTop: "3px",
                            marginLeft: "5px",
                            fontWeight: "bold",
                          }}
                        >
                          {" "}
                          Reply
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        borderTop: "solid",
                        borderTopWidth: ".5px",
                        borderTopColor: "lightgrey",
                        height: `calc(100% - 10px)`,
                      }}
                    >
                      <textarea
                        style={{
                          width: "100%",
                          border: "none",
                          height: `calc(100% - 120px)`,
                          paddingLeft: "10px",
                          backgroundColor: "transparent",
                          resize: "none",
                          outline: "none",
                        }}
                        placeholder="Write your reply here..."
                        onChange={handleResponseMessage}
                        value={responseMessage}
                      />
                      <div
                        style={{
                          overflow: "auto",
                          height: "40px",
                          marginLeft: "4px",
                        }}
                      >
                        {selectedFiles.map((a, index) => (
                          <div style={styles.attachmentContainer}>
                            <div style={styles.attachmentLabel}>
                              <label>{a.name}</label>
                            </div>
                            <div style={styles.attachmentIcon}>
                              <IconButton
                                aria-label="delete"
                                size="small"
                                onClick={() => clearFile(a.name)}
                              >
                                <ClearIcon fontSize="inherit" />
                              </IconButton>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div style={{ display: "flex", height: "20px" }}>
                        <Button
                          variant="outlined"
                          component="label"
                          startIcon={<AttachFile />}
                          style={styles.attachButtonStyle}
                        >
                          Attach
                          <input
                            type="file"
                            hidden
                            onChange={selectFile}
                            accept="image/png, image/jpeg, image/jpg"
                          />
                        </Button>
                        <div
                          style={{
                            marginLeft: "auto",
                            marginRight: "10px",
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              marginTop: "5px",
                              fontWeight: "bold",
                              marginRight: "10px",
                            }}
                          >
                            <Button
                              variant="outlined"
                              color="primary"
                              style={styles.replyCloseStyle}
                              onClick={replyClose}
                              disabled={isReplyCloseLoading}
                            >
                              {isReplyCloseLoading && (
                                <CircularProgress
                                  style={{ color: "#047cfc" }}
                                  size={20}
                                />
                              )}
                              Reply and Close
                            </Button>
                          </div>
                          <div
                            style={{ marginTop: "5px", fontWeight: "normal" }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              style={styles.replyStyle}
                              onClick={reply}
                              disabled={isReplyLoading}
                            >
                              {isReplyLoading && (
                                <CircularProgress
                                  style={{ color: "white" }}
                                  size={20}
                                />
                              )}
                              Reply
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default TicketDetails;

function formatDate(string) {
  const dateObj = new Date(string);
  const day = String(dateObj.getDate()).padStart(2, "0");
  const year = dateObj.getFullYear();
  const time = dateObj.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  const output = `${year}-${dateObj.getMonth() + 1}-${day} ${time}`;
  return output;
}
