import { Button, CircularProgress, IconButton } from "@material-ui/core";
import { AttachFile } from "@material-ui/icons";
import React, { useState } from "react";
import TicketsDialog from "../../../utilities/TicketsDialog";
import ClearIcon from '@material-ui/icons/Clear';
import { useAlertActions } from '../../../../redux/actions/alertActions';
import { closeTicketById, createReplyToTicket, uploadFileToTicketResponse } from "../../../../apis/customerSupportService";

// interface TicketReopenDialog {

//     setPaginChange: (value: PaginChange) => void;

// }

const styles = {

    buttonStyle: {
        height: '30px',
        width: '120px',
        textTransform: 'none',
        backgroundColor: 'green',
    } as React.CSSProperties,

    replyStyle: {
        height: '30px',
        color: 'white',
        textTransform: 'none',
        backgroundColor: '#047cfc',
    } as React.CSSProperties,

    replyCloseStyle: {
        height: '30px',
        width: '165px',
        fontSize: 'small',
        textTransform: 'none',
        backgroundColor: 'transparent',
        borderColor: '#047cfc',
        color: '#047cfc',
    } as React.CSSProperties,

    attachButtonStyle: {
        height: '40px',
        width: '100px',
        fontSize: 'small',
        textTransform: 'none',
        backgroundColor: 'transparent',
        borderColor: 'transparent',
    } as React.CSSProperties,

    header: {
        marginLeft: '10px',
        marginTop: '20px',
    } as React.CSSProperties,

    cirleButton: {
        border: 'solid',
        borderRadius: '100%',
        height: '35px',
        width: '35px',
        borderWidth: '2px',
        borderColor: 'lightgrey',
        backgroundColor: 'lightgrey',
    } as React.CSSProperties,

    cirleSmallButton: {
        border: 'solid',
        marginLeft: '10px',
        borderRadius: '100%',
        height: '25px',
        width: '25px',
        borderWidth: '2px',
        borderColor: 'lightgrey',
        backgroundColor: 'lightgrey',
    } as React.CSSProperties,

    linkStyle: {
        textTransform: 'none',
        cursor: 'pointer',
        textDecoration: 'underline',

    } as React.CSSProperties,

    divider: {
        marginTop: '20px',
        background: 'black',
        width: '100%'
    } as React.CSSProperties,

    container: {
        marginTop: '20px',
        marginLeft: '70px',
        marginRight: '60px',
        height: '50px',
        border: 'solid',
        borderLeftWidth: '7px',
        borderLeftColor: '#c4e4fc',
        borderTopColor: 'lightgrey',
        borderRightColor: 'lightgrey',
        borderBottomColor: 'lightgrey',
        borderTopWidth: '1px',
        borderRightWidth: '1px',
        borderBottomWidth: '1px',

    } as React.CSSProperties,

    generalContainer: {
        marginTop: '0px',
        marginLeft: '62px',
        width: '90%',
        border: 'solid',
        borderLeftWidth: '7px',
        borderLeftColor: '#c4e4fc',
        borderTopColor: 'lightgrey',
        borderRightColor: 'lightgrey',
        borderBottomColor: 'lightgrey',
        borderTopWidth: '1px',
        borderRightWidth: '1px',
        borderBottomWidth: '1px',
        display: 'flex',
        flexDirection: 'row',
    } as React.CSSProperties,

    replyContainer: {
        marginRight: '50px',
        marginTop: '10px',
        marginBottom: '20px',
        marginLeft: '50px',
        border: 'solid',
        borderWidth: '1px',
        borderColor: 'lightgray',
        height: '160px',

    } as React.CSSProperties,

    replyHeader: {
        height: '40px',
        marginBottom: 'auto'
    } as React.CSSProperties,

    textStyle: {
        paddingTop: '10px',
        marginLeft: '10px',

    } as React.CSSProperties,

    textTicketStyle: {
        paddingTop: '10px',
        marginLeft: '10px',
        fontWeight: 'bold',
        fontSize: 'large',
    } as React.CSSProperties,

    liner: {
        borderLeft: 'solid',
        height: '15px',
        marginLeft: '90px',
        borderLeftColor: 'lightgrey',
        borderLeftWidth: '.1rem',

    } as React.CSSProperties,

    textAreaStyle: {
        width: '90%',
        height: '20vh',
        marginLeft: '50px',
        padding: '10px',
        border: 'solid',
        borderRadius: '5px',
        borderColor: 'lightgray',
        borderWidth: '1px',
    } as React.CSSProperties,

    attachment: {
        height: '40px',
        marginTop: '15px',
        marginLeft: '50px',
        width: '100%',
    } as React.CSSProperties,


    attachmentContainer: {
        position: 'relative',
        backgroundColor: '#c4e4fc',
        border: 'solid',
        borderColor: 'transparent',
        borderRadius: '5px',
        width: 'auto',
        margin: '5px',
        paddingLeft: '5px',
        display: 'inline-block',

    } as React.CSSProperties,

    attachmentLabel: {
        position: 'relative',
        width: 'auto',
        height: '100%',
        display: 'inline-block',
    } as React.CSSProperties,

    attachmentIcon: {
        position: 'relative',
        width: 'auto',
        height: '100%',
        display: 'inline-block',
    } as React.CSSProperties,

};

const TicketReopenDialog = (props) => {
    const { open } = props;
    const { setOpen } = props;
    // const { setRefresh } = props;

    const [responseMessage, setResponseMessage] = useState('');
    const handleResponseMessage = (e) => {
        setResponseMessage(e.target.value);
    };

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isReplyLoading, setIsReplyLoading] = useState(false);
    const clearFile = (name) => {
        const arr = selectedFiles.filter((item) => item.name !== name);
        setSelectedFiles(arr);
    };

    const { openAlertBar } = useAlertActions();

    const selectFile = (event) => {
        try {


            var fileType = event.target.files[0].name.split('.').pop();
            if (fileType === 'png' || fileType === 'jpg' || fileType === 'bmp') {
                setSelectedFiles(prevArray => [...prevArray, event.target.files[0]])
            }
            else {
                openAlertBar("Please select image files such as .jpg, .png or .bmp ", false);
            }
        }
        catch (err) {

        }

    };

    const reply = async () => {

        setIsReplyLoading(true);
        if (responseMessage.length > 1) {

            try {
                var data = createReplyToTicket(props.ticketId, { message: responseMessage });
                var responseId = (await data).data.data;
                for (var i = 0; i < selectedFiles.length; i++) {
                    const formData = new FormData();
                    formData.append('data', new Blob([JSON.stringify(selectedFiles[i])], { type: 'application/json' }));
                    formData.append('file', selectedFiles[i]);

                    try {
                        uploadFileToTicketResponse(props.ticketId, responseId, formData);
                    }
                    catch (error) {
                        console.log("error: " + JSON.stringify(error.response));
                    }

                }

                var datas = closeTicketById(props.ticketId, 'open');
                openAlertBar((await datas).data.message, (await datas).data.status);
            }
            catch (error) {
                console.log("error: " + JSON.stringify(error.response));
            }

            // setRefresh();
            setOpen(false);

        }
        else {
            openAlertBar("Please write the reason to reopen", false);
            setIsReplyLoading(false);
            return;
        }



        setIsReplyLoading(false);

    };


    // useEffect(() => {
    //     setLevel(1);
    // }, []);// eslint-disable-line react-hooks/exhaustive-deps

    return (

        <TicketsDialog
            title={`Reopen the Ticket`}
            maxWidth="sm"
            setOpen={setOpen}
            dialogId="reopenTicketDialog"
            open={open}
        >
            <div style={{ display: 'flex', flexDirection: 'column', height: '350px', overflow: '-moz-hidden-unscrollable' }}>
                <div style={{ marginLeft: '30px', marginRight: '30px' }}>
                    Why do you want to reopen the ticket?
                </div>
                <div style={{
                    border: 'solid',
                    borderWidth: '.5px',
                    borderColor: 'lightgrey',
                    color: 'lightgrey',
                    borderRadius: '5px',
                    height: '185px',
                    marginLeft: '30px',
                    marginRight: '30px',
                    marginTop: '10px',

                }} >
                    <textarea style={{
                        width: '100%', border: 'none', height: `140px`, paddingLeft: '10px', backgroundColor: 'transparent',
                        resize: 'none',
                        outline: 'none',

                    }}
                        placeholder="Write your reply here..." onChange={handleResponseMessage} value={responseMessage} />

                </div>
                <div style={{ overflow: 'auto', height: '40px', marginLeft: '25px', marginRight: '30px' }}>
                    {
                        selectedFiles.map((a, index) => (

                            <div style={styles.attachmentContainer}>
                                <div style={styles.attachmentLabel}>
                                    <label>{a.name}</label>
                                </div>
                                <div style={styles.attachmentIcon}>
                                    <IconButton aria-label="delete" size="small" onClick={() => clearFile(a.name)}>
                                        <ClearIcon fontSize="inherit" />
                                    </IconButton>
                                </div>
                            </div>
                        ))
                    }

                </div>

                <div style={{ display: 'flex', marginBottom: '5px', marginLeft: '10px' }}>
                    <Button
                        variant="outlined"
                        component="label"
                        startIcon={<AttachFile />}
                        style={styles.attachButtonStyle}
                    >
                        Attach
                                                <input
                            type="file"
                            hidden
                            onChange={selectFile}
                            accept="image/png, image/jpeg, image/jpg"
                        />
                    </Button>

                </div>

                <div style={{ borderTopColor: 'lightgrey', color: 'lightgrey', borderColor: 'lightgrey', borderTop: 'solid', borderTopWidth: '1px', height: '20px' }}>
                    <div style={{ marginLeft: 'auto', display: 'flex', }} >

                        <div style={{ marginTop: '5px', marginRight: '10px', marginBottom: '5px', marginLeft: 'auto', fontWeight: 'normal' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                style={styles.replyStyle}
                                onClick={reply}
                                disabled={isReplyLoading}
                            >
                                {isReplyLoading && <CircularProgress style={{ color: 'white' }} size={20} />}
                                Reopen

                            </Button>

                        </div>
                    </div>
                </div>
            </div>

        </TicketsDialog>

    );
};

export default TicketReopenDialog;
