import { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { Pagination, PaginChange } from '../../../../@type/Pagination';
import { Ticket } from '../../../../@type/Ticket';
import GlobalSearch from '../../../utilities/GlobalSearch';
import TicketDialog from '../TicketDialogs/TicketDialog';
import MyTicketsStatusSelection from './MyTicketsStatusSelection';
import MyTicketsTable from './MyTicketsTable';
import { TableDateFilterWrapper } from '../../../utilities/TableComponent';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            minHeight: "calc(100vh - 131px)", // to fix the pagination
            position: "relative"
        },
        title: {
            margin: 10,
        },
        outer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: 10
        },
        actionsOuter: {
            display: "flex",
            flexDirection: "row",
        },
        btnsOuter: {
            margin: "0 10px"
        },
        toggleBtn: {
            height: 36.44,
            "& .MuiToggleButton-label": {
                fontSize: "smaller"
            }
        }
    }),
);
interface Props {
    globalSearch: string;
    setGlobalSearch: (value: string) => void;
    tickets: Ticket[];
    setTickets: (value: Ticket[]) => void;
    pagination: Pagination;
    setPagination: (value: Pagination) => void;
    isTableLoading: "Loading" | "Failed" | "Success" | "NoItem";
    setShowingContentIndex: (value: number) => void;
    setShowingTicketDetailsId: (value: number) => void;
    statusArr: string[];
    setStatusArr: (value: string[]) => void;
    setPaginChange: (value: PaginChange) => void;
    paginChange: PaginChange;
    dateFilter: string;
    setDateFilter: (value: string) => void;
    goToFirstPage: () => void;
    goToCurrentPage: () => void;
    sortType: "ASC" | "DESC" | null;
    sortVal: string;
    setSortVal: (value: string) => void;
    setSortType: (value: "ASC" | "DESC" | null) => void;
    adminsList: any;
}

export default function MyTicketsDefault({ goToCurrentPage, adminsList, setSortType, setSortVal, sortType, sortVal, globalSearch, setGlobalSearch, tickets, setTickets, pagination, setPagination, isTableLoading, setShowingContentIndex, setShowingTicketDetailsId, statusArr, setStatusArr, setPaginChange, paginChange, dateFilter, setDateFilter, goToFirstPage }: Props) {
    const classes = useStyles();

    const [createNewTicketDialogOpen, setCreateNewTicketDialogOpen] = useState<boolean>(false);

    return (
        <div className={classes.root}>
            <h4 className={classes.title}>
                My Tickets
            </h4>
            <div className={classes.outer}>
                <div className={classes.actionsOuter}>
                    <MyTicketsStatusSelection
                        setStatusArr={setStatusArr}
                        statusArr={statusArr}
                        setPaginChange={setPaginChange}
                    />
                    <GlobalSearch
                        globalSearch={globalSearch}
                        setGlobalSearch={setGlobalSearch} />
                    <TableDateFilterWrapper
                        dateFilter={dateFilter}
                        setOrderDateFilter={(value) => {
                            setDateFilter(value);
                        }}
                        goToFirstPage={goToFirstPage}
                    />
                </div>
                {/* <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => { setCreateNewTicketDialogOpen(true); }}
                >
                    Add New Ticket
                </Button> */}
            </div>
            <MyTicketsTable
                tickets={tickets}
                pagination={pagination}
                setPagination={setPagination}
                isTableLoading={isTableLoading}
                setShowingContentIndex={setShowingContentIndex}
                setShowingTicketDetailsId={setShowingTicketDetailsId}
                setPaginChange={setPaginChange}
                paginChange={paginChange}
                sortType={sortType}
                sortVal={sortVal}
                setSortType={setSortType}
                setSortVal={setSortVal}
                goToFirstPage={goToFirstPage}
                adminsList={adminsList}
                goToCurrentPage={goToCurrentPage}
            />

            <TicketDialog
                setPaginChange={setPaginChange}
                open={createNewTicketDialogOpen}
                setOpen={(value) => { setCreateNewTicketDialogOpen(value); }}
            />
        </div >
    );
}