import Axios from "axios";
import { SERVER_URL } from "../environment/env_dev";

export const loginService = (loginData: any) => {
    return Axios.post(`${SERVER_URL}/api/Login`, loginData);
};

export const registerService = (data: any) => {
    return Axios.post(`${SERVER_URL}/api/register`, data);
};
