import Axios from 'axios';
import { config, SERVER_URL } from '../environment/env_dev';

export const getNotifications = () => {
  return Axios.get(`${SERVER_URL}/api/Notifications`, config);
};

export const updateNotification = (id, isRead) => {
  if (!id) {
    return Axios.put(`${SERVER_URL}/api/Notifications?isRead=${isRead ? 'true' : 'false'}`, null, config);
  }

  return Axios.put(`${SERVER_URL}/api/Notifications?notificationId=${id}&isRead=${isRead ? 'true' : 'false'}`, null, config);
};