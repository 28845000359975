import { useContext, useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";

import MasterPageSidebar from "./MasterPageSidebar";
import FeedbackFooter from "./FeedbackFooter";
import Dashboard from "./dashboard/Dashboard";
import OpenTickets from "./customerSupport/OpenTickets";
import LearningCenter from "./customerSupport/LearningCenter";

import { MasterPageContext } from "./masterPageContext";
import { MENU_MAP, SIDEBAR_MENU_MAP } from "./common";
import Payments from "../payments/Payments";
// import Shipments from '../shipments/Shipments';
import Customers from "../customers/Customers";
import DepositHistory from "../deposit-history/DepositHistory";
import BalanceSheet from "../balance-sheet/BalanceSheet";
import CustomerStats from "../customer-stats/CustomerStats";
import ChargeCustomer from "../charge-customer/ChargeCustomer";
import BonusClaimed from "../bonus-claimed/BonusClaimed";
import ShipmentHistory from "../shipment-history/ShipmentHistory";
import CancelledShipments from "../cancelled-shipments/CancelledShipments";

const SIDEBAR_MENU_DATA = SIDEBAR_MENU_MAP.ALL;

const MasterPageMain = ({ selectedMenuKey = "" }) => {
  const { resolveSelectedMenuKey } = useContext(MasterPageContext);

  useEffect(() => {
    const route = window.location.pathname;

    if (route === "/") {
      return;
    }

    resolveSelectedMenuKey(route);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();

  const onSidebarMenuChange = (key, route) => {
    resolveSelectedMenuKey(route);
    history.push(route);
  };

  const getPageLabel = () => {
    for (let i = 0, ii = SIDEBAR_MENU_DATA.length; i < ii; i++) {
      const data = SIDEBAR_MENU_DATA[i];

      if (!data.children?.length && data.key !== selectedMenuKey) {
        continue;
      }

      if (data.children?.length) {
        for (let j = 0, jj = data.children.length; j < jj; j++) {
          const c = data.children[j];

          if (c.key !== selectedMenuKey) {
            continue;
          }

          return c.label;
        }

        continue;
      }

      return data.label;
    }
  };

  return (
    <div className="master-page-content">
      <MasterPageSidebar
        data={SIDEBAR_MENU_DATA}
        selectedKey={selectedMenuKey}
        onChange={onSidebarMenuChange}
      />
      <div className="master-page-main-content-wrapper">
        <div className="master-page-main-content">
          <Switch>
            <Route exact path={MENU_MAP.DASHBOARD.route}>
              <Dashboard />
            </Route>
            <Route exact path={MENU_MAP.MANAGE_CUSTOMERS.route}>
              <Customers />
            </Route>
            <Route exact path={MENU_MAP.PAYMENTS.route}>
              <Payments />
            </Route>
            <Route exact path={MENU_MAP.PAYMENTS_DEPOSIT_HISTORY.route}>
              <DepositHistory />
            </Route>
            <Route exact path={MENU_MAP.PAYMENTS_BALANCE_SHEET.route}>
              <BalanceSheet />
            </Route>
            <Route exact path={MENU_MAP.PAYMENTS_CUSTOMER_STATS.route}>
              <CustomerStats />
            </Route>
            <Route exact path={MENU_MAP.PAYMENTS_BONUS_CLIAMED.route}>
              <BonusClaimed />
            </Route>
            <Route exact path={MENU_MAP.PAYMENTS_CHARGE_CUSTOMER.route}>
              <ChargeCustomer />
            </Route>
            <Route exact path={MENU_MAP.SHIPMENTS_HISTORY.route}>
              <ShipmentHistory />
            </Route>
            <Route exact path={MENU_MAP.SHIPMENTS_REFUND.route}>
              <CancelledShipments />
            </Route>
            {/* 
            
            <Route exact path={MENU_MAP.DASHBOARD.route}>
              <Dashboard />
            </Route> */}
            <Route exact path={MENU_MAP.CUSTOMER_SUPPORT.route}>
              <OpenTickets />
            </Route>
            <Route exact path={`${MENU_MAP.CUSTOMER_SUPPORT_MY_TICKETS.route}`}>
              <OpenTickets />
            </Route>
            <Route
              exact
              path={`${MENU_MAP.CUSTOMER_SUPPORT_MY_TICKETS.route}/:id`}
            >
              <OpenTickets />
            </Route>
            <Route exact path={MENU_MAP.DASHBOARD.route}>
              <Dashboard />
            </Route>
            <Route path={MENU_MAP.CUSTOMER_SUPPORT_LEARNING_CENTER.route}>
              <LearningCenter />
            </Route>
          </Switch>
        </div>
        <FeedbackFooter pageLabel={getPageLabel()} />
      </div>
    </div>
  );
};

export default MasterPageMain;
