import { Address } from "../../../@type/Orders/Address";
import { Order } from "../../../@type/Orders/Order";

type LOAD_ORDERS_DATA = "LOAD_ORDERS_DATA";
type LOAD_VIEW_ORDERS_DATA = "LOAD_VIEW_ORDERS_DATA";
type SET_SELECTED_ROWS = "SET_SELECTED_ROWS";
type SET_ORDER_DETAILS_ORDER = "SET_ORDER_DETAILS_ORDER";
type SET_ORDERS_TABLE_LOADING = "SET_ORDERS_TABLE_LOADING";
type SET_SHIP_FROM_ADDRESSES = "SET_SHIP_FROM_ADDRESSES";
type SET_ORDERS_PAGE = "SET_ORDERS_PAGE";
type SET_PAGE_INDEX = "SET_PAGE_INDEX";
type SET_COUNTRIES = "SET_COUNTRIES";

type actionType = {
    LOAD_ORDERS_DATA: LOAD_ORDERS_DATA;
    LOAD_VIEW_ORDERS_DATA: LOAD_VIEW_ORDERS_DATA;
    SET_SELECTED_ROWS: SET_SELECTED_ROWS;
    SET_ORDER_DETAILS_ORDER: SET_ORDER_DETAILS_ORDER;
    SET_ORDERS_TABLE_LOADING: SET_ORDERS_TABLE_LOADING;
    SET_SHIP_FROM_ADDRESSES: SET_SHIP_FROM_ADDRESSES;
    SET_ORDERS_PAGE: SET_ORDERS_PAGE;
    SET_PAGE_INDEX: SET_PAGE_INDEX;
    SET_COUNTRIES: SET_COUNTRIES;
};

export const OrdersActions: actionType = {
    LOAD_ORDERS_DATA: "LOAD_ORDERS_DATA",
    LOAD_VIEW_ORDERS_DATA: "LOAD_VIEW_ORDERS_DATA",
    SET_SELECTED_ROWS: "SET_SELECTED_ROWS",
    SET_ORDER_DETAILS_ORDER: "SET_ORDER_DETAILS_ORDER",
    SET_ORDERS_TABLE_LOADING: "SET_ORDERS_TABLE_LOADING",
    SET_SHIP_FROM_ADDRESSES: "SET_SHIP_FROM_ADDRESSES",
    SET_ORDERS_PAGE: 'SET_ORDERS_PAGE',
    SET_PAGE_INDEX: 'SET_PAGE_INDEX',
    SET_COUNTRIES: 'SET_COUNTRIES',
};

export type OrdersAction =
    | { type: LOAD_ORDERS_DATA, payload: Order[] }
    | { type: LOAD_VIEW_ORDERS_DATA, payload: Order[] }
    | { type: SET_SELECTED_ROWS, payload: Order[] }
    | { type: SET_ORDER_DETAILS_ORDER, payload: Order }
    | { type: SET_ORDERS_TABLE_LOADING, payload: "Loading" | "Failed" | "Success" | "NoOrder" | "NoOrderShipped" | "NoOrderCanceled" }
    | { type: SET_SHIP_FROM_ADDRESSES, payload: Address[] }
    | { type: SET_ORDERS_PAGE, payload: "awaitingShipment" | "shipped" | "canceled" }
    | { type: SET_PAGE_INDEX, payload: number }
