import { useContext, useEffect, useRef, useState, createContext } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import Chip from '@material-ui/core/Chip';

import DefaultSmallCard from '../../utilities/SmallCard';
import Table from '../../utilities/Table';

import { getShipments } from '../../../apis/shipments';
import { getTransactions } from '../../../apis/transactions';
import { getPickups } from '../../../apis/pickups';

import { MENU_MAP } from '../common';

import { RootState } from '../../../redux/reducers';

import { MasterPageContext } from '../masterPageContext';

import carrierServices from '../../../datajsons/carrierServices.json'

import './Dashboard.scss';

const DashboardContext = createContext(null);

const LONG_DATE_FORMAT = 'YYYY-MM-DD h:mm A';
const TIME_FORMAT = 'h A';
const DATA_FORMAT_COMPARER = 'YYYY/MM/DD';

const SHIPMENT_HISTORY_LENGTH = 10;
const TRANSACTION_HISTORY_LENGTH = 10;
const TODAYS_PICKUP_LENGTH = 10;

const TRANSACTION_TYPE_MAP = {
  'LABELPAYMENT': 'Label Payment',
  'SVPAYMENT': 'SVPayment',
  'DEPOSIT': 'Deposit',
  'REFUND': 'Refund',
};

const TRANSACTION_STATUS_MAP = {
  'SUCCESS': 'Success',
  'PENDING': 'Pending',
  'FAILED': 'Failed',
};

const TABLE_CELL_STYLE = {
  padding: '4px 10px',
  fontSize: '0.8rem',
  height: '44px',
};

const TABLE_HEADER_STYLE = {
  ...TABLE_CELL_STYLE,
  background: '#ddd',
};

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const useStyles = makeStyles(() =>
  createStyles({
    cardWrapper: {
      padding: 10,
      height: '100%',
    },
    cardWithButtonWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
    },
    cardButtonWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '1rem',
    },
    cardIsLoading: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    quickActionWrapper: {
      padding: 30,
    },
    quickActionIconWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    quickActionImg: {
      width: '50px',
      height: '50px',
      marginBottom: '1rem',
    },
    accountCardWrapper: {
      padding: 20,
    },
    fillHeight: {
      flex: 1,
    },
    leftAlign: {
      textAlign: 'left',
    },
    shipmentHistoryServiceCell: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    shipmentHistoryIcon: {
      width: 16,
      height: 16,
      marginRight: '4px',
    },
    shipmentHistoryChip: {
      fontSize: '0.7rem',
      height: '20px',
      marginLeft: '4px',
    },
    shipmentHistoryChipPending: {
      fontSize: '0.7rem',
      height: '20px',
      marginLeft: '4px',
      background: 'yellow',
    },
    transactionHistoryTotalCell: {
      display: 'flex',
      flexDirection: 'column',
    },
    transactionHistoryStatusTextSuccess: {
      fontSize: '0.7rem',
      fontStyle: 'italic',
      color: 'green',
    },
    transactionHistoryStatusTextPending: {
      fontSize: '0.7rem',
      fontStyle: 'italic',
      color: 'yellow',
    },
    transactionHistoryStatusTextFailed: {
      fontSize: '0.7rem',
      fontStyle: 'italic',
      color: 'red',
    },
    pickupAddressCell: {
      display: 'flex',
      flexDirection: 'column',
    },
    pickupServiceCell: {
      display: 'flex',
      alignItems: 'center',
    },
    pickupServiceIcon: {
      width: 16,
      height: 16,
      marginRight: '4px',
    },
    tableIconPlaceholderWrapper: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      flex: '1',
      justifyContent: 'center',
    },
    tableIconPlaceholder: {
      maxWidth: '200px',
      alignSelf: 'center',
      marginTop: '1rem',
    },
  }),
);

const SmallCard = ({ title, children }) => {
  return (
    <DefaultSmallCard
      title={title}
      style={{
        minWidth: '400px',
      }}
    >
      {children}
    </DefaultSmallCard>
  );
};

const SmallCardFillHeight = ({ title, children }) => {
  return (
    <DefaultSmallCard
      title={title}
      style={{
        minWidth: '400px',
        height: '100%',
      }}
    >
      {children}
    </DefaultSmallCard>
  );
};

const MasterPageWrapper = ({ children }) => {
  return (
    <div style={{ height: '100%' }}>
      {children}
    </div>
  );
};

const PageContentWrapper = ({ children }) => {
  return (
    <div className="dashboard-main-content">
      {children}
    </div>
  );
};

const PageWrapper = ({ title, children }) => {
  return (
    <div className="dashboard-content">
      <PageContentWrapper>
        {children}
      </PageContentWrapper>
    </div>
  );
};

const CardContentWrapper = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.cardWrapper}>
      {children}
    </div>
  );
};

const CardContentWithButtonWrapper = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.cardWithButtonWrapper}>
      {children}
    </div>
  );
};

const CardButtonWrapper = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.cardButtonWrapper}>
      {children}
    </div>
  );
};

const CardIsLoading = () => {
  const classes = useStyles();
  
  return (
    <div className={classes.cardIsLoading}>
      <CircularProgress />
    </div>
  );
}

const QuickActionIcon = ({ imgUrl, label, onClick }) => {
  const classes = useStyles();

  return (
    <Grid item xs>
      <div
        className={classes.quickActionIconWrapper}
        onClick={onClick}
      >
        <img
          className={classes.quickActionImg}
          src={imgUrl}
          alt={label}
        />
        <Link className="link">{label}</Link>
      </div>
    </Grid>
  );
}

const TableIconPlaceholder = ({ imgUrl, imgAlt, message = null }) => {
  const classes = useStyles();

  return (
    <div className={classes.tableIconPlaceholderWrapper}>
      {message && (<span>{message}</span>)}
      <img
        className={classes.tableIconPlaceholder}
        src={imgUrl}
        alt={imgAlt}
      />
    </div>
  );
};

const RecentShipmentHistory = ({ data = [], isLoading = false }) => {
  const classes = useStyles();

  const { redirectPageHandler } = useContext(DashboardContext);

  const columns = [
    {
      Header: () => <div className={classes.leftAlign}>Date</div>,
      accessor: 'date',
      Cell: (props) => (
        <div>
          {moment(props.value).format(LONG_DATE_FORMAT)}
        </div>
      ),
    },
    {
      Header: () => <div className={classes.leftAlign}>Service</div>,
      accessor: 'service',
      Cell: (props) => (
        <div className={classes.shipmentHistoryServiceCell}>
          {
            props.value.carrier === 'CanadaPost' && (
              <img
                className={classes.shipmentHistoryIcon}
                src="/images/canadapost.png"
                alt="Canada Post"
              />
            )
          }
          <span>{props.value.name}</span>
          {
            props.value.status === 'cancelled' && (
              <div>
                <Chip
                  size="small"
                  label="Cancelled"
                  color="secondary"
                  className={classes.shipmentHistoryChip}
                />
              </div>
            )
          }
          {
            props.value.status === 'pending' && (
              <div>
                <Chip
                  size="small"
                  label="Pending"
                  className={classes.shipmentHistoryChipPending}
                />
              </div>
            )
          }
        </div>
      ),
    },
    {
      Header: 'Total',
      accessor: 'total',
    },
  ];

  return (
    <SmallCardFillHeight title="Recent Shipment History">
      <CardContentWrapper>
        {
          isLoading && (
            <CardIsLoading />
          )
        }
        {
          !isLoading && (
            <CardContentWithButtonWrapper>
              {
                data.length > 0 && (
                  <Table
                    data={data}
                    columns={columns}
                    showDeleteButton={false}
                    headerCellStyle={TABLE_HEADER_STYLE}
                    bodyCellStyle={TABLE_CELL_STYLE}
                  />
                )
              }
              {
                data.length < 5 && (
                  <TableIconPlaceholder
                    imgUrl="./images/dashboard/recent-shipment-history-img.svg"
                    imgAlt="Recent Shipment History"
                    message={data.length > 0 ? null : (
                      <div>
                        <div>You don't have any shipment created.</div>
                        <span>
                          Please&nbsp;
                          <Link
                            className="link"
                            onClick={() => { redirectPageHandler(MENU_MAP.SHIPMENTS_CREATE_SHIPMENT.route); }}
                          >
                            Create a Shipment
                          </Link>
                          &nbsp;first.
                        </span>
                      </div>
                    )}
                  />
                )
              }
              {
                data.length > 0 && (
                  <CardButtonWrapper>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => { redirectPageHandler(MENU_MAP.SHIPMENTS_SHIPMENT_HISTORY.route); }}
                    >
                      See All Shipment History
                    </Button>
                  </CardButtonWrapper>
                )
              }
            </CardContentWithButtonWrapper>
          )
        }
      </CardContentWrapper>
    </SmallCardFillHeight>
  );
};

const RecentTransactionHistory = ({ data = [], isLoading = false }) => {
  const classes = useStyles();

  const { redirectPageHandler } = useContext(DashboardContext);

  const columns = [
    {
      Header: () => <div className={classes.leftAlign}>Date Time</div>,
      accessor: 'date',
      Cell: (props) => (
        <div>
          {moment(props.value).format(LONG_DATE_FORMAT)}
        </div>
      ),
    },
    {
      Header: () => <div className={classes.leftAlign}>Type</div>,
      accessor: 'type',
    },
    {
      Header: 'Total',
      accessor: 'total',
      Cell: (props) => (
        <div className={classes.transactionHistoryTotalCell}>
          <span>{props.value.amount}</span>
          <span className={classes[`transactionHistoryStatusText${props.value.status}`]}>{props.value.status}</span>
        </div>
      ),
    },
  ];

  return (
    <SmallCardFillHeight title="Recent Transaction History">
      <CardContentWrapper>
        {
          isLoading && (
            <CardIsLoading />
          )
        }
        {
          !isLoading && (
            <CardContentWithButtonWrapper>
              {
                data.length > 0 && (
                  <Table
                    data={data}
                    columns={columns}
                    showDeleteButton={false}
                    headerCellStyle={TABLE_HEADER_STYLE}
                    bodyCellStyle={TABLE_CELL_STYLE}
                  />
                )
              }
              {
                data.length < 5 && (
                  <TableIconPlaceholder
                    imgUrl="./images/dashboard/recent-transaction-history-img.svg"
                    imgAlt="Recent Transaction History"
                    message={data.length > 0 ? null : (
                      <div>
                        <div>You don't have any Transaction Record.</div>
                        <span>
                          Please&nbsp;
                          <Link
                            className="link"
                            onClick={() => { redirectPageHandler(MENU_MAP.SETTINGS_ACCOUNT_PAYMENTS.route); }}
                          >
                            Add Deposit
                          </Link>
                          &nbsp;first.
                        </span>
                      </div>
                    )}
                  />
                )
              }
              {
                data.length > 0 && (
                  <CardButtonWrapper>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => { redirectPageHandler(MENU_MAP.SETTINGS_ACCOUNT_TRANSACTION_HISTORY.route); }}
                    >
                      See All Transaction History
                    </Button>
                  </CardButtonWrapper>
                )
              }
            </CardContentWithButtonWrapper>
          )
        }
      </CardContentWrapper>
    </SmallCardFillHeight>
  );
};

const TodaysPickup = ({ data = [], isLoading = false }) => {
  const classes = useStyles();

  const { redirectPageHandler } = useContext(DashboardContext);

  const PickupTime = ({ pickupTime, closeTime }) => {
    return (
      <div>
        {moment(pickupTime).format(TIME_FORMAT)}
        &nbsp;-&nbsp;
        {moment(closeTime).format(TIME_FORMAT)}
      </div>
    );
  };

  const PickupAddress = ({ address }) => {
    const { streetAddress, streetAddress2, city, name, stateCode, postalCode } = address;

    return (
      <div className={classes.pickupAddressCell}>
        <span>{name}</span>
        <span>{streetAddress}</span>
        <span>{streetAddress2}</span>
        <span>{city}</span>
        <span>{stateCode} {postalCode}</span>
      </div>
    );
  };

  const PickupService = ({ shippingCarrier, pickupDetails }) => {
    const { priorityFlag, worldWidePriorityFlag } = pickupDetails;

    return (
      <div className={classes.pickupServiceCell}>
        {
          shippingCarrier === 'CanadaPost' && (
            <img
              className={classes.pickupServiceIcon}
              src="/images/canadapost.png"
              alt="Canada Post"
            />
          )
        }
        {priorityFlag && (<span>Priority</span>)}
        {!priorityFlag && worldWidePriorityFlag && (<span>Priority Worldwide</span>)}
        {!priorityFlag && !worldWidePriorityFlag && (<span>Other</span>)}
      </div>
    );
  };

  const columns = [
    {
      Header: () => <div className={classes.leftAlign}>Time</div>,
      accessor: 'time',
      Cell: (props) => (
        <PickupTime
          pickupTime={props.value.pickupTime}
          closeTime={props.value.closeTime}
        />
      ),
    },
    {
      Header: () => <div className={classes.leftAlign}>Address</div>,
      accessor: 'address',
      Cell: (props) => (
        <PickupAddress
          address={props.value}
        />
      ),
    },
    {
      Header: 'Service',
      accessor: 'service',
      Cell: (props) => (
        <PickupService
          shippingCarrier={props.value.shippingCarrier}
          pickupDetails={props.value.pickupDetails}
        />
      ),
    },
  ];

  return (
    <SmallCardFillHeight title="Today's Pickup">
      <CardContentWrapper>
        {
          isLoading && (
            <CardIsLoading />
          )
        }
        {
          !isLoading && (
            <CardContentWithButtonWrapper>
              {
                data.length > 0 && (
                  <Table
                    data={data}
                    columns={columns}
                    showDeleteButton={false}
                    headerCellStyle={TABLE_HEADER_STYLE}
                    bodyCellStyle={TABLE_CELL_STYLE}
                  />
                )
              }
              {
                data.length < 3 && (
                  <TableIconPlaceholder
                    imgUrl="./images/dashboard/create-pickup-img.svg"
                    imgAlt="Today's Pickup"
                    message={data.length > 0 ? null : (
                      <div>
                        <div>You don't have any Pickup today.</div>
                        <span>
                          <Link
                            className="link"
                            onClick={() => { redirectPageHandler(MENU_MAP.SHIPMENTS_CREATE_PICKUP_REQUEST.route); }}
                          >
                            Create Pickup
                          </Link>
                          &nbsp;for today.
                        </span>
                      </div>
                    )}
                  />
                )
              }
              {
                data.length > 0 && (
                  <CardButtonWrapper>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => { redirectPageHandler(MENU_MAP.SHIPMENTS_CREATE_PICKUP_REQUEST.route); }}
                    >
                      See All Pickups
                    </Button>
                  </CardButtonWrapper>
                )
              }
            </CardContentWithButtonWrapper>
          )
        }
      </CardContentWrapper>
    </SmallCardFillHeight>
  );
};

const Dashboard = () => {
  const classes = useStyles();

  const history = useHistory();

  const { user } = useSelector(
    (state: RootState) => state.user
  );

  const { resolveSelectedMenuKey, resolveHeaderMenuKey } = useContext(MasterPageContext);

  const [shipmentDataIsLoading, setShipmentDataIsLoading] = useState(false);
  const [transactionDataIsLoading, setTransactionDataIsLoading] = useState(false);
  const [pickupDataIsLoading, setPickupDataIsLoading] = useState(false);

  const [shipmentData, setShipmentData] = useState([]);
  const [transactionData, setTransactionData] = useState([]);
  const [pickupData, setPickupData] = useState([]);

  const [totalShipment, setTotalShipment] = useState(null);
  const [lastShipped, setLastShipped] = useState(null);

  const mountedRef = useRef(false);

  const fetchShipments = async () => {
    try {
      setShipmentDataIsLoading(true);

      const result = await getShipments();

      setShipmentDataIsLoading(false);

      if (!mountedRef.current) {
        return;
      }
      
      if (result.data.data) {
        const shipments = result.data.data

        setTotalShipment(shipments.length);
        setLastShipped(shipments[0].shipDate);

        const shipmentServiceMap = {};

        setShipmentData(shipments.slice(0, SHIPMENT_HISTORY_LENGTH).map((e) => {
          const { shipDate, shipmentService, shippingCarrier, shipmentStatus } = e;

          const date = shipDate;
          const total = `${currencyFormatter.format(e.total)}`;
          const totalEl = <span><b>{total}</b> CAD</span>

          if (!(shipmentService in shipmentServiceMap)) {
            shipmentServiceMap[shipmentService] = carrierServices.find((ee) => ee.code === shipmentService)?.name ?? '';
          }

          return {
            date,
            service: {
              name: shipmentServiceMap[shipmentService],
              carrier: shippingCarrier,
              status: shipmentStatus, // 'created', 'cancelled', 'pending'
            },
            total: totalEl,
          };
        }));
      }
    } catch (err) {
      console.log(err);
    }

    setShipmentDataIsLoading(false);
  };

  const fetchTransactions = async () => {
    try {
      setTransactionDataIsLoading(true);

      const result = await getTransactions();

      setTransactionDataIsLoading(false);

      if (!mountedRef.current) {
        return;
      }
      
      if (result.data.data) {
        const { transactions } = result.data.data

        if (!transactions || !transactions.length) {
          return;
        }

        setTransactionData(transactions.slice(0, TRANSACTION_HISTORY_LENGTH).map((e) => {
          const date = e.date;
          const type = TRANSACTION_TYPE_MAP[e.type]
          const amount = `${currencyFormatter.format(e.amount.amount)}`;
          const amountEl = <span>{e.type === 'LABELPAYMENT' ? '-' : ''}<b>{amount}</b> CAD</span>

          return {
            date,
            type,
            total: {
              amount: amountEl,
              status: TRANSACTION_STATUS_MAP[e.status],
            },
          };
        }));
      }
    } catch (err) {
      console.log(err);
    }

    setTransactionDataIsLoading(false);
  };

  const fetchPickups = async () => {
    try {
      setPickupDataIsLoading(true);

      const result = await getPickups();

      setPickupDataIsLoading(false);

      if (!mountedRef.current) {
        return;
      }
      
      if (result.data.data) {
        const pickups = result.data.data;

        if (!pickups || !pickups.length) {
          return;
        }

        setPickupData(
          pickups
            .filter((e) => {
              const { pickupDetails } = e;
              const { pickupDate } = pickupDetails;

              const pickupDateString = moment(pickupDate).format(DATA_FORMAT_COMPARER);
              const now = moment().format(DATA_FORMAT_COMPARER);

              return pickupDateString === now;
            })
            .slice(0, TODAYS_PICKUP_LENGTH).map((e) => {
              const { pickupAddress, pickupDetails, shippingCarrier } = e;
              const { pickupTime, closeTime } = pickupDetails;

              return {
                time: {
                  pickupTime,
                  closeTime,
                },
                address: pickupAddress,
                service: {
                  shippingCarrier,
                  pickupDetails,
                },
              };
            })
        );
      }
    } catch (err) {
      console.log(err);
    }

    setTransactionDataIsLoading(false);
  };

  useEffect(() => {
    const init = () => {
      fetchShipments();
      fetchTransactions();
      fetchPickups();
    };

    mountedRef.current = true;

    init();

    return () => {
      mountedRef.current = false;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectPageHandler = (route) => {
    resolveSelectedMenuKey(route);
    resolveHeaderMenuKey(route);
    history.push(route);
  };

  const context = { redirectPageHandler };

  return (
    <DashboardContext.Provider value={context}>
      <MasterPageWrapper>
        <PageWrapper title="Dashboard">
          <Grid container spacing={2}>
            <Grid container item md={8} direction="column">
              <Grid container item>
                <Grid item xs>
                  <SmallCard title="Quick Actions">
                    <Grid container item className={classes.quickActionWrapper}>
                      <QuickActionIcon
                        imgUrl="./images/dashboard/create-shipment-img.svg"
                        label="Create Shipment"
                        onClick={() => { redirectPageHandler(MENU_MAP.SHIPMENTS_CREATE_SHIPMENT.route); }}
                      />
                      <QuickActionIcon
                        imgUrl="./images/dashboard/create-pickup-img.svg"
                        label="Create Pickup"
                        onClick={() => { redirectPageHandler(MENU_MAP.SHIPMENTS_CREATE_PICKUP_REQUEST.route); }}
                      />
                      <QuickActionIcon
                        imgUrl="./images/dashboard/add-deposit-img.svg"
                        label="Add Deposit"
                        onClick={() => { redirectPageHandler(MENU_MAP.SETTINGS_ACCOUNT_PAYMENTS.route); }}
                      />
                      <QuickActionIcon
                        imgUrl="./images/dashboard/connect-store-img.svg"
                        label="Connect Store"
                        onClick={() => { redirectPageHandler(MENU_MAP.SETTINGS_INTEGRATIONS_STORE_SETUP.route); }}
                      />
                    </Grid>
                  </SmallCard>
                </Grid>
              </Grid>
              <div className="linebreak"></div>
              <Grid container item spacing={2} className={classes.fillHeight}>
                <Grid item xs>
                  <RecentShipmentHistory
                    data={shipmentData}
                    isLoading={shipmentDataIsLoading}
                  />
                </Grid>
                <Grid item xs>
                  <RecentTransactionHistory
                    data={transactionData}
                    isLoading={transactionDataIsLoading}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container item md={4} direction="column">
              <Grid item>
                <SmallCard title={`Account #${user.account_no}`}>
                  <Grid container spacing={2} className={classes.accountCardWrapper}>
                    <Grid container item direction="column" xs={6}>
                      <span><b>Signup Date</b></span>
                      <span>-</span>
                    </Grid>
                    <Grid container item direction="column" xs={6}>
                      <span><b>Last Login</b></span>
                      <span>-</span>
                    </Grid>
                    <Grid container item direction="column" xs={6}>
                      <span><b>Total Shipments</b></span>
                      <span>{totalShipment ?? '-'}</span>
                    </Grid>
                    <Grid container item direction="column" xs={6}>
                      <span><b>Last Shipped</b></span>
                      <span>{!lastShipped ? '-' : moment(lastShipped).format(LONG_DATE_FORMAT)}</span>
                    </Grid>
                    <Grid container item direction="column" xs={6}>
                      <span><b>ShipCash</b></span>
                      <span>{currencyFormatter.format(user.accountBalance.amount)}</span>
                    </Grid>
                  </Grid>
                </SmallCard>
              </Grid>
              <div className="linebreak"></div>
              <Grid item className={classes.fillHeight}>
                <TodaysPickup
                  data={pickupData}
                  isLoading={pickupDataIsLoading}
                />
              </Grid>
            </Grid>
          </Grid>
        </PageWrapper>
      </MasterPageWrapper>
    </DashboardContext.Provider>
  );
};

export default Dashboard;
