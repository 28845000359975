import { useEffect, useMemo, useRef, useState } from "react";

import ReusableControlledTable from "../utilities/ReusableControlledTable";
import { TableSearchFilterWrapper } from "../utilities/TableComponent";

import useCustomerLogin from "../../hooks/useCustomerLogin";
import useDebounce from "../utilities/useDebounce";
import RefundShipment from "../refund-shipment/RefundShipment";

import { Link } from "@material-ui/core";
import { Shipment } from "../../@type/Shipment";
import { getCancelledShipments } from "../../apis/shipmentsService";

const CancelledShipments = () => {
  const [cancelledShipments, setCancelledShipments] = useState<Shipment[]>([]);
  const [dateFilter] = useState("");
  const [sortVal, setSortVal] = useState(null);
  const [sortColumn] = useState<string>(null);
  const [sortType, setSortType] = useState<"ASC" | "DESC" | null>(null);
  const [searchFilter, setSearchFilter] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [itemsTotal, setItemsTotal] = useState(0);
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const isFirstRun = useRef(true);
  const searchFilterDebounce = useDebounce(searchFilter, 500);
  const { handleLoginClick } = useCustomerLogin();
  const [isTableLoading, setTableLoading] = useState<
    "Loading" | "Failed" | "Success" | "NoItem"
  >("Success");

  const columns = useMemo(
    () => [
      {
        Header: "Action",
        accessor: "xxs",
        Cell: ({ row }) => (
          <RefundShipment
          // transactionId={row.original.id}
          // refreshDeposits={fetchDeposits}
          />
        ),
      },
      {
        Header: "ID",
        accessor: "id",
        Cell: ({ row }) => row.original.id,
      },
      {
        Header: "Account No",
        accessor: "accountNo",
        Cell: ({ row }) => (
          <Link onClick={() => handleLoginClick(row.original.accountNo)}>
            {row.original.accountNo}
          </Link>
        ),
      },
      {
        Header: `User Id`,
        accessor: "userId",
        Cell: ({ row }) => row.original.userId,
      },
      {
        Header: `Shipment ID`,
        accessor: "shipmentId",
        Cell: ({ row }) => row.original.shipmentId,
      },
      {
        Header: `Tracking PIN`,
        accessor: "trackPin",
        Cell: ({ row }) => row.original.trackPin,
      },
      {
        Header: `Ship Date`,
        accessor: "shipDate",
        Cell: ({ row }) => row.original.shipDate,
      },
      {
        Header: "Service name",
        accessor: "serviceName",
        Cell: ({ row }) => row.original.serviceName,
      },
      {
        Header: "Sub Total",
        accessor: "subTotal",
        Cell: ({ row }) => row.original.subTotal,
      },
      {
        Header: "Total",
        accessor: "total",
        Cell: ({ row }) => row.original.total,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => row.original.status,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sortType, sortColumn]
  );

  const fetchCancelledShipments = async () => {
    setTableLoading("Loading");

    try {
      const requestBody = {
        pageIndex,
        pageSize,
        sortVal,
        sortType,
        searchText: searchFilterDebounce,
        dateFilter,
      };

      const { data } = await getCancelledShipments(requestBody);

      if (!data?.records?.length) {
        setTableLoading("NoItem");

        return null;
      }

      if (!data?.records) {
        setCancelledShipments([]);
        setItemsTotal(0);

        return;
      }

      setCancelledShipments(data.records);
      setItemsTotal(data.total_records);
      setTableLoading("Success");
    } catch (error) {
      setTableLoading("Failed");
      console.log(error);
    }
  };
  useEffect(() => {
    // fetchCancelledShipments();

    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, pageIndex, sortType, searchFilterDebounce]);

  return (
    <div className="reusable-table-content">
      <div className="reusable-table-content-header">
        <h4>Cancelled Shipments</h4>
      </div>
      <div className="reusable-table-main-content">
        <div style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "50px",
              whiteSpace: "nowrap",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <TableSearchFilterWrapper
                globalSearch={searchFilter}
                setGlobalSearch={(value) => {
                  setSearchFilter(value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="reusable-table-content-wrapper">
          <ReusableControlledTable
            data={cancelledShipments}
            columns={columns}
            selectedRowIds={selectedRowIds}
            onSelectedRowIdsChange={setSelectedRowIds}
            isLoading={isTableLoading === "Loading"}
            placeholder={
              isTableLoading === "Failed" ? (
                "Failed to retrieve cancelled shipments."
              ) : (
                <span>There are no shipments yet.</span>
              )
            }
            placeholderStyle={
              isTableLoading === "Failed" ? { color: "red" } : {}
            }
            sortVal={sortVal}
            setSortVal={(e) => {
              setSortVal(e);
              fetchCancelledShipments();
            }}
            sortType={sortType}
            setSortType={setSortType}
            itemsTotal={itemsTotal}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            pageSizes={[50, 100]}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        </div>
      </div>
    </div>
  );
};

export default CancelledShipments;
