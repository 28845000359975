import { useState, useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';

import { MENU_MAP } from '../common';
import { Ticket } from "../../../@type/Ticket";
import { Pagination, PaginChange } from "../../../@type/Pagination";
import { getAllTickets } from "../../../apis/customerSupportService";
import { ticketsMapper } from "../../../mappers/myTickets/ticketsMapper";
import { paginationMapper } from "../../../mappers/paginationMapper";
import { useAlertActions } from "../../../redux/actions/alertActions";
import MyTicketsDefault from "./MyTickets/MyTicketsDefault";
import ClosedTicketDetails from "./TicketDialogs/ClosedTicketDetails";
import TicketDetails from "./TicketDialogs/TicketDetails";
import { getAdminsService } from "../../../apis/userService";
import { adminsMapper } from "../../../mappers/myTickets/adminsMapper";

const OpenTickets = (props) => {
  const { id: ticketId } = useParams<any>();
  const history = useHistory();
  const ticketReload = queryString.parse(props.location.search).reload;

  const { openAlertBar } = useAlertActions();

  const [level, setLevel] = useState(1);
  const [issueNumber, setIssueNumber] = useState(0);
  const [lastOpenedTicketDetails, setLastOpenedTicketDetails] = useState((new Date()).toString());

  const [pagination, setPagination] = useState<Pagination>({
    prev: null,
    next: null,
    pageSize: 20,
    itemsTotal: 0
  });
  const [paginChange, setPaginChange] = useState<PaginChange>({
    old: 1,
    new: 1,
  });
  const [currentPagination, setCurrentPagination] = useState<Pagination>({
    prev: null,
    next: null,
    pageSize: 20,
    itemsTotal: 0
  });
  const goToFirstPage = () => {
    setPaginChange({
      old: 1,
      new: 1,
    })
  };
  const goToCurrentPage = () => {
    // setTableLoading("Loading");
    getAllTickets(paginChange, currentPagination.prev, currentPagination.next, pagination.pageSize, ticketsStatusArr, globalSearch, dateFilter, sortVal, sortType).then(res => {
      if (res.data.status) {
        console.log(res.data);

        const pagin = paginationMapper(res.data);
        setPagination(pagin);
        // console.log(pagin);

        if (res.data.records.length !== 0) {
          const ticketsData = ticketsMapper(res.data.records);
          setTickets(ticketsData);
          // console.log(ticketsData);
          setTableLoading("Success");
        } else {
          setTableLoading("NoItem");
        }
      } else {
        openAlertBar(res.data.message, false);
        setTableLoading("Failed");
      }
    }).catch(error => {
      setTableLoading("Failed");
      console.log("error: " + JSON.stringify(error.response));
      openAlertBar(JSON.stringify(error.response.data.errors), false);
    });
  }

  const [globalSearch, setGlobalSearch] = useState<string>("");
  useDebounceInternal(globalSearch, 500, setPaginChange);

  const [dateFilter, setDateFilter] = useState<string>("");

  const [ticketsStatusArr, setTicketsStatusArr] = useState<string[]>([]);

  const [isTableLoading, setTableLoading] = useState<"Loading" | "Failed" | "Success" | "NoItem">("Success");

  const [tickets, setTickets] = useState<Ticket[]>([]);

  const [sortVal, setSortVal] = useState<string>(null);
  const [sortType, setSortType] = useState<"ASC" | "DESC" | null>(null);

  const [adminsList, setAdminsList] = useState<{ id: number, name: string }[]>([{ id: 0, name: "No Assignee" }]);

  const getTickets = () => {
    setTableLoading("Loading");
    getAllTickets(paginChange, pagination.prev, pagination.next, pagination.pageSize, ticketsStatusArr, globalSearch, dateFilter, sortVal, sortType).then(res => {
      if (res.data.status) {
        // console.log(res.data);

        const pagin = paginationMapper(res.data);
        setCurrentPagination(pagination);
        setPagination(pagin);
        // console.log(pagin);

        if (res.data.records.length !== 0) {
          const ticketsData = ticketsMapper(res.data.records);
          setTickets(ticketsData);
          // console.log(ticketsData);
          setTableLoading("Success");
        } else {
          setTableLoading("NoItem");
        }
      } else {
        openAlertBar(res.data.message, false);
        setTableLoading("Failed");
      }
    }).catch(error => {
      setTableLoading("Failed");
      console.log("error: " + JSON.stringify(error.response));
      openAlertBar(JSON.stringify(error.response.data.errors), false);
    });
  }

  const getAdmins = () => {
    getAdminsService().then(res => {
      if (res.data.status) {
        console.log(res.data);
        const adminsData = adminsMapper(res.data.records);
        // console.log(adminsData);
        setAdminsList(adminsData);
      } else {
        openAlertBar(res.data.message, false);
      }
    }).catch(error => {
      console.log("error: " + JSON.stringify(error.response));
      openAlertBar(JSON.stringify(error.response.data.errors), false);
    });
  }

  const isFirstRun = useRef(true);
  useEffect(() => {
    getTickets();
    getAdmins();
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginChange, sortVal, sortType]);

  useEffect(() => {
    const reload = ticketId && ticketId !== issueNumber;

    if (reload || ticketReload) {
      setLastOpenedTicketDetails((new Date()).toString());
      history.push(`${MENU_MAP.CUSTOMER_SUPPORT_MY_TICKETS.route}/${ticketId}`);
    }

    setLevel(ticketId ? 2 : 1);
    setIssueNumber(ticketId ? ticketId : 0);
  }, [history, issueNumber, ticketId, ticketReload]);

  return (
    //  Empty element if I place a div it will pop up unwanted scroll bars
    <>
      {(() => {
        switch (level) {
          case 1:
            return <MyTicketsDefault
              globalSearch={globalSearch}
              setGlobalSearch={setGlobalSearch}
              tickets={tickets}
              setTickets={setTickets}
              pagination={pagination}
              setPagination={setPagination}
              isTableLoading={isTableLoading}
              setShowingContentIndex={setLevel}
              setShowingTicketDetailsId={(e) => {
                setIssueNumber(e);
                history.push(`${MENU_MAP.CUSTOMER_SUPPORT_MY_TICKETS.route}/${e}`);
              }}
              statusArr={ticketsStatusArr}
              setStatusArr={setTicketsStatusArr}
              setPaginChange={setPaginChange}
              paginChange={paginChange}
              dateFilter={dateFilter}
              setDateFilter={setDateFilter}
              goToFirstPage={goToFirstPage}
              goToCurrentPage={goToCurrentPage}
              sortType={sortType}
              sortVal={sortVal}
              setSortType={setSortType}
              setSortVal={setSortVal}
              adminsList={adminsList}

            />
          case 2:
            return (
              <TicketDetails
                key={lastOpenedTicketDetails}
                updateLevel={(e) => {
                  setLevel(e);
                  history.push(MENU_MAP.CUSTOMER_SUPPORT_MY_TICKETS.route);
                }}
                level={1}
                ticketId={issueNumber}
                goToCurrentPage={goToCurrentPage}
                setTableLoading={setTableLoading}
              />
            )
          case 3:
            return <ClosedTicketDetails updateLevel={setLevel} ticketId={issueNumber} ></ClosedTicketDetails>
          default:
            return <></>
        }
      })()}
    </>

  );
};

export default withRouter(OpenTickets);

export function useDebounceInternal(value, delay, setPaginChange) {

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    const handler = setTimeout(() => {
      setPaginChange({
        old: 1,
        new: 1
      });
    }, delay);
    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, delay]);
}
