import { CircularProgress, Grid, IconButton } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { getResponsesTicketById, getTicketById } from "../../../../apis/customerSupportService";
import ThreadDialog from "./ThreadDialog";
import { useAlertActions } from '../../../../redux/actions/alertActions';

const styles = {

    buttonStyle: {
        height: '30px',
        width: '120px',
        textTransform: 'none',
        backgroundColor: 'green',
    } as React.CSSProperties,

    replyStyle: {
        height: '30px',
        color: 'white',
        textTransform: 'none',
        backgroundColor: '#047cfc',
    } as React.CSSProperties,

    replyCloseStyle: {
        height: '30px',
        width: '140px',
        fontSize: 'small',
        textTransform: 'none',
        backgroundColor: 'transparent',
        borderColor: '#047cfc',
        color: '#047cfc',
    } as React.CSSProperties,

    attachButtonStyle: {
        height: '40px',
        width: '100px',
        fontSize: 'small',
        textTransform: 'none',
        backgroundColor: 'transparent',
        borderColor: 'transparent',
    } as React.CSSProperties,

    header: {
        marginLeft: '10px',
        marginTop: '20px',
    } as React.CSSProperties,

    cirleButton: {
        border: 'solid',
        borderRadius: '100%',
        height: '35px',
        width: '35px',
        borderWidth: '2px',
        borderColor: 'lightgrey',
        backgroundColor: 'lightgrey',
    } as React.CSSProperties,

    cirleSmallButton: {
        border: 'solid',
        marginLeft: '10px',
        borderRadius: '100%',
        height: '25px',
        width: '25px',
        borderWidth: '2px',
        borderColor: 'lightgrey',
        backgroundColor: 'lightgrey',
    } as React.CSSProperties,

    linkStyle: {
        textTransform: 'none',
        cursor: 'pointer',
        textDecoration: 'underline',

    } as React.CSSProperties,

    divider: {
        marginTop: '20px',
        background: 'black',
        width: '100%'
    } as React.CSSProperties,

    container: {
        marginTop: '20px',
        marginLeft: '70px',
        marginRight: '60px',
        height: '50px',
        border: 'solid',
        borderLeftWidth: '7px',
        borderLeftColor: '#c4e4fc',
        borderTopColor: 'lightgrey',
        borderRightColor: 'lightgrey',
        borderBottomColor: 'lightgrey',
        borderTopWidth: '1px',
        borderRightWidth: '1px',
        borderBottomWidth: '1px',

    } as React.CSSProperties,

    generalContainer: {
        marginTop: '0px',
        marginLeft: '62px',
        width: '90%',
        border: 'solid',
        borderLeftWidth: '7px',
        borderLeftColor: '#c4e4fc',
        borderTopColor: 'lightgrey',
        borderRightColor: 'lightgrey',
        borderBottomColor: 'lightgrey',
        borderTopWidth: '1px',
        borderRightWidth: '1px',
        borderBottomWidth: '1px',
        display: 'flex',
        flexDirection: 'row',
    } as React.CSSProperties,

    replyContainer: {
        marginRight: '60px',
        marginLeft: '5px',
        border: 'solid',
        borderTopWidth: '7px',
        borderTopColor: '#c4e4fc',
        borderLeftColor: 'lightgrey',
        borderRightColor: 'lightgrey',
        borderBottomColor: 'lightgrey',
        borderLeftWidth: '1px',
        borderRightWidth: '1px',
        borderBottomWidth: '1px',
        height: `calc(100% - 30px)`,

    } as React.CSSProperties,


    replyHeader: {
        height: '40px',
        marginBottom: 'auto'
    } as React.CSSProperties,

    textStyle: {
        paddingTop: '10px',
        marginLeft: '10px',

    } as React.CSSProperties,

    textTicketStyle: {
        paddingTop: '10px',
        marginLeft: '10px',
        fontWeight: 'bold',
        fontSize: 'large',
    } as React.CSSProperties,

    liner: {
        borderLeft: 'solid',
        height: '15px',
        marginLeft: '90px',
        borderLeftColor: 'lightgrey',
        borderLeftWidth: '.1rem',

    } as React.CSSProperties,


    textAreaStyle: {
        width: '90%',
        height: '20vh',
        marginLeft: '50px',
        padding: '10px',
        border: 'solid',
        borderRadius: '5px',
        borderColor: 'lightgray',
        borderWidth: '1px',
    } as React.CSSProperties,

    attachment: {
        height: '40px',
        marginTop: '15px',
        marginLeft: '50px',
        width: '100%',
    } as React.CSSProperties,


    attachmentContainer: {
        position: 'relative',
        backgroundColor: '#c4e4fc',
        width: 'auto',
        margin: '1px',
        display: 'inline-block',
    } as React.CSSProperties,

    attachmentLabel: {
        position: 'relative',
        width: 'auto',
        height: '100%',
        display: 'inline-block',
    } as React.CSSProperties,

    attachmentIcon: {
        position: 'relative',
        width: 'auto',
        height: '100%',
        display: 'inline-block',
    } as React.CSSProperties,

};
// getTicketById
const ClosedTicketDetails = (props) => {
    const { openAlertBar } = useAlertActions();
    const { updateLevel } = props;
    const [createdAt, setCreatedAt] = useState('');
    const [status, setStatus] = useState('');
    const [summary, setSummary] = useState('');
    const [description, setDescription] = useState('');
    const [attachments] = useState([]);
    const [dataIsLoading, setDataIsLoading] = useState(false);
    const [isRecentlyLoaded] = useState(false);

    const [responseList, setResponseList] = useState([]);





    useEffect(() => {
        setDataIsLoading(true);
        try {

            const GetTicketDetails = async (id) => {

                try {
                    const data = getTicketById(id);
                    setSummary((await data).data.data.summary);
                    setStatus((await data).data.data.status);
                    setDescription((await data).data.data.description);
                    setCreatedAt((await data).data.data.createdAt);
                    var rData = getResponsesTicketById(id);
                    setResponseList((await rData).data.data);
                    setDataIsLoading(false);

                }
                catch (err) {
                    setDataIsLoading(false);
                    openAlertBar("Unable to load tickets, Please try again later!", false);
                }

            };

            GetTicketDetails(props.ticketId);
        }
        catch (err) {
            setDataIsLoading(false);
            openAlertBar("Unable to load tickets, Please try again later!", false);
        }
        // eslint-disable-line react-hooks/exhaustive-deps
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    if (dataIsLoading === true) {

        return (
            <div style={{
                minHeight: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
            }}>
                < CircularProgress />
            </div >

        );
    }
    else {
        return (
            <div style={{ display: 'table', width: '100%' }}>
                <Grid container item direction="column" xs={12} style={{
                    borderBottom: 'solid',
                    borderColor: 'black',
                    borderWidth: '1px'
                }} >
                    <h2 style={{ marginTop: '10px', marginLeft: '25px' }}>Ticket Details - Closed</h2>
                </Grid>

                <Grid container item direction="column" xs={12} >
                    <div style={{ marginTop: '25px', marginLeft: '25px', display: 'flex' }}>
                        <IconButton style={styles.cirleButton} aria-label="delete" onClick={() => updateLevel(1)} size="small">
                            <ChevronLeft fontSize="inherit" />
                        </IconButton>
                        <div style={{ marginLeft: '10px', marginTop: '5px', fontWeight: 'bold' }}>
                            <span style={{ color: '#1054ac' }}>ID: </span>{props.ticketId}
                        </div>
                        <div style={{ marginLeft: '10px', marginTop: '5px', fontWeight: 'normal' }}>
                            |
                    </div>
                        <div style={{ marginLeft: '10px', marginTop: '5px', marginRight: '5px', fontWeight: 'normal' }}>
                            <span style={{ color: '#1054ac' }}> Status: </span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div style={{
                                width: '13px', height: '13px', marginRight: '5px', borderRadius: '50%',
                                backgroundColor: status === 'OPEN' ? 'red' : 'green'
                            }}>

                            </div>
                            {status}
                        </div>

                        <div style={{ marginLeft: 'auto', display: 'flex', marginRight: '60px' }} >
                            <div style={{ marginTop: '5px', fontWeight: 'bold', marginRight: '10px' }}>
                                Created At : {formatDate(createdAt)}
                            </div>

                        </div>
                    </div>

                </Grid>
                <Grid container item direction="column" xs={12}>
                    <div style={styles.container} >
                        <div style={styles.textTicketStyle}>{summary}</div>
                    </div>
                </Grid>
                <Grid container justify="center" style={{ height: `calc(100vh - 330px)` }} >
                    <Grid container item direction="column" xs={12}  >
                        <div style={styles.liner}>

                        </div>
                    </Grid>
                    <Grid container item direction="column" xs={12} style={{ height: `calc(100% - 15px)`, marginRight: '60px' }} >
                        <div style={{ height: `calc(100% - 15px)`, paddingRight: '10px', overflowY: 'auto' }}>
                            <ThreadDialog
                                createdAt={formatDate(createdAt)}
                                description={description}
                                attachments={attachments}
                            />
                            {
                                responseList.map((a, index) => (
                                    <div>
                                        { index === 0 ?
                                            <ThreadDialog
                                                createdAt={formatDate(a.createdAt)}
                                                description={a.message}
                                                isRecentlyLoaded={isRecentlyLoaded}
                                                attachments={a.attachments}
                                            />
                                            :
                                            <ThreadDialog
                                                createdAt={formatDate(a.createdAt)}
                                                description={a.message}
                                                isRecentlyLoaded={false}
                                                attachments={a.attachments}
                                            />
                                        }
                                    </div>
                                ))
                            }


                            <div style={{ marginLeft: '70px', marginTop: '-10px' }}>
                                <Grid container spacing={2} justify="center" >
                                    <Grid container item direction="column" xs={5}>
                                        <div style={{
                                            borderTop: 'solid',
                                            borderTopColor: '#c4e4fc',
                                            marginTop: '10px',
                                            borderTopWidth: '.1rem',
                                        }}></div>
                                    </Grid>
                                    <Grid container item direction="column" xs={2}>
                                        <div style={{ color: '#1054ac', fontWeight: 'bold', textAlign: 'center' }}>End of Timeline</div>
                                    </Grid>
                                    <Grid container item direction="column" xs={5}>
                                        <div style={{
                                            borderTop: 'solid',
                                            borderTopColor: '#c4e4fc',
                                            borderTopWidth: '.1rem',
                                            marginTop: '10px',

                                        }}></div>
                                    </Grid>
                                </Grid>

                            </div>
                        </div>

                    </Grid>

                </Grid>



            </div >
        );
    }

};

export default ClosedTicketDetails;

function formatDate(string) {
    const dateObj = new Date(string);
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj.getFullYear();
    const time = dateObj.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    const output = `${year}-${(dateObj.getMonth() + 1)}-${day} ${time}`;
    return output;
}
