import Axios from "axios";
import { config, SERVER_URL } from "../environment/env_dev";
import { getFormattedParams } from "../utils/commonFunctions";

export const getShipmentService = () =>
  Axios.get(`${SERVER_URL}/api/Shipments`, config);

type ShipmentHistoryParams = {
  pageIndex: number;
  pageSize: number;
  sortVal?: string;
  sortType?: "ASC" | "DESC" | null;
  searchText?: string;
  dateFilter?: string;
  carriers?: Array<string>;
  carrierAccount?: string;
  shipmentType?: string;
  paymentMethods?: Array<string>;
  statuses?: Array<string>;
  insureType?: Array<string>;
};

export const getShipmentsByAdmin = (parameters: ShipmentHistoryParams) => {
  const paramString = getFormattedParams(parameters);

  return Axios.get(
    `${SERVER_URL}/api/Shipments/GetShipmentsByAdmin${paramString}`,
    config
  );
};

export const getshippingaccounts = () =>
  Axios.get(
    `${SERVER_URL}/api/ShippingCarrierConnect/getshippingaccounts`,
    config
  );

export const getLabelService = (labelName: string) =>
  Axios.get(
    `${SERVER_URL}/api/Shipments/GetLabel?fileName=${labelName}`,
    config
  );

export const refundService = (shipmentHId: string, emailid: string) =>
  Axios.post(
    `${SERVER_URL}/api/Shipments/refund?shipmentHistoryId=${parseInt(
      shipmentHId
    )}&email=${emailid}`,
    null,
    config
  );

export const getUserDetails = () => Axios.get(`${SERVER_URL}/api/user`, config);

export const getCancelledShipments = (parameters: ShipmentHistoryParams) => {
  const paramString = getFormattedParams(parameters);
  return Axios.get(
    `${SERVER_URL}/api/Shipments/GetCancelledShipmentsByAdmin${paramString}`,
    config
  );
};
