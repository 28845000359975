import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { store } from '../redux/store/reduxStore';
import 'bootstrap/dist/css/bootstrap.min.css';
import MasterPage from './masterPage/MasterPage';
import 'font-awesome/css/font-awesome.min.css';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
    }
  }),
);

interface Props {

}

export default function App(props: Props) {
  const classes = useStyles();

  return (
    // redux store should be the parent of the apps
    <Provider store={store}>
      <div className={classes.root}>
        <Router>
          <MasterPage />
        </Router>
      </div>
    </Provider>
  );
}
