import { Button, CircularProgress, Divider, Grid, IconButton } from "@material-ui/core";
import React, { useState } from "react";
import { createNewTicket, uploadFileToTicket } from "../../../../apis/customerSupportService";
import DropdownWrapper from "../../../utilities/DropdownButton/DropdownWrapper";
import { useAlertActions } from '../../../../redux/actions/alertActions';
import { AttachFile } from "@material-ui/icons";
import ClearIcon from '@material-ui/icons/Clear';
// import { Ticket } from "../../../../@type/Ticket";
// import { Pagination } from "../../../../@type/Pagination";

const issuesList = [
    'Cannot create a shipment',
    'Unable to add funds',
    'Cannot cancel a shipment',
    'Other',
];

const styles = {

    maxWidthStyle: {
        marginRight: '20px',
        marginTop: '10px',
        marginLeft: '50px',
        color: 'black',
    } as React.CSSProperties,

    textBoxStyle: {
        marginRight: '20px',
        marginLeft: '50px',
        padding: '10px',
        height: '30px',
        border: 'solid',
        borderRadius: '5px',
        borderColor: 'lightgray',
        borderWidth: '1px',
        backgroundColor: 'transparent',
        resize: 'none',
        outline: 'none',
    } as React.CSSProperties,

    labelStyle: {
        color: 'black',

    } as React.CSSProperties,

    textAreaStyle: {
        marginRight: '20px',
        marginTop: '5px',
        height: '20vh',
        marginLeft: '50px',
        padding: '10px',
        border: 'solid',
        borderRadius: '5px',
        borderColor: 'lightgray',
        borderWidth: '1px',
        backgroundColor: 'transparent',
        resize: 'none',
        outline: 'none',
    } as React.CSSProperties,

    buttonStyle: {
        marginLeft: '17px',
        width: '120px',
        alignItems: 'end',
        textTransform: 'none',
    } as React.CSSProperties,

    attachButtonStyle: {
        marginLeft: '40px',
        width: '90%',
        alignItems: 'start',
        textTransform: 'none',
    } as React.CSSProperties,

    gridStyle: {
        marginLeft: '-10px',
        marginTop: '10px'
    },

    dropDownStyle: {
        marginTop: '15px',
        position: 'relative',
        marginLeft: '-6%',
        width: '240px',
    } as React.CSSProperties,

    header: {
        marginLeft: '15px',
    } as React.CSSProperties,

    divider: {
        marginTop: '10px',
        marginLeft: '-5px',
        marginRight: '-7px',
    } as React.CSSProperties,

    attachment: {
        height: '40px',
        marginTop: '15px',
        marginLeft: '50px',
        width: '250px',
    } as React.CSSProperties,

    linkStyle: {
        textTransform: 'none',
        cursor: 'pointer',
        textDecoration: 'underline',

    } as React.CSSProperties,

    attachmentContainer: {
        position: 'relative',
        backgroundColor: '#c4e4fc',
        width: 'auto',
        margin: '1px',
        paddingLeft: '5px',
        display: 'inline-block',
    } as React.CSSProperties,

    attachmentLabel: {
        position: 'relative',
        width: 'auto',
        height: '100%',
        display: 'inline-block',
    } as React.CSSProperties,

    attachmentIcon: {
        position: 'relative',
        width: 'auto',
        height: '100%',
        display: 'inline-block',
    } as React.CSSProperties,
};

const TicketItem = (props) => {
    const { updateLevel } = props;
    const { setPaginChange } = props;
    const updateItems = (level: number) => {

        setIsSubmitLoading(true);
        if (selectedTransactionDropdown === 'Other' && (summary.length < 5 || summary.length > 50)) {
            openAlertBar("Please enter between 5-50 characters in summary", false);
            setIsSubmitLoading(false);
            return;
        }

        if (description.length < 10) {
            openAlertBar("Please enter at least 10 characters in description", false);
            setIsSubmitLoading(false);
            return;
        }
        createTicket(level);


    }

    // const [message, setMessage] = useState('');
    const [summary, setSummary] = useState('');
    const handleSummaryChange = (e) => {
        setSummary(e.target.value);
    }

    const [description, setDescription] = useState('');
    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    }
    const [selectedTransactionDropdown, setSelectedTransactionDropdown] = useState(issuesList[props.selectedIssue - 1]);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);

    const { openAlertBar } = useAlertActions();

    // const [message, setMessage] = useState('');
    const createTicket = (level) => {
        // TODO for Goodson:
        // After send createNewTicket request and succeed, set pagination currentPage to 1
        // Call getTickets

        var title = selectedTransactionDropdown;
        if (selectedTransactionDropdown === 'Other') {
            title = summary;
        };

        createNewTicket({
            summary: title,
            description: description,
        }).then
            (result => {
                if (result.data.status) {
                    // const pagin = paginationMapper(res.data);
                    // setPagination(pagin);
                    // setMessage(result.data.message);
                    for (var i = 0; i < selectedFiles.length; i++) {
                        const formData = new FormData();
                        formData.append('data', new Blob([JSON.stringify(selectedFiles[i])], { type: 'application/json' }));
                        formData.append('file', selectedFiles[i]);

                        uploadFileToTicket(result.data.data, formData).then
                            (upResult => {
                                if (upResult.data.status) {

                                }
                                else {
                                    openAlertBar(upResult.data.message, false);
                                }
                            }

                            ).catch(error => {
                                console.log("error: " + JSON.stringify(error.response));
                                openAlertBar(JSON.stringify(error.response.data.errors), false);
                            });
                    }

                    setPaginChange({
                        old: 1,
                        new: 1
                    })

                    openAlertBar(result.data.message, true);

                }
                else {
                    openAlertBar(result.data.message, false);

                }

                setIsSubmitLoading(false);
                updateLevel(level);
            }

            ).catch(error => {
                console.log("error: " + JSON.stringify(error.response));
                openAlertBar(JSON.stringify(error.response.data.errors), false);
            });

    };



    // const [selectedIssue, setSelectedIssue] = useState(props.selectIssue);

    const transactionDropdownListFormatter = (i) => {
        return (
            <div style={{ display: 'flex' }}>
                {issuesList[i]}
                {
                    issuesList[i] === selectedTransactionDropdown
                    // && (
                    //     // <>
                    //     //     &nbsp;
                    //     //     <Chip size="small" label="Default" color="primary" style={{ cursor: 'pointer' }} />
                    //     // </>
                    // )
                }
            </div>
        );
    };


    const [selectedFiles, setSelectedFiles] = useState([]);

    const selectFile = (event) => {
        try {
            var fileType = event.target.files[0].name.split('.').pop();
            if (fileType === 'png' || fileType === 'jpg' || fileType === 'bmp') {
                setSelectedFiles(prevArray => [...prevArray, event.target.files[0]])
            }
            else {
                openAlertBar("Please select image files such as .jpg, .png or .bmp ", false);
            }
        }
        catch (err) {

        }

    };

    const clearFile = (name) => {

        try {
            const arr = selectedFiles.filter((item) => item.name !== name);
            setSelectedFiles(arr);
        }
        catch (err) {

        }
    };

    return (

        <div style={{ height: '480px', width: '99%' }}>
            <div style={{ display: 'flex', marginTop: '20px' }}>
                <div style={{ marginLeft: '40px', fontWeight: 'bold', marginTop: '4px' }} >Your problem </div>
                <div style={{ marginLeft: '10px', marginBottom: '5px', width: '250px' }}  >
                    <DropdownWrapper

                        btnName={selectedTransactionDropdown}
                        btnNameCustomEl={(
                            <div style={{ display: 'flex' }}>
                                {selectedTransactionDropdown}

                            </div>
                        )}
                        color="default"
                        variant="outlined"
                        size="medium"
                        list={issuesList}
                        listFormatter={transactionDropdownListFormatter}
                        setSelect={(e) => { setSelectedTransactionDropdown(e); }}
                    />
                </div>
            </div>
            <Divider style={styles.divider} variant="middle" />
            <div>
                <Grid container justify="flex-start" spacing={2}>

                    <Grid container item direction="column" spacing={2} style={{ marginTop: '5px' }} xs={12}>
                        <div style={styles.maxWidthStyle}>Summary</div>
                    </Grid>
                    <Grid container item direction="column" spacing={2} xs={12}>
                        <input type="text" value={selectedTransactionDropdown === 'Other' ? summary : selectedTransactionDropdown} onChange={handleSummaryChange} style={styles.textBoxStyle} placeholder="Summary" />
                    </Grid>
                    <Grid container item direction="column" spacing={2} xs={12}>
                        <div style={styles.maxWidthStyle}>Description</div>
                    </Grid>
                    <Grid container item direction="column" spacing={2} xs={12}>
                        <textarea style={styles.textAreaStyle} onChange={handleDescriptionChange} placeholder="Describe your problem" />
                    </Grid>
                    <Grid container item direction="column" spacing={2} xs={12}>
                        <div style={{ overflow: 'auto', height: '40px', marginTop: '10px', marginLeft: '50px', }}>
                            {
                                selectedFiles.map((a, index) => (

                                    <div style={styles.attachmentContainer}>
                                        <div style={styles.attachmentLabel}>
                                            <label>{a.name}</label>
                                        </div>
                                        <div style={styles.attachmentIcon}>
                                            <IconButton aria-label="delete" size="small" onClick={() => clearFile(a.name)}>
                                                <ClearIcon fontSize="inherit" />
                                            </IconButton>
                                        </div>
                                    </div>
                                ))
                            }

                        </div>
                    </Grid>
                    <Grid container item direction="column" spacing={2} xs={3}>
                        <div style={styles.attachButtonStyle}>

                            <Button
                                variant="outlined"
                                component="label"
                                startIcon={<AttachFile />}
                                style={{ borderColor: 'transparent', textTransform: 'none', backgroundColor: 'transparent' }}
                            >
                                Attach Files
                        <input
                                    type="file"
                                    hidden
                                    onChange={selectFile}
                                    accept="image/png, image/jpeg, image/jpg"
                                />
                            </Button>
                        </div>

                    </Grid>
                    <Grid container item direction="column" spacing={2} xs={7}>


                    </Grid>
                    <Grid container item direction="column" spacing={2} xs={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={isSubmitLoading}
                            style={styles.buttonStyle}
                            onClick={() => updateItems(3)}
                        >
                            {
                                isSubmitLoading ?
                                    <CircularProgress style={{ color: 'white' }} size={20} />
                                    :
                                    <div> Submit</div>
                            }

                        </Button>

                    </Grid>

                </Grid >

            </div>
        </div>

    );
};

export default TicketItem;
