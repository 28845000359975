import Axios from 'axios';
import { config, SERVER_URL } from '../environment/env_dev';

export const getPickups = () => {
    return Axios.get(`${SERVER_URL}/api/pickup`, config);
};

export const createPickup = (data) => {
    return Axios.post(`${SERVER_URL}/api/pickup`, data, config);
};

export const updatePickup = (id, data) => {
    return Axios.put(`${SERVER_URL}/api/pickup?pickupid=${id}`, data, config);
};

export const cancelPickup = (id) => {
    return Axios.delete(`${SERVER_URL}/api/pickup`, {
        ...config,
        data: id,
    });
};
