import { useState, useMemo, MouseEvent } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

import TableSortingHeader from "../../../utilities/MainTableComponents/TableSortingHeader";
import CloseReopenDialog from "../TicketDialogs/CloseReopenDialog";
import { useAlertActions } from "../../../../redux/actions/alertActions";
import { changeAssignee } from "../../../../apis/customerSupportService";
import { Ticket } from "../../../../@type/Ticket";
import { Pagination, PaginChange } from "../../../../@type/Pagination";
import ReactTable from "../../../react-table/ReactTable";

export const useStyles = makeStyles(() =>
  createStyles({
    statusBtn: {
      "& .MuiButton-label": {
        textTransform: "none",
      },
      padding: 5,
    },
    linkButton: {
      color: "#069",
      textDecoration: "underline",
      cursor: "pointer",
      "&:hover": {
        color: "#0099E6",
      },
    },
    assigneeSelection: {
      cursor: "pointer",
      border: "unset",
    },
    userCell: {
      cursor: "pointer",
    },
    userDetails: {
      fontSize: 12,
      "& td": {
        padding: "5px 8px",
      },
    },
    statusDiv: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    statusOuter: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      "&:hover": {
        textDecoration: "underline",
        color: "#0099E6",
      },
      backgroundColor: "white",
      border: "none",
    },
    statusDot: {
      width: "13px",
      height: "13px",
      marginRight: "5px",
      borderRadius: "50%",
    },
  })
);

interface Props {
  tickets: Ticket[];
  pagination: Pagination;
  setPagination: (value: Pagination) => void;
  isTableLoading: "Loading" | "Failed" | "Success" | "NoItem";
  setShowingContentIndex: (value: number) => void;
  setShowingTicketDetailsId: (value: number) => void;
  setPaginChange: (value: PaginChange) => void;
  paginChange: PaginChange;
  sortType: "ASC" | "DESC" | null;
  sortVal: string;
  setSortVal: (value: string) => void;
  setSortType: (value: "ASC" | "DESC" | null) => void;
  goToFirstPage: () => void;
  adminsList: { id: number; name: string }[];
  goToCurrentPage: () => void;
}

export default function MyTicketsTable({
  goToCurrentPage,
  adminsList,
  goToFirstPage,
  sortVal,
  sortType,
  setSortType,
  setSortVal,
  tickets,
  pagination,
  setPagination,
  isTableLoading,
  setShowingContentIndex,
  setShowingTicketDetailsId,
  setPaginChange,
  paginChange,
}: Props) {
  const classes = useStyles();

  const { openAlertBar } = useAlertActions();

  const data = useMemo(() => tickets, [tickets]);
  // const [isTableLoading, setTableLoading] = useState<"Loading" | "Failed" | "Success" | "NoItem">("Success");
  const sortClick = (property: string) => {
    setSortVal(property);
    if (!sortType || property !== sortVal) {
      setSortType("DESC");
      if (property !== sortVal) {
        goToFirstPage();
      }
    } else if (sortType === "DESC") {
      setSortType("ASC");
    } else if (sortType === "ASC") {
      setSortType(null);
    }
  };

  const [selectedTicket, setSelectedTicket] = useState<Ticket>(null);

  //Ticket selection change
  const closeTicketText = useState<string>("Close the ticket");
  const openTicketText = useState<string>("Reopen the ticket");
  const [openReopenDialog, setOpenReopenDialog] = useState<boolean>(false);

  const getSelectedTicket = () => {
    if (selectedTicket) {
      return selectedTicket;
    }
    return "";
  };

  const handleTicketActions = () => {
    setOpenReopenDialog(true);
    handleCloseStatus();
  };

  // popover for change status
  const [anchorElStatus, setAnchorElStatus] =
    useState<HTMLButtonElement | null>(null);
  const handleClickStatus = (
    event: MouseEvent<HTMLButtonElement>,
    ticket: Ticket
  ) => {
    setAnchorElStatus(event.currentTarget);
    setSelectedTicket(ticket);
  };
  const handleCloseStatus = () => {
    setAnchorElStatus(null);
  };
  const openStatus = Boolean(anchorElStatus);
  const idStatus = openStatus ? "status-popover" : undefined;
  // -------------------

  // popover for change assignee
  const [anchorElAssignee, setAnchorElAssignee] =
    useState<HTMLButtonElement | null>(null);
  const [selectedAssignee, setSelectedAssignee] = useState<{
    id: number;
    name: string;
  }>(null);
  const [loadingAssignee, setLoadingAssignee] = useState<boolean>(false);
  const handleClickAssignee = (
    event: MouseEvent<HTMLButtonElement>,
    ticket: Ticket
  ) => {
    setAnchorElAssignee(event.currentTarget);
    setSelectedTicket(ticket);
  };
  const handleCloseAssignee = () => {
    setAnchorElAssignee(null);
  };
  const openAssignee = Boolean(anchorElAssignee);
  const idAssignee = openAssignee ? "assignee-popover" : undefined;

  const chooseAssignee = (support: { id: number; name: string }) => {
    setSelectedAssignee(support);
    setLoadingAssignee(true);
    changeAssignee(selectedTicket.id, support.id)
      .then((res) => {
        setLoadingAssignee(false);
        if (res.data.status) {
          handleCloseAssignee();
          goToCurrentPage();
          // openAlertBar(res.data.message, true);
        } else {
          openAlertBar(res.data.message, false);
        }
      })
      .catch((error) => {
        setLoadingAssignee(false);
        console.log("error: " + JSON.stringify(error.response));
        openAlertBar(JSON.stringify(error.response.data.errors), false);
      });
  };
  // -------------------

  // const [supportList, setSupportList] = useState<{ id: number, name: string }[]>([
  //     { id: 0, name: "No Assignee" },
  //     { id: 1011, name: "support1" },
  //     { id: 1012, name: "support2" },
  //     { id: 1013, name: "support3" },
  //     { id: 1014, name: "support4" },
  // ]);

  const columns = useMemo(
    () => [
      {
        Header: (
          <TableSortingHeader
            header="Ticket ID"
            property="id"
            sortType={sortType}
            sortVal={sortVal}
            sortClick={sortClick}
          />
        ),
        accessor: "id",
        Cell: ({ row }) => (
          <div
            className={classes.linkButton}
            onClick={(e) => {
              setShowingContentIndex(2);
              setShowingTicketDetailsId(row.original.id);
            }}
          >
            {row.original.id}
          </div>
        ),
      },
      {
        Header: "Ticket Summary",
        accessor: "summary",
        Cell: ({ row }) => <div>{row.original.summary}</div>,
      },
      {
        Header: `Ticket Content Overview`,
        accessor: "description",
        Cell: ({ row }) => (
          <div>
            {row.original.description.length > 50
              ? `${row.original.description.slice(0, 100)} ...`
              : row.original.description}
          </div>
        ),
      },
      {
        Header: "Age",
        accessor: "age",
        Cell: ({ row }) => <div>{row.original.age}</div>,
      },
      {
        Header: (
          <TableSortingHeader
            header="Created At"
            property="createdAt"
            sortType={sortType}
            sortVal={sortVal}
            sortClick={sortClick}
          />
        ),
        accessor: "createdAt",
        Cell: ({ row }) => (
          <div>
            {moment(row.original.createdAt).format("YYYY/MM/DD h:mm a")}
          </div>
        ),
      },
      {
        Header: (
          <TableSortingHeader
            header="Last Updated At"
            property="lastUpdatedAt"
            sortType={sortType}
            sortVal={sortVal}
            sortClick={sortClick}
          />
        ),
        accessor: "lastUpdatedAt",
        Cell: ({ row }) => (
          <div>
            {moment(row.original.lastUpdatedAt).format("YYYY/MM/DD h:mm a")}
          </div>
        ),
      },
      {
        Header: "Resolved In",
        accessor: "resolvedIn",
        Cell: ({ row }) => <div>{row.original.resolvedIn}</div>,
      },
      {
        Header: "User",
        accessor: "createdBy",
        Cell: ({ row }) => (
          <div>
            <Tooltip
              arrow
              title={
                <UserDetails
                  userId={row.original.createdById}
                  userName={row.original.createdBy}
                />
              }
            >
              <div className={classes.userCell}>{row.original.createdBy}</div>
            </Tooltip>
          </div>
        ),
      },
      {
        Header: "Assignee",
        // accessor: 'age',
        Cell: ({ row }) => (
          <div className={classes.statusDiv}>
            <button
              type="button"
              className={classes.statusOuter}
              aria-describedby={idAssignee}
              onClick={(e) => handleClickAssignee(e, row.original)}
            >
              {row.original.assignee ? row.original.assignee : "No Assignee"}
              <ExpandMoreIcon fontSize="small" style={{ color: "gray" }} />
            </button>
          </div>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => (
          <div className={classes.statusDiv}>
            <button
              type="button"
              className={classes.statusOuter}
              aria-describedby={idStatus}
              onClick={(e) => handleClickStatus(e, row.original)}
            >
              <div
                className={classes.statusDot}
                style={{
                  backgroundColor:
                    row.original.status === "OPEN"
                      ? "red"
                      : row.original.status === "INPROGRESS"
                      ? "yellow"
                      : "green",
                }}
              />
              {row.original.status === "OPEN"
                ? "Open"
                : row.original.status === "INPROGRESS"
                ? "In progress"
                : "Closed"}
              <ExpandMoreIcon fontSize="small" style={{ color: "gray" }} />
            </button>
          </div>
        ),
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    [sortType, sortVal]
  );

  return (
    <>
      <ReactTable
        isTableLoading={isTableLoading}
        pagination={pagination}
        setPagination={setPagination}
        columns={columns}
        data={data}
        setPaginChange={setPaginChange}
        paginChange={paginChange}
        errorMessage="Failed to retrieve tickets"
      />
      <CloseReopenDialog
        selectedTicket={getSelectedTicket()}
        open={openReopenDialog}
        goToCurrentPage={goToCurrentPage}
        // setTableLoading={setTableLoading}
        setOpen={(value) => {
          setOpenReopenDialog(value);
        }}
      />
      <Popover
        id={idStatus}
        open={openStatus}
        anchorEl={anchorElStatus}
        onClose={handleCloseStatus}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {selectedTicket?.status === "OPEN" ||
        selectedTicket?.status === "INPROGRESS" ? (
          <Button
            size="small"
            onClick={handleTicketActions}
            className={classes.statusBtn}
          >
            {closeTicketText}
          </Button>
        ) : (
          <Button
            size="small"
            onClick={handleTicketActions}
            className={classes.statusBtn}
          >
            {openTicketText}
          </Button>
        )}
      </Popover>
      <Popover
        id={idAssignee}
        open={openAssignee}
        anchorEl={anchorElAssignee}
        onClose={handleCloseAssignee}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {adminsList.map((support) => (
          <div>
            <Button
              fullWidth
              size="small"
              className={classes.statusBtn}
              onClick={() => chooseAssignee(support)}
              endIcon={
                selectedAssignee?.id === support.id && loadingAssignee ? (
                  <CircularProgress size={10} style={{ color: "gray" }} />
                ) : (
                  <></>
                )
              }
            >
              {support.name}
            </Button>
          </div>
        ))}
      </Popover>
    </>
  );
}

function UserDetails(props) {
  const classes = useStyles();
  const { userId, userName } = props;
  return (
    <table className={classes.userDetails}>
      <tbody>
        <tr>
          <td>User Id</td>
          <td>{userId}</td>
        </tr>
        <tr>
          <td>User Name</td>
          <td>{userName}</td>
        </tr>
      </tbody>
    </table>
  );
}
