import { Link } from "@material-ui/core";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { Deposit } from "../../@type/Payments/Deposit";
import { getDepositHistory } from "../../apis/deposit";
import useCustomerLogin from "../../hooks/useCustomerLogin";
import AddDepositDialog from "../add-deposit-dialog/AddDepositDialog";
import DeleteDepositDialog from "../delete-deposit-dialog/DeleteDepositDialog";

import ReusableControlledTable from "../utilities/ReusableControlledTable";
import { TableSearchFilterWrapper } from "../utilities/TableComponent";
import useDebounce from "../utilities/useDebounce";

const DepositHistory = () => {
  const [isTableLoading, setTableLoading] = useState<
    "Loading" | "Failed" | "Success" | "NoItem"
  >("Success");
  const [deposits, setDeposits] = useState<Deposit[]>([]);
  const [dateFilter] = useState("");
  const [sortVal, setSortVal] = useState(null);
  const [sortColumn] = useState<string>(null);
  const [sortType, setSortType] = useState<"ASC" | "DESC" | null>(null);
  const [searchFilter, setSearchFilter] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [itemsTotal, setItemsTotal] = useState(0);
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const isFirstRun = useRef(true);
  const searchFilterDebounce = useDebounce(searchFilter, 500);
  const { handleLoginClick } = useCustomerLogin();

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        Cell: ({ row }) => row.original.id,
      },
      {
        Header: "Account No",
        accessor: "accountNo",
        Cell: ({ row }) => (
          <Link onClick={() => handleLoginClick(row.original.accountNo)}>
            {row.original.accountNo}
          </Link>
        ),
      },
      {
        Header: `User Id`,
        accessor: "userId",
        Cell: ({ row }) => row.original.userId,
      },
      {
        Header: `Deposit Amount`,
        accessor: "depositAmount",
        Cell: ({ row }) => row.original.depositAmount,
      },
      {
        Header: `Deposit by`,
        accessor: "depositBy",
        Cell: ({ row }) => row.original.depositBy,
      },
      {
        Header: `Remark`,
        accessor: "remark",
        Cell: ({ row }) => row.original.remark,
      },
      {
        Header: "IP Address",
        accessor: "ipAddress",
        Cell: ({ row }) => row.original.ipAddress,
      },
      {
        Header: "Created At",
        accessor: "createdAt",
        Cell: ({ row }) =>
          moment(row.original.createdAt).format("YYYY/MM/DD h:mm a"),
      },
      {
        Header: "",
        accessor: "xxs",
        Cell: ({ row }) => (
          <DeleteDepositDialog
            transactionId={row.original.id}
            refreshDeposits={fetchDeposits}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sortType, sortColumn]
  );

  const fetchDeposits = async () => {
    setTableLoading("Loading");

    try {
      const requestBody = {
        pageIndex,
        pageSize,
        sortVal,
        sortType,
        searchText: searchFilterDebounce,
        dateFilter,
      };

      const { data } = await getDepositHistory(requestBody);

      if (!data?.records?.length) {
        setTableLoading("NoItem");

        return null;
      }

      if (!data?.records) {
        setDeposits([]);
        setItemsTotal(0);

        return;
      }

      setDeposits(data.records);
      setItemsTotal(data.total_records);
      setTableLoading("Success");
    } catch (error) {
      setTableLoading("Failed");
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDeposits();

    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, pageIndex, sortType, searchFilterDebounce]);

  return (
    <div className="reusable-table-content">
      <div className="reusable-table-content-header">
        <h4>Customer Deposit History</h4>
        <AddDepositDialog refreshDeposits={fetchDeposits} />
      </div>
      <div className="reusable-table-main-content">
        <div style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "50px",
              whiteSpace: "nowrap",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <TableSearchFilterWrapper
                globalSearch={searchFilter}
                setGlobalSearch={(value) => {
                  setSearchFilter(value);
                }}
              />
              {/* <TableSelectFilterWrapper
                emptyBtnName="CARRIER ACCOUNT"
                btnName={selectedCarrierAccount}
                list={Shipment_CARRIER_ACCOUNT_MAP}
                setSelect={(e) => {
                  setSelectedCarrierAccount(e);
                  getShipments();
                }}
              />
              <TableSelectFilterWrapper
                emptyBtnName="SHIPMENT TYPE"
                btnName={selectedShipmentTypeDropdown}
                list={Object.values(Shipment_TYPE_MAP)}
                setSelect={(e) => {
                  setSelectedShipmentTypeDropdown(e);
                  getShipments();
                }}
              /> */}
            </div>
          </div>
        </div>
        <div className="reusable-table-content-wrapper">
          <ReusableControlledTable
            data={deposits}
            columns={columns}
            selectedRowIds={selectedRowIds}
            onSelectedRowIdsChange={setSelectedRowIds}
            isLoading={isTableLoading === "Loading"}
            placeholder={
              isTableLoading === "Failed" ? (
                "Failed to retrieve deposits."
              ) : (
                <span>There are no shipments yet.</span>
              )
            }
            placeholderStyle={
              isTableLoading === "Failed" ? { color: "red" } : {}
            }
            sortVal={sortVal}
            setSortVal={(e) => {
              setSortVal(e);
              fetchDeposits();
            }}
            sortType={sortType}
            setSortType={setSortType}
            itemsTotal={itemsTotal}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            pageSizes={[50, 100]}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        </div>
      </div>
    </div>
  );
};

export default DepositHistory;
