import { Button } from '@material-ui/core'
import React, { useState } from 'react'
import { addDepositAdmin } from '../../apis/deposit';
import { useAlertActions } from '../../redux/actions';
import DropdownWrapper from '../utilities/DropdownButton/DropdownWrapper';
import FixedDialogWrapper from '../utilities/FixedDialogWrapper';
import FloatingTextField from '../utilities/FloatingTextField';

const AddDepositDialog = ({ refreshDeposits }) => {
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [depositMethod, setDepositMethod] = useState("");
  const [depositCurrency, setDepositCurrency] = useState('')
  const [accountNo, setAccountNo] = useState("");
  const [remarks, setRemarks] = useState("");
  const [addNewDepositDialog, setAddNewDepositDialog] = useState(false);
  const { openAlertBar } = useAlertActions();

  const addDepositClick = async () => {
    setLoading(true);
    try {
      const requestBody = {
        amount,
        depositMethod,
        remarks,
        accountNo,
        currency: depositCurrency
      }
      const { data } = await addDepositAdmin(requestBody);

      if (data.status) {
        setAddNewDepositDialog(false);
        openAlertBar(data.message, true)
        refreshDeposits();
      } else {
        openAlertBar(data.message, false)
      }

    } catch (error) {
      openAlertBar("Unexpected error occured: " + error, false)

    }
    setLoading(false);
  }

  return (
    <>
      <Button
        size="small"
        variant="contained"
        color="primary"
        onClick={() => setAddNewDepositDialog(true)}
      >
        Add New Deposit
      </Button>

      <FixedDialogWrapper
        dialogId="add-deposit-dialog"
        title="Add Deposit Dialog"
        maxWidth='sm'
        open={addNewDepositDialog}
        setOpen={setAddNewDepositDialog}
        buttonDefinitions={[
          {
            label: 'Cancel',
            onClick: () => setAddNewDepositDialog(false)
          },
          {
            label: 'Submit',
            color: 'primary',
            disabled: loading,
            onClick: addDepositClick,
            isLoading: loading,
          }
        ]}
      >
        <div style={{ padding: "20px" }}>
          <FloatingTextField
            label="Enter amount to deposit"
            value={amount}

            onChange={(e) => setAmount(e.target.value)}
          />
          <div style={{ marginBottom: '10px' }}>Deposit Method</div>
          <DropdownWrapper
            btnName={'-- Select --'}
            btnNameCustomEl={(
              <div style={{ display: 'flex' }}>
                {depositMethod}
              </div>
            )}
            color="default"
            variant="outlined"
            size="medium"
            style={{ marginBottom: '10px' }}
            list={["Cash", "Stripe", "Bank", "Intrac", "Charge", "Other"]}
            setSelect={(value) => setDepositMethod(value)}
          />
          <div style={{ marginBottom: '10px' }}>Deposit Currency</div>
          <DropdownWrapper
            btnName={'-- Select --'}
            btnNameCustomEl={(
              <div style={{ display: 'flex' }}>
                {depositCurrency}
              </div>
            )}
            color="default"
            variant="outlined"
            size="medium"
            style={{ marginBottom: '32px' }}
            list={["CAD", "USD"]}
            setSelect={(value) => setDepositCurrency(value)}
          />
          <div style={{ marginBottom: '32px' }}>
            <FloatingTextField
              label="Customer account number"
              value={accountNo}
              onChange={(e) => setAccountNo(e.target.value)}
            />
          </div>
          <div style={{ marginBottom: '20px' }}>
            <FloatingTextField
              label="Remark"
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
            />
          </div>
        </div>
      </FixedDialogWrapper>
    </>
  )
}

export default AddDepositDialog