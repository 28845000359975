export const formatToFixedDecimal = (_price: number) => {
  let calcDec = Math.pow(10, 2);

  return Math.trunc(_price * calcDec) / calcDec;
};

export const roundAfterDecimalPoint = (num: number) =>
  Math.round((num + Number.EPSILON) * 100) / 100;

export const convertCurrencyToSymbol = (currency, locale = "en-CA") =>
  new Intl.NumberFormat(locale, { style: "currency", currency })
    .formatToParts(1)
    .find((x) => x.type === "currency").value;

export const getFormattedParams = (parameters) => {
  const params = [];

  params.push({ key: "Limit", value: parameters.pageSize });

  if (parameters.pageIndex) {
    params.push({ key: "Page", value: parameters.pageIndex });
  }

  if (parameters.sortType) {
    params.push({ key: "SortType", value: parameters.sortType });
    params.push({ key: "SortColumn", value: parameters.sortVal });
  }

  if (parameters.searchText) {
    params.push({ key: "SearchText", value: parameters.searchText.trim() });
  }

  if (parameters.dateFilter) {
    let startDate = "";
    let endDate = "";

    if (parameters.dateFilter.includes("-")) {
      const arrDates = parameters.dateFilter.split(" - ");

      startDate = arrDates[0].replaceAll("/", "-");
      endDate = arrDates[1].replaceAll("/", "-");
    } else {
      startDate = endDate = parameters.dateFilter.replaceAll("/", "-");
    }

    params.push({ key: "MinDate", value: startDate });
    params.push({ key: "MaxDate", value: endDate });
  }

  if (parameters.carriers) {
    for (let i = 0, ii = parameters.carriers.length; i < ii; i += 1) {
      params.push({ key: "Carrier", value: parameters.carriers[i] });
    }
  }
  if (parameters.insureType) {
    for (let i = 0, ii = parameters.insureType.length; i < ii; i += 1) {
      params.push({ key: "InsuranceType", value: parameters.insureType[i] });
    }
  }
  if (parameters.carrierAccount) {
    params.push({ key: "CarrierAccount", value: parameters.carrierAccount });
  }

  if (parameters.shipmentType) {
    params.push({ key: "ShipmentType", value: parameters.shipmentType });
  }

  if (parameters.paymentMethods) {
    for (let i = 0, ii = parameters.paymentMethods.length; i < ii; i += 1) {
      params.push({
        key: "PaymentMethod",
        value: parameters.paymentMethods[i],
      });
    }
  }

  if (parameters.statuses) {
    for (let i = 0, ii = parameters.statuses.length; i < ii; i += 1) {
      params.push({ key: "Status", value: parameters.statuses[i] });
    }
  }

  const paramString = `?${params.map((e) => `${e.key}=${e.value}`).join("&")}`;

  return paramString;
};
