import { IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { useState } from 'react'
import { deleteDepositAdmin } from '../../apis/deposit';

import { useAlertActions } from '../../redux/actions';
import FixedDialogWrapper from '../utilities/FixedDialogWrapper'

const DeleteDepositDialog = ({ transactionId,refreshDeposits }) => {
  const [loading, setLoading] = useState(false);
  const [deleteDepsitDialog, setDeleteDepsitDialog] = useState(false)
  const { openAlertBar } = useAlertActions();

  const deleteDepositClick = async () => {
    setLoading(true);

    try {
      const { data } = await deleteDepositAdmin(transactionId);

      if (data.status) {
        setDeleteDepsitDialog(false);
        openAlertBar(data.message, true)
        refreshDeposits();
      } else {
        openAlertBar(data.message, false)
      }

    } catch (error) {
      openAlertBar("Unexpected error occured: " + error, false)
    }

    setLoading(false);
  }

  return (
    <>
      <IconButton size='small' color='secondary' onClick={() => setDeleteDepsitDialog(s => !s)}>
        <Delete />
      </IconButton>
      <FixedDialogWrapper
        dialogId="update-customer-details"
        title="Update Customer Status"
        maxWidth='sm'
        open={deleteDepsitDialog}
        setOpen={setDeleteDepsitDialog}
        buttonDefinitions={[
          {
            label: 'Cancel',
            onClick: () => setDeleteDepsitDialog(false)
          },
          {
            label: 'Submit',
            color: 'primary',
            disabled: loading,
            onClick: deleteDepositClick,
            isLoading: loading,
          }
        ]}
      >
        <div style={{ padding: "20px" }}>
          Are you sure you want to delete transaction {transactionId}
        </div>
      </FixedDialogWrapper>
    </>
  )
}

export default DeleteDepositDialog