import React from 'react'
import { APP_COLOR } from "../../environment/env_dev";
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTable } from "react-table";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { CircularProgress, IconButton } from "@material-ui/core/";

import { Pagination, PaginChange } from "../../@type/Pagination";

const useStyles = makeStyles(() =>
    createStyles({
        reactTable: {
            width: "100%",
            border: "none",
            borderCollapse: "separate",
            borderSpacing: "unset",
            fontSize: '0.9rem',
            "& th": {
                position: "sticky",
                fontWeight: "600",
                top: "0",
                zIndex: 10,
                padding: "8px 8px",
                backgroundColor: "#F3F3F3",
                borderLeft: "1px solid #DDDDDD",
                "&:first-child": {
                    borderLeft: "3px solid #DDDDDD",
                    width: "8%"
                },
                "&:nth-child(2)": {
                    width: "15%"
                },
                "&:nth-child(3)": {
                    width: "20%"
                },
                "&:nth-child(4)": {
                    width: "5%",
                    textAlign: "center"
                },
                "&:nth-child(7)": {
                    textAlign: "center"
                },
                "&:nth-child(8)": {
                    textAlign: "center"
                },
                "&:nth-child(9)": {
                    textAlign: "center"
                },
                "&:last-child": {
                    width: "9%",
                    borderRight: "1px solid #DDDDDD",
                    textAlign: "center"
                }
            },
            "& td": {
                padding: "5px 8px",
                borderBottom: "1px solid #DDDDDD",
                "&:first-child": {
                    borderLeft: "3px solid #DDDDDD",
                },
                "&:last-child": {
                    borderRight: "1px solid #DDDDDD",
                    textAlign: "center"
                },
                "&:nth-child(4)": {
                    textAlign: "center"
                },
                "&:nth-child(7)": {
                    textAlign: "center"
                },
                "&:nth-child(8)": {
                    textAlign: "center"
                },
            },
        },
        iconBtn: {
            padding: 3
        },
        pagination: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            backgroundColor: "#F3F3F3",
            padding: 5,
            margin: "0 10px",
            fontSize: '0.9rem',
            fontWeight: 600
        },
        paginItem: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
        },
        indeCb: {
            textAlign: "center",
            margin: "0px -2px"
        },
        tableOuter: {
            margin: "0 10px",
            width: "calc(100% - 20px)",
            height: "calc(100vh - 255px)",  // to fix the table
            overflowY: "scroll"
        },
        failedMsg: {
            marginTop: "18%",
            textAlign: "center",
            fontSize: 16,
            fontWeight: 600,
            color: "red"
        },
        loadingMsg: {
            marginTop: "18%",
            textAlign: "center",
            fontSize: 16,
            fontWeight: 600,
        },
        progressGrey: {
            color: "grey",
            marginRight: 5
        },
        noAddressMsg: {
            marginTop: "18%",
            textAlign: "center",
            fontSize: 16,
            fontWeight: 600,
        },
        actionsOuter: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center"
        },
        editBtn: {
            padding: 6,
            color: APP_COLOR.appLightBlue
        },
        shipBtn: {
            padding: 6,
        },
        shipSvg: {
            fontSize: 25,
        },
        centerCell: {
            textAlign: "center",
        },
        paginationOuter: {
            position: "absolute",
            bottom: 0,
            width: "100%"
        },
        sort: {
            cursor: "pointer",
            '&:hover': {
                color: "#0099E6",
                textDecoration: "underline",
            },
            display: "flex",
            flexDirection: "row"
        },
    }),
);

interface ReactTableProps {
    columns: any,
    data: any,
    pagination: Pagination;
    setPagination: (value: Pagination) => void;
    isTableLoading: "Loading" | "Failed" | "Success" | "NoItem",
    errorMessage: string,
    setPaginChange: (value: PaginChange) => void;
    paginChange: PaginChange
}

const ReactTable = ({
    columns,
    data,
    isTableLoading,
    pagination,
    setPagination,
    setPaginChange,
    paginChange,
    errorMessage
}: ReactTableProps) => {
    const classes = useStyles();

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
    } = useTable(
        {
            columns,
            data,
        },
    )

    // Render the UI for your table
    return (
        <>
            <div className={classes.tableOuter}>
                <table className={classes.reactTable} {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()}>
                                        {column.render("Header")}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    {
                        isTableLoading === "Success"
                            ? <tbody {...getTableBodyProps()}>
                                {rows.map((row, i) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => {
                                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                            : <></>
                    }
                </table>
                {isTableLoading === "Loading"
                    ? <div className={classes.loadingMsg}><span><CircularProgress size={18} className={classes.progressGrey} /></span><span>Loading...</span></div>
                    : isTableLoading === "Failed"
                        ? <div className={classes.failedMsg}>{errorMessage}</div>
                        : isTableLoading === "NoItem"
                            ? <div className={classes.noAddressMsg} >No ticket in the queue.</div>
                            : <></>
                }
            </div>

            <div className={classes.paginationOuter}>
                <div className={classes.pagination}>
                    <div className={classes.paginItem}>
                        <div>Row {(paginChange.new - 1) * pagination.pageSize + 1}-{pagination.itemsTotal > ((paginChange.new - 1) + 1) * pagination.pageSize ? ((paginChange.new - 1) + 1) * pagination.pageSize : pagination.itemsTotal} of {pagination.itemsTotal}</div>
                        <div>
                            <IconButton
                                disabled={!pagination.prev}
                                onClick={() => {
                                    const old = paginChange.new;
                                    setPaginChange({
                                        old: old,
                                        new: old - 1
                                    })
                                }}
                                className={classes.iconBtn}
                                aria-label="previousPage">
                                <ChevronLeftIcon />
                            </IconButton>
                            <IconButton
                                disabled={!pagination.next}
                                onClick={() => {
                                    const old = paginChange.new;
                                    setPaginChange({
                                        old: old,
                                        new: old + 1
                                    })
                                }}
                                className={classes.iconBtn}
                                aria-label="nextPage">
                                <ChevronRightIcon />
                            </IconButton>
                        </div>
                        {/* <select
                            value={pagination.currentPage}
                            onChange={e => {
                                setPagination({ ...pagination, currentPage: Number(e.target.value) });
                            }}
                        >
                            {numArr(pagination.totalPages).map(pageNum => (
                                <option key={pageNum} value={pageNum}>
                                    {`Page ${pageNum}`}
                                </option>
                            ))}
                        </select> */}
                    </div>
                    <div className={classes.paginItem}>
                        <select
                            value={pagination.pageSize}
                            onChange={e => {
                                setPagination({ ...pagination, pageSize: Number(e.target.value) });
                                setPaginChange({
                                    old: 1,
                                    new: 1
                                });
                            }}
                        >
                            {[20, 50].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                </option>
                            ))}
                        </select>
                        <div style={{ marginLeft: 5 }}>per page</div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReactTable