export const MENU_MAP = {
  LOGIN: { key: 'login', route: '/login', label: 'Login' },
  DASHBOARD: { key: 'dashboard', route: '/', label: 'Dashboard' },
  CUSTOMERS: { key: 'customers', route: '/customers', label: 'Customers' },
  MANAGE_CUSTOMERS: { key: 'manage-customers', route: '/customers/manage-customers', label: 'Manage Customers' },
  PAYMENTS: { key: 'payments', route: '/payments', label: 'Payments' },
  PAYMENTS_DEPOSIT_HISTORY: { key: 'deposit-history', route: '/payments/deposit-history', label: 'Deposit History' },
  PAYMENTS_MANAGE_PAYMENTS: { key: 'manage-payments', route: '/payments/manage-payments', label: 'Manage Payments' },
  PAYMENTS_BALANCE_SHEET: { key: 'balance-sheet', route: '/payments/balance-sheet', label: 'Balance Sheet' },
  PAYMENTS_CUSTOMER_STATS: { key: 'customer-stats', route: '/payments/customer-stats', label: 'Customer Stats' },
  PAYMENTS_BONUS_CLIAMED: { key: 'bonus-claimed', route: '/payments/bonus-claimed', label: 'Bonus Claimed' },
  PAYMENTS_CARD_INFO: { key: 'card-info', route: '/payments/card-info', label: 'Card Info' },
  PAYMENTS_CHARGE_CUSTOMER: { key: 'charge-customer', route: '/payments', label: 'Charge To Customer' },
  SHIPMENTS: { key: 'shipments', route: '/shipments', label: 'Shipments' },
  SHIPMENTS_HISTORY: { key: 'shipment-history', route: '/shipment-history', label: 'History' },
  SHIPMENTS_PICKUP_SCHEDULES: { key: 'pickup-schedules', route: '/shipment/pickup-schedules', label: 'Pickup Schedules' },
  SHIPMENTS_VARIANTS: { key: 'variants', route: '/shipment/variants', label: 'Variants' },
  SHIPMENTS_UPLOAD_LABEL: { key: 'upload-label', route: '/shipment/upload-label', label: 'Upload Label' },
  SHIPMENTS_REFUND: { key: 'refunds', route: '/shipment/refunds', label: 'Refund Shipment' },
  SHIPMENTS_ADJUST_INOVICE: { key: 'adjust-invoice', route: '/shipment/adjust-invoice', label: 'Adjust Invoice' },
  CUSTOMER_SUPPORT: { key: 'customer-support', route: '/customer-support', label: 'Customer Support' },
  CUSTOMER_SUPPORT_MY_TICKETS: { key: 'my-tickets', route: '/customer-support/my-tickets', label: 'My Tickets' },
  CUSTOMER_SUPPORT_LEARNING_CENTER: { key: 'learning-center', route: '/customer-support/learning-center', label: 'Learning Center' },
};

export const SIDEBAR_MENU_MAP = {
  ALL: [
    MENU_MAP.DASHBOARD,
    {
      ...MENU_MAP.CUSTOMERS,
      children: [
        MENU_MAP.MANAGE_CUSTOMERS
      ]
    },
    {
      ...MENU_MAP.PAYMENTS,
      children: [
        MENU_MAP.PAYMENTS_DEPOSIT_HISTORY,
        MENU_MAP.PAYMENTS_MANAGE_PAYMENTS,
        MENU_MAP.PAYMENTS_BALANCE_SHEET,
        MENU_MAP.PAYMENTS_CUSTOMER_STATS,
        MENU_MAP.PAYMENTS_BONUS_CLIAMED,
        MENU_MAP.PAYMENTS_CARD_INFO,
        MENU_MAP.PAYMENTS_CHARGE_CUSTOMER,
      ]
    },
    {
      ...MENU_MAP.SHIPMENTS,
      children: [
        MENU_MAP.SHIPMENTS_HISTORY,
        MENU_MAP.SHIPMENTS_PICKUP_SCHEDULES,
        MENU_MAP.SHIPMENTS_VARIANTS,
        MENU_MAP.SHIPMENTS_UPLOAD_LABEL,
        MENU_MAP.SHIPMENTS_REFUND,
        MENU_MAP.SHIPMENTS_ADJUST_INOVICE,
      ]
    },
    {
      ...MENU_MAP.CUSTOMER_SUPPORT,
      children: [
        MENU_MAP.CUSTOMER_SUPPORT_MY_TICKETS,
        MENU_MAP.CUSTOMER_SUPPORT_LEARNING_CENTER,
      ],
    },
  ],
};
