import { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import { Notifications, RadioButtonChecked, MoreHoriz, Check, Markunread, NavigateNext, NavigateBefore } from '@material-ui/icons';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';

import { MENU_MAP } from './common';

import { useNotificationActions } from '../../redux/actions';

import { RootState } from '../../redux/reducers';

import { updateNotification } from '../../apis/notificationService';

const DATE_FORMAT = 'YYYY-MM-DD h:mm A';
const PAGE_SIZE = 8;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: 500,
    },
    rootMobile: {
      display: 'flex',
      flexDirection: 'column',
      width: 300,
    },
    loadingWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 100,
    },
    listWrapper: {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: 600,
      overflow: 'auto',
    },
    itemWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    unreadItemWrapper: {
      display: 'flex',
      alignItems: 'center',
      background: '#E6F7FF',
    },
    itemContentWrapper: {
      padding: '0px 16px',
    },
    itemContentTitle: {
      fontSize: '0.9rem',
      fontWeight: 'bold',
      marginBottom: '0.2rem',
    },
    itemContentMain: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '0.8rem',
    },
    itemContentSubtitle: {
      fontWeight: 'bold',
    },
    itemContentDate: {
      fontSize: '0.7rem',
      fontWeight: 'bold',
      marginTop: '0.2rem',
    },
    footer: {
      height: '0.4rem',
      background: '#3c8dbc',
    },
    iconWrapper: {
      display: 'flex',
      cursor: 'pointer',
      margin: '0px 0.5rem',
    },
    tooltipWrapper: {
      fontSize: 14,
    },
    moreItemWrapper: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '0.9rem',
    },
    markAllAsRead: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '8px 8px 0px 8px',
    },
    emptyNotifications: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '40px 0px',
    },
    paginationWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    paginationIcon: {
      cursor: 'pointer',
    },
    paginationInfo: {
      margin: '0px 1rem',
      fontSize: '0.9rem',
      fontWeight: 'bold',
    },
  }),
);

const doUpdateNotification = async (id = null, isReadArg = true) => {
  try {
    const result = await updateNotification(id, isReadArg);
    const status = result?.data?.status;

    return status;
  } catch (err) {
    console.log(err);

    return null;
  }
};

const NotificationItem = ({ id, title, subtitle = '', content, date, isRead, onClick, onReadStatusChange }) => {
  const classes = useStyles();

  const [moreOpen, setMoreOpen] = useState(false);

  const moreIconRef = useRef();

  const markAsReadHandler = async () => {
    setMoreOpen(false);

    const result = await doUpdateNotification(id);

    if (!result) {
      return;
    }

    onReadStatusChange(true);
  };

  const markAsUnreadHandler = async () => {
    setMoreOpen(false);

    const result = await doUpdateNotification(id, false);

    if (!result) {
      return;
    }

    onReadStatusChange(false);
  };

  return (
    <div className={!isRead ? classes.unreadItemWrapper : classes.itemWrapper}>
      <ListItem
        button
        className={classes.itemContentWrapper}
        onClick={() => {
          onClick();
          markAsReadHandler();
        }}
      >
        <ListItemText
          primary={(
            <>
              <div className={classes.itemContentTitle}>{title}</div>
              <div className={classes.itemContentMain}>
                <div>
                  {subtitle && (<span className={classes.itemContentSubtitle}>{subtitle}&nbsp;</span>)}
                  <span>{content}</span>
                </div>
                <div className={classes.itemContentDate}>{moment(date).format(DATE_FORMAT)}</div>
              </div>
            </>
          )}
        />
      </ListItem>
      <div className={classes.iconWrapper}>
        {
          !isRead && (
            <Tooltip title={<div className={classes.tooltipWrapper}>Mark as read</div>}>
              <RadioButtonChecked
                fontSize="small"
                color="secondary"
                onClick={markAsReadHandler}
              />
            </Tooltip>
          )
        }
        <MoreHoriz
          fontSize="small"
          color="primary"
          ref={moreIconRef}
          onClick={() => { setMoreOpen(true); }}
        />
        <Popover
          id="more"
          open={moreOpen}
          anchorEl={moreIconRef.current}
          onClose={() => { setMoreOpen(false); }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <List dense>
            {
              !isRead && (
                <ListItem
                  button
                  className={classes.itemWrapper}
                  onClick={markAsReadHandler}
                >
                  <ListItemText
                    primary={(
                      <div className={classes.moreItemWrapper}>
                        <Check color="primary" />
                        &nbsp;
                        <span>Mark as read</span>
                      </div>
                    )}
                  />
                </ListItem>
              )
            }
            {
              isRead && (
                <ListItem
                  button
                  className={classes.itemWrapper}
                  onClick={markAsUnreadHandler}
                >
                  <ListItemText
                    primary={(
                      <div className={classes.moreItemWrapper}>
                        <Markunread color="primary" />
                        &nbsp;
                        <span>Mark as unread</span>
                      </div>
                    )}
                  />
                </ListItem>
              )
            }
          </List>
        </Popover>
      </div>
    </div>
  );
};

const MasterPageNotification = ({ anchorEl, isMobileView, open, setOpen }) => {
  const { updateNotification, sortNotifications, markAllAsRead } = useNotificationActions();

  const classes = useStyles();
  const history = useHistory();

  const { notifications } = useSelector(
    (state: RootState) => state.notification
  );

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (!notifications || !notifications.length) {
      return;
    }

    setData(notifications);
  }, [notifications]);

  useEffect(() => {
    if (open || !data || !data.length) {
      return;
    }

    sortNotifications(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const ticketOnClickHandler = (id) => {
    history.push(`${MENU_MAP.CUSTOMER_SUPPORT_MY_TICKETS.route}/${id}?reload=true`);
    setOpen(false);
  };

  const getStartIndex = () => {
    return pageIndex * PAGE_SIZE + 1;
  }

  const getEndIndex = () => {
    let endIndex = (pageIndex * PAGE_SIZE) + PAGE_SIZE;

    if (endIndex > data.length) {
      endIndex = data.length;
    }

    return endIndex;
  }

  const onPrevHandler = () => {
    setPageIndex(pageIndex - 1);
  };

  const onNextHandler = () => {
    setPageIndex(pageIndex + 1);
  };

  const generatePaginationInfo = () => {
    return (
      <span>Row {getStartIndex()} - {getEndIndex()} of {data.length}</span>
    );
  };

  const isPrevDisabled = () => {
    return pageIndex === 0;
  };

  const isNextDisabled = () => {
    return getEndIndex() >= data.length;
  };

  const generateNotificationItems = () => {
    const items = [];

    for (let i = getStartIndex() - 1, ii = getEndIndex(); i < ii; i++) {
      const n = data[i];

      if (!n) {
        break;
      }

      const { id, title, description, isRead, createdAt, notificationType, referenceId } = n;

      if (i > 0) {
        items.push(
          <Divider key={`notification-item-divider-${i}`} />
        );
      }

      let titleString = '';
      let onClickHandler = () => { };

      switch (notificationType) {
        case 'TICKET':
          titleString = `${title} (${referenceId})`;
          onClickHandler = () => { ticketOnClickHandler(referenceId); };
          break;
        default:
          titleString = title;
          break;
      }

      items.push(
        <NotificationItem
          key={`notification-item-${i}`}
          id={id}
          title={titleString}
          subtitle=""
          content={description}
          date={createdAt}
          isRead={isRead}
          onClick={onClickHandler}
          onReadStatusChange={(e) => {
            let newData = data.find((ee) => ee.id === id);

            if (!newData) {
              return;
            }

            newData.isRead = e;

            updateNotification(id, newData);
          }}
        />
      );
    }

    return items;
  };

  const getUnreadNotificationsLength = () => {
    return data.filter((e) => !e.isRead).length;
  };

  const markAllAsReadHandler = async () => {
    const result = await doUpdateNotification();

    if (!result) {
      return;
    }

    markAllAsRead();
  };

  return (
    <>
      <Badge
        badgeContent={getUnreadNotificationsLength()}
        color="secondary"
        overlap='rectangular'
      >
        <Notifications />
      </Badge>
      <Popover
        id="notification"
        open={open}
        anchorEl={anchorEl}
        onClose={() => { setOpen(false); }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClick={(e) => { e.stopPropagation(); }}
      >
        <div className={isMobileView ? classes.rootMobile : classes.root}>
          {
            isLoading && (
              <div className={classes.loadingWrapper}>
                <CircularProgress />
              </div>
            )
          }
          {
            !isLoading && !data.length && (
              <div className={classes.emptyNotifications}>
                No Notifications
              </div>
            )
          }
          {
            !isLoading && data.length > 0 && (
              <div className={classes.listWrapper}>
                <div className={classes.markAllAsRead}>
                  <Link
                    className="link"
                    onClick={markAllAsReadHandler}
                  >
                    Mark all as read
                  </Link>
                </div>
                <List>
                  {generateNotificationItems()}
                </List>
                <div className={classes.paginationWrapper}>
                  <NavigateBefore
                    className={isPrevDisabled() ? '' : classes.paginationIcon}
                    color={isPrevDisabled() ? 'disabled' : 'primary'}
                    fontSize="large"
                    onClick={() => {
                      if (isPrevDisabled()) {
                        return;
                      }

                      onPrevHandler();
                    }}
                  />
                  <div className={classes.paginationInfo}>
                    {generatePaginationInfo()}
                  </div>
                  <NavigateNext
                    className={isNextDisabled() ? '' : classes.paginationIcon}
                    color={isNextDisabled() ? 'disabled' : 'primary'}
                    fontSize="large"
                    onClick={() => {
                      if (isNextDisabled()) {
                        return;
                      }

                      onNextHandler();
                    }}
                  />
                </div>
              </div>
            )
          }
          <div className={classes.footer}></div>
        </div>
      </Popover>
    </>
  );
};

export default MasterPageNotification;
