import Axios from "axios";
import { config, SERVER_URL } from "../environment/env_dev";
import { getFormattedParams } from "../utils/commonFunctions";

export const addDeposit = (cardId, amount) => {
  return Axios.post(
    `${SERVER_URL}/api/deposit/AddDeposit`,
    {
      amount: amount,
      cardInfo: {
        id: cardId,
      },
      clientCallBack: window.location.href,
    },
    config
  );
};

export const getDepositHistory = (parameters) => {
  const paramString = getFormattedParams(parameters);
  return Axios.get(
    `${SERVER_URL}/api/deposit/GetDepositHistory${paramString}`,
    config
  );
};

export const deleteDepositAdmin = (transactionId) => {
  return Axios.delete(
    `${SERVER_URL}/api/deposit/DeleteDepositAdmin?transactionId=${transactionId}`,
    {
      ...config,
    }
  );
};

export const addDepositAdmin = (body) => {
  return Axios.post(`${SERVER_URL}/api/deposit/AddDepositAdmin`, body, {
    ...config,
  });
};
