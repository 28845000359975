import { useEffect } from 'react';
import { useTable, useRowSelect } from 'react-table';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MaUTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Delete } from '@material-ui/icons';

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#E6F7FF',
    color: 'black',
    fontWeight: 'bold',
    border: '1px solid lightgray',
    padding: '10px',
    textAlign: 'center',
  },
  body: {
    padding: '10px',
  },
}))(TableCell);

const useStyles = makeStyles({
  table: {
    border: '1px solid lightgray',
    width: '100%',
  },
  placeholder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'calc(100% - 2px)',
    height: 100,
    border: '1px solid lightgray',
    borderTop: 'none',
  },
});

const ReactTable = ({
  columns,
  data,
  initialState,
  selectionType,
  showDeleteButton,
  onSelectedRowIdsChange,
  onDeleteRow,
  onCellClick,
  headerCellStyle,
  bodyCellStyle,
  placeholder,
  placeholderStyle,
}) => {
  const classes = useStyles();
  
  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    toggleAllRowsSelected,
    state: { selectedRowIds }
  } = useTable({
      columns,
      data,
      initialState,
    },
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => {
        let newColumns = [];

        switch (selectionType) {
          case 0:
          default:
            newColumns = columns;

            break;
          case 1:
            newColumns = [
              {
                id: 'selection',
                Header: ({ getToggleAllRowsSelectedProps }) => (
                  <div style={{ padding: '0' }}>
                    <input type="checkbox" { ...getToggleAllRowsSelectedProps() } indeterminate="false" />
                  </div>
                ),
                Cell: ({ row }) => (
                  <div style={{ textAlign: 'center' }}>
                    <input type="checkbox" { ...row.getToggleRowSelectedProps() } indeterminate="false" />
                  </div>
                ),
              },
              ...columns,
            ];

            break;
          case 2:
            newColumns = [
              {
                id: 'selection',
                Cell: ({ row }) => (
                  <div style={{ textAlign: 'center' }}>
                    <input
                      type="radio"
                      onClick={() => {
                        toggleAllRowsSelected(false);
                        row.toggleRowSelected();
                      }}
                      { ...row.getToggleRowSelectedProps() }
                      indeterminate="false"
                    />
                  </div>
                ),
              },
              ...columns,
            ];

            break;
        }

        if (showDeleteButton) {
          newColumns.push({
            Header: <div>Action</div>,
            Cell: ({ row }) => (
              <div
                style={{ textAlign: "center", cursor: "pointer" }}
                onClick={() => {
                  onDeleteRow(row.id);
                }}
              >
                <Delete color="error" />
              </div>
            ),
          });
        }

        return newColumns;
      });
    },
  );

  useEffect(() => {
    onSelectedRowIdsChange(selectedRowIds);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowIds]);

  return (
    <>
      <MaUTable
        className={classes.table}
        {...getTableProps()}
      >
        <TableHead>
          {
            headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {
                  headerGroup.headers.map(column => (
                    <StyledTableCell {...column.getHeaderProps()} style={headerCellStyle}>
                      {column.render('Header')}
                    </StyledTableCell>
                  ))
                }
              </TableRow>
            ))
          }
        </TableHead>
        <TableBody>
          {
            rows.map((row, i) => {
              prepareRow(row);

              return (
                <TableRow
                  {...row.getRowProps()}
                  style={row.original.rowStyle}
                >
                  {
                    row.cells.map(cell => {
                      return (
                        <StyledTableCell
                          {...cell.getCellProps()}
                          onClick={(e) => {  onCellClick(e, cell); }}
                          style={bodyCellStyle}
                        >
                          {cell.render('Cell')}
                        </StyledTableCell>
                      );
                    })
                  }
                </TableRow>
              )
            })
          }
        </TableBody>
      </MaUTable>
      {
        (!data || !data.length) && placeholder && (
          <div className={classes.placeholder} style={placeholderStyle}>
            {placeholder}
          </div>
        )
      }
    </>
  );
};

export const Table = ({
  columns,
  data,
  initialState = {},
  selectionType = 0,
  showDeleteButton = true,
  onSelectedRowIdsChange = (ids) => {},
  onDeleteRow = (id) => {},
  onCellClick = (e, cell) => {},
  headerCellStyle = {},
  bodyCellStyle = {},
  placeholder = null,
  placeholderStyle = {},
}) => {
  return (
    <ReactTable
      columns={columns}
      data={data}
      initialState={initialState}
      selectionType={selectionType}
      showDeleteButton={showDeleteButton}
      onSelectedRowIdsChange={onSelectedRowIdsChange}
      onDeleteRow={onDeleteRow}
      onCellClick={onCellClick}
      headerCellStyle={headerCellStyle}
      bodyCellStyle={bodyCellStyle}
      placeholder={placeholder}
      placeholderStyle={placeholderStyle}
    />
  );
};

export default Table;
