import { useMemo } from "react";

import { CustomerStat } from "../../@type/Customer";
import { Pagination, PaginChange } from "../../@type/Pagination";
import { useTableStyles } from "../manage-customers/ManageCustomers";
import ReactTable from "../react-table/ReactTable";
import GlobalSearch from "../utilities/GlobalSearch";
import { TableSelectFilterWrapper } from "../utilities/TableComponent";

interface CustomerStatsProps {
  customerStats: CustomerStat[];
  pagination: Pagination;
  setPagination: (value: Pagination) => void;
  isTableLoading: "Loading" | "Failed" | "Success" | "NoItem";
  setPaginChange: (value: PaginChange) => void;
  paginChange: PaginChange;
  sortType: "ASC" | "DESC" | null;
  sortColumn: string;
  setSortColumn: (value: string) => void;
  globalSearch: string;
  setGlobalSearch: (value: string) => void;
  orderBy: string;
  setOrderBy: (value: string) => void;
  setSortType: (value: "ASC" | "DESC" | null) => void;
  goToFirstPage: () => void;
}

const CustomerStatsTable = ({
  sortColumn,
  sortType,
  customerStats,
  pagination,
  paginChange,
  isTableLoading,
  goToFirstPage,
  setPagination,
  setSortType,
  setSortColumn,
  setPaginChange,
  globalSearch,
  setGlobalSearch,
  orderBy,
  setOrderBy,
}: CustomerStatsProps) => {
  const classes = useTableStyles();

  const data = useMemo(() => customerStats, [customerStats]);

  const columns = useMemo(
    () => [
      {
        Header: "Account No",
        accessor: "account_no",
        Cell: ({ row }) => row.original.account_no,
      },
      {
        Header: `Contact Name`,
        accessor: "name",
        Cell: ({ row }) => row.original.name,
      },
      {
        Header: `Phone`,
        accessor: "address.phone",
        Cell: ({ row }) => row.original.address.phone,
      },
      {
        Header: `Email`,
        accessor: "address.email",
        Cell: ({ row }) => row.original.email,
      },
      {
        Header: `Total Shipments`,
        accessor: "address.totalShipments",
        Cell: ({ row }) => row.original.totalShipments,
      },
      {
        Header: `Total Deposits`,
        accessor: "address.totalDeposits",
        Cell: ({ row }) => row.original.totalDeposits,
      },
      {
        Header: `Total Shipment Amount`,
        accessor: "address.totalShipmentAmount",
        Cell: ({ row }) => row.original.totalShipmentAmount,
      },
      {
        Header: `Balance`,
        accessor: "address.balance",
        Cell: ({ row }) => row.original.balance,
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    [sortType, sortColumn]
  );

  return (
    <>
      <div className={classes.root}>
        <h4 className={classes.title}>Customer Statistics</h4>
        <div className={classes.outer}>
          <div className={classes.actionsOuter}>
            <GlobalSearch
              globalSearch={globalSearch}
              setGlobalSearch={setGlobalSearch}
            />
            <TableSelectFilterWrapper
              btnName={orderBy}
              emptyBtnName="Order By"
              list={[
                "Deposit Amount",
                "Shipment Amount",
                "Balance",
                "Total Shipments",
              ]}
              setSelect={setOrderBy}
            />
            {/* <MyTicketsStatusSelection
                          setStatusArr={setStatusArr}
                          statusArr={statusArr}
                          setPaginChange={setPaginChange}
                      />
                      
                       */}
          </div>
          {/* <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => { setCreateNewTicketDialogOpen(true); }}
              >
                  Add New Ticket
              </Button> */}
        </div>
        <ReactTable
          isTableLoading={isTableLoading}
          pagination={pagination}
          setPagination={setPagination}
          columns={columns}
          data={data}
          setPaginChange={setPaginChange}
          paginChange={paginChange}
          errorMessage="Failed to retrieve customer stats."
        />
      </div>
    </>
  );
};

export default CustomerStatsTable;
