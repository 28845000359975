import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import DropdownButton from '../DropdownButton/DropdownButton';

import { useAlertActions } from '../../../redux/actions';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      margin: 10,
    },
    datesText: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginRight: 20,
    },
    dateText: {
      fontWeight: 700,
      fontSize: '0.8rem',
    },
    btn: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginRight: 20,
    },
  }),
);

interface DateFilterProps {
  startDate: Date;
  endDate: null | Date;
  orderDateFilter: string;
  setAnchorEl: (value: React.SetStateAction<HTMLButtonElement>) => void;
  setOrderDateFilter: (value: React.SetStateAction<string>) => void;
  onChange: (dates: [Date, Date]) => void;
  setStartDate: (value: React.SetStateAction<Date>) => void;
  setEndDate: (value: any) => void;
  goToFirstPage?: () => void;
};

const DateFilter = (props: DateFilterProps) => {
  const classes = useStyles();
  const { startDate, endDate, setStartDate, setEndDate, onChange, setOrderDateFilter, setAnchorEl, goToFirstPage } = props;

  const { openAlertBar, closeAlertBar } = useAlertActions();

  const formatTwoDigits = (digit: any) => ('0' + digit).slice(-2);

  const formatOrdersDate = (date: Date) => `${date.getFullYear()}/${formatTwoDigits(date.getMonth() + 1)}/${formatTwoDigits(date.getDate())}`;

  const applyBtnOnClick = () => {
    if (endDate) {
      if (formatOrdersDate(startDate) === formatOrdersDate(endDate)) {
        setOrderDateFilter(formatOrdersDate(startDate))
      } else {
        setOrderDateFilter(formatOrdersDate(startDate) + ' - ' + formatOrdersDate(endDate))
      }

      setAnchorEl(null);
    } else {
      openAlertBar('Please select the end date', false);

      setTimeout(() => {
        closeAlertBar();
      }, 2000);
    }
    if (goToFirstPage) {
      goToFirstPage();
    }
  };

  const cancelBtnOnClick = () => {
    setStartDate(new Date());
    setEndDate(null);
    setOrderDateFilter('');
    setAnchorEl(null);
    if (goToFirstPage) {
      goToFirstPage();
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.datesText}>
        <div className={classes.dateText}>From: {startDate ? formatOrdersDate(startDate) : 'Please select'}</div>
        <div className={classes.dateText}>To: {endDate ? formatOrdersDate(endDate) : 'Please select'}</div>
      </div>
      <DatePicker
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        inline
      />
      <div className={classes.btn}>
        {
          endDate && (
            <Button
              style={{ marginRight: 10 }}
              onClick={cancelBtnOnClick}
              size="small"
              variant="outlined"
              color="secondary"
            >
              Remove Filter
            </Button>
          )
        }
        <Button
          onClick={applyBtnOnClick}
          size="small"
          variant="contained"
          color="primary"
        >
          Apply
        </Button>
      </div>
    </div >
  );
};

interface TableDateFilterProps {
  dateFilter: string;
  setOrderDateFilter: (value: React.SetStateAction<string>) => void;
  goToFirstPage?: () => void;
};

const TableDateFilter = (props: TableDateFilterProps) => {
  const { dateFilter, setOrderDateFilter, goToFirstPage } = props;

  useEffect(() => {
    if (!dateFilter) {
      setStartDate(new Date());
      setEndDate(null);
    }
  }, [dateFilter]);

  const [anchorEl, setAnchorEl] = useState(null);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const onDateFilterChange = (dates: [Date, Date]) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);
  };

  return (
    <DropdownButton
      btnName=""
      btnNameCustomEl={(
        <div style={{ color: '#0B57A8', fontWeight: 'bold' }}>
          {dateFilter ? dateFilter : 'DATE'}
        </div>
      )}
      color="primary"
      variant={dateFilter ? 'outlined' : 'text'}
      anchorEl={anchorEl}
      size="medium"
      setAnchorEl={(e) => { setAnchorEl(e); }}
    >
      <DateFilter
        startDate={startDate}
        endDate={endDate}
        orderDateFilter={dateFilter}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setOrderDateFilter={setOrderDateFilter}
        onChange={onDateFilterChange}
        setAnchorEl={(e) => { setAnchorEl(e); }}
        goToFirstPage={goToFirstPage}
      />
    </DropdownButton>
  );
};

export default TableDateFilter;
