import { useDispatch } from 'react-redux';
import { SET_NOTIFICATIONS, ADD_NOTIFICATION, UPDATE_NOTIFICATION, MARK_ALL_AS_READ } from '../@types/notification/notificationAction';
import { getNotifications } from '../../apis/notificationService';

export function useNotificationActions() {
  const dispatch = useDispatch();

  const fetchNotifications = async () => {
    try {
      const result = await getNotifications();
      const data = result?.data?.data;

      if (!data) {
        return null;
      }

      sortNotifications(data);

      return data;
    } catch (err) {
      console.log(err);

      return null;
    }
  };

  const addNotification = (data) => {
    return dispatch({
      type: ADD_NOTIFICATION, payload: data,
    });
  };
  
  const updateNotification = (id, data) => {
    return dispatch({
      type: UPDATE_NOTIFICATION, payload: {
        id, data,
      },
    });
  };

  const sortNotifications = (notifications) => {
    const unreadNotifications = notifications.filter((e) => !e.isRead);
    const readNotifications = notifications.filter((e) => e.isRead);

    dispatch({
      type: SET_NOTIFICATIONS, payload: unreadNotifications.concat(readNotifications),
    });
  };

  const markAllAsRead = () => {
    return dispatch({
      type: MARK_ALL_AS_READ,
    });
  };

  return {
    fetchNotifications,
    addNotification,
    updateNotification,
    sortNotifications,
    markAllAsRead,
  };
}
