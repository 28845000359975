import { Button, Grid, Box } from "@material-ui/core";
import React from "react";


const styles = {

    body: {
        marginLeft: '5px',
    } as React.CSSProperties,

    buttonStyle: {

        width: '93%',
        margin: '20px 10px',
        height: '60px',
        textTransform: 'none',
        backgroundColor: 'white',
        border: 'solid',
        borderWidth: '1px',
        borderColor: 'lightgray',
        textAlign: 'left',
        color: 'black',
        cursor: 'pointer',

    } as React.CSSProperties,

    textStyle: {
        marginLeft: '10px',
        marginTop: '15px',
    } as React.CSSProperties,

    gridStyle: {
        width: '95%',
        margin: '10px',
        height: '60px',
    } as React.CSSProperties,

    firstGridStyle: {
        borderLeft: 'solid',
        borderColor: 'lightgray',
        borderLeftWidth: '1px',
        height: '408px',
    } as React.CSSProperties,

    leftPaneStyle: {
        marginLeft: '15px',
    } as React.CSSProperties,

    paragraphStyle: {
        marginTop: '-10px',
    } as React.CSSProperties,

    header: {
        marginLeft: '10px',
        marginBottom: '-10px',
        marginTop: '25px',
        fontSize: 'larger',
        fontWeight: 'bold',
    } as React.CSSProperties,

    secondaryHeader: {
        marginBottom: '-10px',
        marginTop: '25px',
        fontSize: 'larger',
        fontWeight: 'bold',
    } as React.CSSProperties,

    linkStyle: {
        textTransform: 'none',
        cursor: 'pointer',
        textDecoration: 'underline',

    } as React.CSSProperties,


};

const NewTicket = (props) => {
    const { updateLevel } = props;
    const { updateIssue } = props;

    const updateItems = (level: number, issue: number) => {
        updateLevel(level);
        updateIssue(issue);
    }
    // level: number, issue: number
    return (
        <div style={{ height: '400px', width: '99%' }}>
            <Grid container spacing={2} justify="center" style={{ height: '320px' }} >
                <Grid container item direction="column" spacing={2} xs={8}>

                    <div style={styles.body}>
                        <div style={styles.header}>What's the problem you have, Choose one option:</div>


                        <Box
                            boxShadow={"rgb(0 0 0 / 0%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px;"}
                            bgcolor="background.paper"
                            style={styles.buttonStyle}
                            onClick={() => updateItems(2, 1)}
                            id="test"
                        >
                            <div style={styles.textStyle}>
                                Cannot create a shipment
                            </div>

                        </Box>
                        <Box
                            boxShadow={"rgb(0 0 0 / 0%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px;"}
                            bgcolor="background.paper"
                            style={styles.buttonStyle}
                            onClick={() => updateItems(2, 2)}
                        >
                            <div style={styles.textStyle}>
                                Unable to add funds
                            </div>

                        </Box>
                        <Box

                            boxShadow={"rgb(0 0 0 / 0%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px;"}
                            bgcolor="background.paper"
                            style={styles.buttonStyle}
                            onClick={() => updateItems(2, 3)}
                        >
                            <div style={styles.textStyle}>
                                Cannot cancel a shipment
                            </div>

                        </Box>
                    </div>

                </Grid>
                <Grid container item direction="column" style={styles.firstGridStyle} spacing={2} xs={4}>
                    <div style={styles.leftPaneStyle}>
                        <div style={styles.secondaryHeader}>Your problem isn't in the list?</div>
                        <br></br>
                        <div style={styles.paragraphStyle}>Send in a plain description of what you're after and we will do the rest.</div>
                        <br></br>
                        <Button
                            variant="contained" color="primary"
                            onClick={() => updateItems(2, 4)}
                        >
                            New Ticket
                        </Button>
                    </div>

                </Grid>
            </Grid>
        </div >
    );
};

export default NewTicket;
