import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import React from "react";
import { useSelector } from "react-redux";
import { useAlertActions } from "../../redux/actions";
import { RootState } from "../../redux/reducers";

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AlertBar() {

    const { closeAlertBar } = useAlertActions();
    const { isAlertBarOpen, alertMsg, isSuccess } = useSelector(
        (state: RootState) => state.alert
    );

    const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        closeAlertBar();
    };

    return (
        <div>
            <Snackbar
                autoHideDuration={isSuccess ? 3000 : null}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={isAlertBarOpen}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={isSuccess ? "success" : "error"}>
                    {alertMsg}
                </Alert>
            </Snackbar>
        </div>
    );
}