// the library has bug when taking parameter to transform in array item
var objectMapper = require('object-mapper');

export const adminsMapper = (data: any) => {
    const adminsPre = objectMapper(data, map);
    const admins = [{ id: 0, name: "No Assignee" }, ...adminsPre];
    // console.log(admins);
    return admins;
};

const map = {
    "[].account_no": "[].id",
    "[].user_name": "[].name",
}