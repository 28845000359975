import { getAccessToken } from "../functionUtilities/getTokenAndExpireTime";

// console.log("=======================" + JSON.stringify(process))
// console.log("=======================" + JSON.stringify(process.env))
// console.log("=======================" + process.env.NODE_ENV)

export let SERVER_URL = process.env.REACT_APP_SERVER_URL;
export let CUSTOMER_DASHBOARD = process.env.REACT_APP_CUSTOMER_DASHBOARD_URL;

export const REDIRECT_URL = window.location.href.includes("?")
  ? window.location.href.split("?")[0]
  : window.location.href;

export const getStoreSetupURL = () => {
  const domainArr = window.location.href.split("/");
  return `${domainArr[0]}//${domainArr[2]}/settings/store-setup`;
};

export const tokenCookieName = "sv_tkn";

const getToken = () => {
  try {
    const token = getAccessToken(tokenCookieName);

    return token;
  } catch (err) {
    console.log(err);
  }

  return null;
};

export const config = { headers: { Authorization: `Bearer ${getToken()}` } };

export const APP_COLOR = {
  appLightBlue: "#3c8dbc",
  appHrGrey: "#e9ecef",
  deleteRed: "#D30101",
};

export const getIncomingShipmentsURL = () => {
  const domainArr = window.location.href.split("/");
  return `${domainArr[0]}//${domainArr[2]}/SW_WarehouseIncomingShipments/`;
};
