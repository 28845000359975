import RoundedCard from './RoundedCard';

const STYLE = {
  boxShadow: 'unset',
  borderRadius: '10px',
};

const HEADER_STYLE = {
  background: '#E6F7FF',
  borderTopRightRadius: '10px',
  borderTopLeftRadius: '10px',
  minHeight: '40px',
};

const TITLE_STYLE = {
  fontSize: '1rem',
};

const SmallCard = (props) => {
  const { children, style } = props;

  let customStyle = STYLE;
  
  if (style) {
    customStyle = { ...STYLE, ...style };
  }

  return (
    <RoundedCard
      style={customStyle}
      headerStyle={HEADER_STYLE}
      titleStyle={TITLE_STYLE}
      {...props}
    >
      {children}
    </RoundedCard>
  );
};

export default SmallCard;
