import { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Switch, Route, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

import MasterPageHeader from './MasterPageHeader';
import MasterPageMain from './MasterPageMain';
import LoginPage from './LoginPage';
import AlertBar from '../utilities/AlertBar';
import FixedDialog from '../utilities/FixedDialog';
import { useLoseFocus } from '../utilities/useLoseFocus';

import { MasterPageContext } from './masterPageContext';
import { MENU_MAP } from './common';

import * as tokenActions from '../../redux/actions/tokenActions';
import { useAlertActions, useUserActions, useSignalrActions, useNotificationActions } from '../../redux/actions';

import { isAccessTokenExpired } from '../../functionUtilities/checkAccessTokenExpiry';

import { tokenCookieName } from '../../environment/env_dev';

import '../../styles/scss/masterPage/masterPage.scss';

const DEFAULT_URL = `http://${window.location.host}/`;

const MasterPage = ({
  alert,
  user,
  addAccessTokenWatcher,
}) => {
  const history = useHistory();

  const { closeAlertBar } = useAlertActions();
  const { fetchUser } = useUserActions();
  const { fetchNotifications } = useNotificationActions();
  const { startSignalrConnection } = useSignalrActions();

  const [feedbackDialogVisible, setFeedbackDialogVisible] = useState(false);
  const [feedbackDialogTitle, setFeedbackDialogTitle] = useState('');

  const [selectedMenuKey, setSelectedMenuKey] = useState(MENU_MAP.DASHBOARD.key);

  const [tokenReady, setTokenReady] = useState(false);

  const [documentWidth, setDocumentWidth] = useState(0);

  const alertRef = useRef(null);

  const appVersion = "9.9.2";

  const resolveSelectedMenuKey = (route) => {
    const obj = Object.values(MENU_MAP).find((e) => e.route === route);

    if (!obj) {
      history.push(MENU_MAP.DASHBOARD.route);

      return;
    }

    if (route.startsWith(MENU_MAP.CUSTOMER_SUPPORT.route)) {
      if (obj.key === MENU_MAP.CUSTOMER_SUPPORT.key) {
        setSelectedMenuKey(MENU_MAP.CUSTOMER_SUPPORT_MY_TICKETS.key);

        return;
      }
    }

    setSelectedMenuKey(obj.key);
  };
  
  const masterPageContext = {
    appVersion,

    setFeedbackDialogVisible,
    setFeedbackDialogTitle,

    resolveSelectedMenuKey,
  };

  useEffect(() => {
    const init = async () => {
      if (isAccessTokenExpired()) {
        let currentRoute = window.location.pathname;

        const currentRouteIsValid = Object.values(MENU_MAP).find((e) => e.route === currentRoute);

        currentRoute = currentRoute === MENU_MAP.LOGIN.route || !currentRouteIsValid ? '' : currentRoute;

        const loginUrl = currentRoute ? `${MENU_MAP.LOGIN.route}?redirect=${currentRoute}` : MENU_MAP.LOGIN.route;

        history.push(loginUrl);

        setTokenReady(true);
        
        return;
      }

      const userResult = await fetchUser();
      await fetchNotifications();
      startSignalrConnection(userResult.account_no);
      
      addAccessTokenWatcher();

      setTokenReady(true);
    };

    init();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResize = () => {
    setDocumentWidth(window.innerWidth);
  };

  useEffect(() => {
    setTimeout(() => {
      handleResize();
    });

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useLoseFocus(alertRef, () => {
    closeAlertBar();
  });

  const onLogoClick = () => {
    history.push(MENU_MAP.DASHBOARD.route);
  }

  const onLogoutClick = () => {
    document.cookie = `${tokenCookieName}=`
    window.localStorage.setItem('accesstoken', '');
    window.localStorage.setItem('expireAt', '');

    window.location.href = DEFAULT_URL;
  };

  const isMobileView = () => {
    return documentWidth <= 720;
  };

  const isLoginPage = () => {
    return window.location.pathname === MENU_MAP.LOGIN.route;
  };

  if (!tokenReady) {
    return (
      <div></div>
    );
  }

  return (
    <MasterPageContext.Provider value={masterPageContext}>
      <div className={`master-page${isMobileView() ? ' mobile' : ''}`}>
          {
            !isLoginPage() && (
              <MasterPageHeader
                userProfileName={user.user.user_name}
                onLogoClick={onLogoClick}
                onLogoutClick={onLogoutClick}
                isMobileView={isMobileView()}
              />
            )
          }

          <Switch>
            <Route path={MENU_MAP.LOGIN.route}>
              <LoginPage />
            </Route>
            <Route path={MENU_MAP.DASHBOARD.route}>
              <MasterPageMain
                key={selectedMenuKey}
                selectedMenuKey={selectedMenuKey}
              />
            </Route>
          </Switch>
        
        <FixedDialog
          title={`Feedback for ${feedbackDialogTitle}`}
          maxWidth="sm"
          setOpen={(value) => {
            setFeedbackDialogVisible(value);
          }}
          dialogId="feedback-dialog"
          open={feedbackDialogVisible}
        >
          <div className="feedback-dialog">
            <div className="feedback-dialog-textarea">
              <textarea placeholder={`Write your feedback for ${feedbackDialogTitle} page...`} />
            </div>
            <div className="feedback-dialog-footer">
              <div className="feedback-dialog-footer-message">
                <span>This is for suggestions or feedback only.</span>
                <span>If you are in need of help, please contact <Link className="link">support</Link></span>
              </div>
              <Button variant="contained" color="primary">Send</Button>
            </div>
          </div>
        </FixedDialog>

        {
          alert.isAlertBarOpen && (
            <AlertBar />
          )
        }
      </div>
    </MasterPageContext.Provider>
  );
};

const mapStateToProps = (state) => {
  return {
    alert: state.alert,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addAccessTokenWatcher: bindActionCreators(tokenActions.addAccessTokenWatcher, dispatch),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(MasterPage);
