import {
  Paper, Table, TableBody,
  TableCell, TableContainer,
  TableHead, TableRow, Typography
} from '@material-ui/core'

import {Deposit} from '../../@type/Payments/Deposit'

type BalanceSheetTableProps = {
  title: string
  rows: Deposit[]
}

const BalanceSheetTable = ({title, rows}: BalanceSheetTableProps) => {
  return (
    <TableContainer component={Paper}>
      <Typography>{title}</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Account No</TableCell>
            <TableCell>{title}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {row.accountNo}
              </TableCell>
              <TableCell>
                {row.depositAmount}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default BalanceSheetTable