import Link from '@material-ui/core/Link';

const MasterPageFooter = ({
  version = "1.0.0",
  children,
}) => {
  return (
    <div className="master-page-footer">
      <div className="master-page-footer-item">
        © 2021&nbsp;
        <Link
          className="link"
          href="https://shipvista.com/"
          target="_blank"
        >
          ShipVista.com
        </Link>
      </div>
      {children}
      <div className="master-page-footer-item">
        Version {version}
      </div>
    </div>
  );
}

export default MasterPageFooter;
