import { useDispatch } from "react-redux";
import { AlertActions } from "../@types/alert/alertActions";

export function useAlertActions() {
    const dispatch = useDispatch();

    function openAlertBar(alertMsg: string, isSuccess: boolean) {
        return dispatch({ type: AlertActions.OPEN_ALERT_BAR, payload: alertMsg, payload2: isSuccess });
    }

    function closeAlertBar() {
        return dispatch({ type: AlertActions.CLOSE_ALERT_BAR });
    }

    return {
        openAlertBar,
        closeAlertBar
    }
}