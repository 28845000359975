 
import { Boxes } from "../../../@type/Boxes";

type LOAD_BOXES_DATA = "LOAD_BOXES_DATA";
type LOAD_VIEW_BOXES_DATA = "LOAD_VIEW_BOXES_DATA";
type SET_SELECTED_ROWS = "SET_SELECTED_ROWS";
type SET_BOXE_DETAILS_BOXE = "SET_BOXES_DETAILS_BOXE";
type SET_BOXES_TABLE_LOADING = "SET_BOXES_TABLE_LOADING"; 
type SET_BOXES_PAGE = "SET_BOXES_PAGE";
type SET_PAGE_INDEX = "SET_PAGE_INDEX";

type actionType = {
    LOAD_BOXES_DATA: LOAD_BOXES_DATA;
    LOAD_VIEW_BOXES_DATA: LOAD_VIEW_BOXES_DATA;
    SET_SELECTED_ROWS: SET_SELECTED_ROWS;
    SET_BOXE_DETAILS_BOXE: SET_BOXE_DETAILS_BOXE;
    SET_BOXES_TABLE_LOADING: SET_BOXES_TABLE_LOADING; 
    SET_BOXES_PAGE: SET_BOXES_PAGE;
    SET_PAGE_INDEX: SET_PAGE_INDEX;
};

export const BoxesActions: actionType = {
    LOAD_BOXES_DATA: "LOAD_BOXES_DATA",
    LOAD_VIEW_BOXES_DATA: "LOAD_VIEW_BOXES_DATA",
    SET_SELECTED_ROWS: "SET_SELECTED_ROWS",
    SET_BOXE_DETAILS_BOXE: "SET_BOXES_DETAILS_BOXE",
    SET_BOXES_TABLE_LOADING: "SET_BOXES_TABLE_LOADING", 
    SET_BOXES_PAGE: 'SET_BOXES_PAGE',
    SET_PAGE_INDEX: 'SET_PAGE_INDEX',
};

export type BoxesAction =
    | { type: LOAD_BOXES_DATA, payload: Boxes[] }
    | { type: LOAD_VIEW_BOXES_DATA, payload: Boxes[] }
    | { type: SET_SELECTED_ROWS, payload: Boxes[] }
    | { type: SET_BOXE_DETAILS_BOXE, payload: Boxes }
    | { type: SET_BOXES_TABLE_LOADING, payload: "Loading" | "Failed" | "Success" | "NoBox" }
    | { type: SET_BOXES_PAGE, payload: "awaitingShipment" | "shipped" | "canceled" }
    | { type: SET_PAGE_INDEX, payload: number }
