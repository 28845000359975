import { createStyles, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Deposit } from "../../@type/Payments/Deposit";
import BalanceSheetTable from "../balance-sheet-table/BalanceSheetTable";
import GlobalSearch from "../utilities/GlobalSearch";
import { TableDateFilterWrapper } from "../utilities/TableComponent";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minHeight: "calc(100vh - 131px)", // to fix the pagination
      position: "relative",
    },
    title: {
      margin: 10,
    },
    outer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      margin: 10,
    },
    actionsOuter: {
      display: "flex",
      flexDirection: "row",
    },
    tableSpacing: {
      margin: "0 10px",
    },
  })
);

const PaymentBalanceSheet = () => {
  const classes = useStyles();
  const [globalSearch, setGlobalSearch] = useState("");
  const [dateFilter, setDateFilter] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const [deposits] = useState<Deposit[]>([]);
  const [spent] = useState<Deposit[]>([]);

  const fetchDeposits = async () => {
    setLoading(true);
    console.log(loading);
    try {
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    fetchDeposits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <h4 className={classes.title}>Balance Sheet</h4>
      <div className={classes.outer}>
        <div className={classes.actionsOuter}>
          <GlobalSearch
            placeholder="Account No"
            globalSearch={globalSearch}
            setGlobalSearch={setGlobalSearch}
          />
          <TableDateFilterWrapper
            dateFilter={dateFilter}
            setOrderDateFilter={(value: string) => setDateFilter(value)}
          />
        </div>
        <div className="card-container">
          <div className="card-content green">
            <span>
              <b>Deposit: 306949.38 | Spend: 294834.92</b>
            </span>
          </div>
          <div style={{ width: 10 }}></div>
          <div className="card-content blue">
            <span>
              <b>Available Balance: $ 12114.46 CAD</b>
            </span>
          </div>
        </div>
      </div>
      <Grid container spacing={3}>
        <Grid item sm={6}>
          <div className={classes.tableSpacing}>
            <BalanceSheetTable rows={deposits} title="Deposit" />
          </div>
        </Grid>
        <Grid item sm={6}>
          <div className={classes.tableSpacing}>
            <BalanceSheetTable rows={spent} title="Spent" />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default PaymentBalanceSheet;
