import axios from "axios";
import Axios from "axios";
import { PaginChange } from "../@type/Pagination";
import { config, SERVER_URL } from "../environment/env_dev";

export const getAllTickets = (paginChange: PaginChange, prev: string, next: string, pageSize: number, statusArr: string[], searchTxt: string, dateFilter: string, sortVal: string, sortType: "ASC" | "DESC" | null) => {
    let params = `?Limit=${pageSize}`;

    if (paginChange.new < paginChange.old) {
        params += `&Prev=${prev}`
    };
    if (paginChange.new > paginChange.old) {
        params += `&Next=${next}`
    };
    if (searchTxt) {
        params += `&SearchText=${searchTxt}`
    }
    if (dateFilter) {
        let startDate = "";
        let endDate = "";
        if (dateFilter.includes("-")) {
            const arrDates = dateFilter.split(" - ");
            startDate = arrDates[0].replaceAll("/", "-");
            endDate = arrDates[1].replaceAll("/", "-");
        } else {
            startDate = endDate = dateFilter.replaceAll("/", "-");
        }
        params += `&MinCreatedDate=${startDate}&MaxCreatedDate=${endDate}`;
    }
    if (sortType) {
        params += `&SortType=${sortType}&SortColumn=${sortVal}`;
    }

    statusArr.forEach(status => {
        params += `&Status=${status}`
    });
    return Axios.get(`${SERVER_URL}/api/ticket/admin${params}`, config);
};

export const createNewTicket = (body) => {
    return Axios.post(`${SERVER_URL}/api/Ticket`, body, config);
};


export const createReplyToTicket = (id, body) => {
    return Axios.post(`${SERVER_URL}/api/Ticket/admin/${id}/response`, body, config);
};

export const getTicketById = (id) => {
    return Axios.get(`${SERVER_URL}/api/ticket/admin/${id}`, config);
};

// export const closeTicketById = (id: any) => {
//     return Axios.put(`${SERVER_URL}/api/Ticket/${id}`, config);
// };

export const closeTicketById = (id: any, status: string) => {

    return axios({
        method: "put",
        url: `${SERVER_URL}/api/Ticket/${id}?status=${status}`,
        headers: { 'Authorization': `${config.headers.Authorization}` },
    });

};

export const getResponsesTicketById = (id) => {
    return Axios.get(`${SERVER_URL}/api/Ticket/${id}/responses`, config);
};

export const uploadFileToTicket = (id, body: any) => {

    return axios({
        method: "post",
        url: `${SERVER_URL}/api/Ticket/${id}/attach`,
        data: body,
        headers: { "Content-Type": `multipart/form-data;`, 'Authorization': `${config.headers.Authorization}` },
    });

};

export const uploadFileToTicketResponse = (id, responseId, body: any) => {

    return axios({
        method: "post",
        url: `${SERVER_URL}/api/Ticket/${id}/attach/?responseId=${responseId}`,
        data: body,
        headers: { "Content-Type": `multipart/form-data;`, 'Authorization': `${config.headers.Authorization}` },
    });

};

export const getFileByFileName = (fileName) => {
    return Axios.get(`${SERVER_URL}/api/Ticket/get-file?fileName=${fileName}`, config);
};

export const changeAssignee = (ticketId: number, assigneeId: number) => {
    return Axios.put(`${SERVER_URL}/api/ticket/admin/${ticketId}?accountNo=${assigneeId}`, null, config);
}