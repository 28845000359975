import React from "react";

const styles = {

    body: {
        height: '400px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    } as React.CSSProperties,

};

const TicketCompletion = (props) => {

    // const { updateLevel } = props;
    // useEffect(() => {

    //     setTimeout(() => {
    //         updateLevel(1);
    //     }, 3500);
    // }, []);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div style={styles.body}>
            Your ticket is created successfully. Please check your ticket in the open tickets queue.
        </div>
    );
};

export default TicketCompletion;
