export type DialogState = {
  isDialogOpened: boolean;
  dialogPage: DialogOption;
};

export enum DialogOption {
  BlankPage = "BlankPage",
  ConnectCarrierAccountPage = "ConnectCarrierAccountPage",
  ConnectYourAccountPage = "ConnectYourAccountPage",
  UpdateYourAccountPage = "UpdateYourAccountPage",
  ConnectStorePage = "ConnectStorePage",
  StoreDetailFramePage = "StoreDetailFramePage",
  EditCurrentStorePage = "EditCurrentStorePage",
}