import { useState } from 'react'
import { IconButton } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

import DropdownWrapper from '../utilities/DropdownButton/DropdownWrapper';
import FixedDialogWrapper from '../utilities/FixedDialogWrapper';
import { updateCustomerAccount } from '../../apis/manageCustomersService';
import { useAlertActions } from '../../redux/actions';

const userStatusList = [
  { label: "Active", value: "1" },
  { label: "Inactive", value: "2" },
  { label: "Suspended", value: "3" }
]
const userLevelList = [
  { value: "N1", label: "General Public" },
  { value: "B2", label: "Bronze" },
  { value: "S3", label: "Silver" },
  { value: "G4", label: "Gold" },
  { value: "P5", label: "Platinum" },
  { value: "SV6", label: "0 Profit Commission" },
]

const UpdateCustomerDialog = ({
  refreshCustomers,
  accountNo,
  userAccountLevel,
  status
}) => {
  const [loading, setloading] = useState(false)
  const [accountStatus, setAccountStatus] = useState(`${status}`);
  const [userLevel, setUserLevel] = useState(userAccountLevel)
  const [updateDetailsDialog, setupdateDetailsDialog] = useState(false);

  const { openAlertBar } = useAlertActions();

  const onUpdateClick = async () => {
    setloading(true)

    try {
      const requestBody = {
        account_no: accountNo,
        status: accountStatus,
        user_level: userLevel
      }

      const { data } = await updateCustomerAccount(requestBody);

      if (data.status) {
        openAlertBar(data.message, true);
        refreshCustomers();

      } else {
        openAlertBar(data.message, false);
      }

    } catch (error) {
      openAlertBar("unexpected error occured: " + error, false);
    }
    setloading(false);
  }

  return (
    <>
      <IconButton size='small' color='primary' onClick={() => setupdateDetailsDialog(s => !s)}>
        <Edit />
      </IconButton>
      <FixedDialogWrapper
        dialogId="update-customer-details"
        title="Update Customer Status"
        maxWidth='sm'
        open={updateDetailsDialog}
        setOpen={setupdateDetailsDialog}
        buttonDefinitions={[
          {
            label: 'Cancel',
            onClick: () => setupdateDetailsDialog(false)
          },
          {
            label: 'Submit',
            color: 'primary',
            disabled: loading,
            onClick: onUpdateClick,
            isLoading: loading,
          }
        ]}
      >
        <div style={{ padding: "20px" }}>

          <div style={{ margin: '10px 0' }}>Account Status</div>
          <DropdownWrapper
            btnName={'Set Account Status'}
            btnNameCustomEl={(
              <div style={{ display: 'flex' }}>
                {userStatusList.find(ac => ac.value === accountStatus)?.label}
              </div>
            )}
            color="default"
            variant="outlined"
            size="medium"
            list={userStatusList.map(s => s.value)}
            listFormatter={(i) => userStatusList[i].label}
            setSelect={(value) => {
              setAccountStatus(value);
            }}
          />
          <div style={{ margin: '10px 0' }}>User Level</div>
          <DropdownWrapper
            btnName={'Set User Level'}
            btnNameCustomEl={(
              <div style={{ display: 'flex' }}>
                {userLevelList.find(us => us.value === userLevel)?.label}
              </div>
            )}
            color="default"
            variant="outlined"
            size="medium"
            list={userLevelList.map(us => us.value)}
            listFormatter={(i) => userLevelList[i].label}
            setSelect={(value) => {
              setUserLevel(value);
            }}
          />
        </div>
      </FixedDialogWrapper>
    </>
  )
}

export default UpdateCustomerDialog