import React from "react";
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { APP_COLOR } from '../../environment/env_dev';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
            backgroundColor: APP_COLOR.appLightBlue
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: "white",
        },
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    closeDisabled?: boolean;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, closeDisabled, ...other } = props;
    return (
        <MuiDialogTitle
            disableTypography
            className={classes.root}
            style={{ height: '55px' }}
            {...other}>
            <Typography style={{ color: "white", marginBottom: "10px" }} variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" style={{ color: "white", marginBottom: "10px" }} className={classes.closeButton} onClick={onClose} disabled={closeDisabled}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: 0,
    },
}))(MuiDialogContent);

interface Props {
    open: boolean;
    children: any;
    title: string;
    maxWidth: false | "xs" | "sm" | "md" | "lg" | "xl";
    setOpen: (value: boolean) => void;
    dialogId: string;
    closeDisabled?: boolean;
}

export default function TicketsDialog(props: Props) {
    const { open, setOpen, maxWidth, title, children, dialogId, closeDisabled } = props;

    const handleClose = () => {
        if (closeDisabled) {
            return;
        }

        setOpen(false);
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={maxWidth}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}>
            <DialogTitle
                id={dialogId}
                onClose={handleClose}
                closeDisabled={closeDisabled}
            >
                {title}
            </DialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
        </Dialog>
    );
}