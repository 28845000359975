const getCookie = (tokenCookieName, key) => {
    const cookie =
        document.cookie.split('; ')
            .find(row => row.startsWith(tokenCookieName));

    if (!cookie) {
        const sessionCookie = window.localStorage.getItem(key);

        return sessionCookie ? sessionCookie : '';
    }

    const obj =
        cookie
            .slice(tokenCookieName.length + 1).split("&")
            .find(row => row.startsWith(key));

    if (!obj) {
        const sessionCookie = window.localStorage.getItem(key);

        return sessionCookie ? sessionCookie : '';
    }

    return obj.split('=')[1];
};

export const getAccessToken = (tokenCookieName) => {
    try {
        return getCookie(tokenCookieName, 'accesstoken');
    } catch (err) {
        console.log(err);

        return '';
    }
};

export const getExpireAt = (tokenCookieName) => {
    try {
        return getCookie(tokenCookieName, 'expireAt');
    } catch (err) {
        console.log(err);

        return '';
    }
};