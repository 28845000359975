/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { ExpandMore, ChevronLeft } from '@material-ui/icons';

const Accordion = withStyles({
  root: {
    margin: '0px',
    boxShadow: 'none',
    borderBottom: '1px solid gray',
    '&$expanded': {
      margin: '0px',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#444',
    color: 'white',
    minHeight: 50,
    '&$expanded': {
      minHeight: 50,
      '&:hover': {
        backgroundColor: '#222',
      }
    },
    '&:hover': {
      backgroundColor: '#666',
    }
  },
  content: {
    '&$expanded': {
      margin: '0px',
    },
  },
  expanded: {
    backgroundColor: '#222',
  },
})(MuiAccordionSummary);

// data = [{ key, label, route, children }]
const MasterPageSidebar = ({
  data = [],
  selectedKey = '',
  onChange = (key, route) => {},
  style = {},
  id = '',
}) => {
  const [expandedKeyMap, setExpandedKeyMap] = useState({});
  const [ready, setReady] = useState(false);

  useEffect(() => {
    for (let i = 0, ii = data.length; i < ii; i++) {
      const d = data[i];

      if (!d.children?.length) {
        continue;
      }

      for (let j = 0, jj = d.children.length; j < jj; j++) {
        const c = d.children[j];

        if (selectedKey !== c.key) {
          continue;
        }

        setExpandedKeyMap({ [d.key]: true });
        break;
      }
    }

    setReady(true);
  }, []);

  const onMenuClick = (key, route) => {
    onChange(key, route);
  };

  const renderMenu = () => {
    let items = [];

    for (let i = 0, ii = data.length; i < ii; i++) {
      const d = data[i];
      const children = [];

      if (d.children?.length) {
        for (let j = 0, jj = d.children.length; j < jj; j++) {
          const c = d.children[j];

          children.push(
            <div
              key={`sidebar-menu-item-${i}-${j}`}
              className={`master-page-sidebar-menu-item child${selectedKey === c.key ? ' selected' : ''}`}
              onClick={() => onMenuClick(c.key, c.route)}
            >
              {c.label}
            </div>
          );
        }
      }

      if (children.length) {
        items.push(
          <Accordion
            key={`${id}-sidebar-menu-item-${i}`}
            expanded={expandedKeyMap[d.key] ? true : false}
            onChange={(e, value) => {
              setExpandedKeyMap({ [d.key]: value });
            }}
          >
            <AccordionSummary
              expandIcon={
                expandedKeyMap[d.key] ?
                  <ExpandMore style={{ color: 'white' }}/> :
                  <ChevronLeft style={{ color: 'white' }}/>
              }
            >
              {d.label}
            </AccordionSummary>
            {
              children
            }
          </Accordion>
        )

        continue;
      }

      items.push(
        <div
          key={`sidebar-menu-item-${i}`}
          className={`master-page-sidebar-menu-item${selectedKey === d.key ? ' selected' : ''}`}
          onClick={() => onMenuClick(d.key, d.route)}
        >
          <div>{d.label}</div>
        </div>
      );
    }

    return items;
  };

  return (
    <div className="master-page-sidebar" style={style}>
      <div className="master-page-sidebar-menu">
        {
          ready && renderMenu()
        }
      </div>
    </div>
  );
};

export default MasterPageSidebar;
