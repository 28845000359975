import { OrdersAction, OrdersState, OrdersActions } from "../@types/orders";

const initialState: OrdersState = {
    orders: [],
    viewOrders: [],
    selectedRows: [],
    orderDetailsOrder: null,
    isTableLoading: "Success",
    shipFromAddresses: [],
    ordersPage: null,
    pageIndexGlobal: 0,
};

export function ordersReducer(
    state = initialState,
    action: OrdersAction
): OrdersState {
    switch (action.type) {
        case OrdersActions.LOAD_ORDERS_DATA:
            return { ...state, orders: action.payload }
        case OrdersActions.LOAD_VIEW_ORDERS_DATA:
            return { ...state, viewOrders: action.payload }
        case OrdersActions.SET_SELECTED_ROWS:
            return { ...state, selectedRows: action.payload }
        case OrdersActions.SET_ORDER_DETAILS_ORDER:
            return { ...state, orderDetailsOrder: action.payload }
        case OrdersActions.SET_ORDERS_TABLE_LOADING:
            return { ...state, isTableLoading: action.payload }
        case OrdersActions.SET_SHIP_FROM_ADDRESSES:
            return { ...state, shipFromAddresses: action.payload }
        case OrdersActions.SET_ORDERS_PAGE:
            return { ...state, ordersPage: action.payload }
        case OrdersActions.SET_PAGE_INDEX:
            return { ...state, pageIndexGlobal: action.payload }
        default:
            return state;
    }
}