import React from "react";
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { APP_COLOR } from '../../environment/env_dev';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
            backgroundColor: APP_COLOR.appLightBlue
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: "white",
        },
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    closeDisabled?: boolean;
    onClose: () => void;
}


const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: 0,
    },
}))(MuiDialogContent);

interface Props {
    open: boolean;
    children: any;
    title: string;
    maxWidth: false | "xs" | "sm" | "md" | "lg" | "xl";
    setOpen: (value: boolean) => void;
    dialogId: string;
    closeDisabled?: boolean;
}

export default function ImageDialog(props: Props) {
    const { open, setOpen, maxWidth, children, closeDisabled } = props;

    const handleClose = () => {
        if (closeDisabled) {
            return;
        }

        setOpen(false);
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={maxWidth}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}>

            <DialogContent dividers>
                {children}
            </DialogContent>
        </Dialog>
    );
}