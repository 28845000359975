import { makeStyles, createStyles, Tooltip } from "@material-ui/core";

const useStyles = makeStyles(() =>
    createStyles({
        padding30: {
            padding: "30px !important",
        },
        linkButton: {
            color: "#069",
            cursor: "pointer",
            textDecoration: "underline",
            "&:hover": {
                color: "#0099E6",
            },
        },
        tableWrapper: {
            height: "calc(100% - 87px)",
        },
        cancelledStrike: {
            textDecoration: "line-through"
        }
    })
);

const TrackingNumberLink = ({ value, shippingCarrier, button = null }) => {
    const classes = useStyles();
    return (
        <Tooltip title="Tracking your parcel" placement="bottom">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                    }}
                    className={classes.linkButton}
                    onClick={() => {
                        let url = "";

                        switch (shippingCarrier) {
                            case "CanadaPost":
                            default:
                                url = `https://www.canadapost-postescanada.ca/track-reperage/en#/details/${value}`;
                                break;
                            case "UPS":
                                url = `https://wwwapps.ups.com/WebTracking/processRequest?HTMLVersion=5.0&Requester=NES&AgreeToTermsAndConditions=yes&loc=en_US&tracknum=${value}/trackdetails`;
                                break;
                            case "FEDEX":
                                url = `https://www.fedex.com/fedextrack/?tracknumbers=${value}`;
                                break;
                            case "CANPAR":
                                url = `https://www.canpar.com/en/tracking/track.htm?barcode=${value}`;
                                break;
                            case "USPS":
                                url = `https://tools.usps.com/go/TrackConfirmAction?tLabels=${value}`;
                                break;
                        }

                        window.open(url, "_blank");
                    }}
                >
                    {value}
                </span>
                {button}
            </div>
        </Tooltip>
    );
};

export default TrackingNumberLink;