import { makeStyles, createStyles, Link } from "@material-ui/core";
import moment from "moment";
import { useState, useEffect, useRef, useMemo, useCallback } from "react";

import { Customer } from "../../@type/Customer";
import { getUsersService } from "../../apis/manageCustomersService";
import useCustomerLogin from "../../hooks/useCustomerLogin";
import CustomerDetailsDialog from "../customer-details-dialog/CustomerDetailsDialog";
import UpdateCustomerDialog from "../update-customer-dialog/UpdateCustomerDialog";
import ReusableControlledTable from "../utilities/ReusableControlledTable";
import { TableSearchFilterWrapper } from "../utilities/TableComponent";
import useDebounce from "../utilities/useDebounce";

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minHeight: "calc(100vh - 131px)", // to fix the pagination
      position: "relative",
    },
    title: {
      margin: 10,
    },
  })
);

const Customers = () => {
  const [dateFilter] = useState("");
  const [sortVal, setSortVal] = useState(null);
  //   const [sortColumn] = useState<string>(null);
  const [sortType, setSortType] = useState<"ASC" | "DESC" | null>(null);
  const [searchFilter, setSearchFilter] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [itemsTotal, setItemsTotal] = useState(0);
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const [isTableLoading, setTableLoading] = useState<
    "Loading" | "Failed" | "Success" | "NoItem"
  >("Success");
  const [customers, setCustomers] = useState<Customer[]>([]);

  const { handleLoginClick } = useCustomerLogin();

  const isFirstRun = useRef(true);
  const searchFilterDebounce = useDebounce(searchFilter, 500);

  const getUserStatus = (status) => {
    switch (status) {
      case 1:
        return "ACTIVE";
      case 2:
        return "INACTIVE";
      case 3:
        return "SUSPENDED";
      default:
        return "ACTIVE";
    }
  };
  const fetchCustomers = useCallback(async () => {
    setTableLoading("Loading");

    try {
      const requestBody = {
        role: "Customer",
        pageIndex,
        pageSize,
        sortVal,
        sortType,
        searchText: searchFilterDebounce,
        dateFilter,
      };
      const { data } = await getUsersService(requestBody);

      if (!data?.records?.length) {
        setTableLoading("NoItem");

        return null;
      }

      if (!data?.records) {
        setCustomers([]);
        setItemsTotal(0);

        return;
      }

      setCustomers(data.records);
      setItemsTotal(data.total_records);
      setTableLoading("Success");
    } catch (error) {
      setTableLoading("Failed");
      console.log(error);
    }
  }, [
    dateFilter,
    pageIndex,
    pageSize,
    searchFilterDebounce,
    sortType,
    sortVal,
  ]);

  const columns = useMemo(
    () => [
      {
        Header: "Account No",
        accessor: "account_no",
        Cell: ({ row }) => (
          <Link onClick={() => handleLoginClick(row.original.account_no)}>
            {row.original.account_no}
          </Link>
        ),
      },
      {
        Header: `Contact Name`,
        accessor: "user_name",
        Cell: ({ row }) => row.original.user_name ?? "",
      },
      {
        Header: `Email Address`,
        accessor: "email",
        Cell: ({ row }) => row.original.email ?? "",
      },
      {
        Header: `Company`,
        accessor: "address.companyName",
        Cell: ({ row }) => row.original.address?.companyName ?? "",
      },
      {
        Header: `Postal Code`,
        accessor: "address.postalCode",
        Cell: ({ row }) => row.original.address?.postalCode ?? "",
      },
      {
        Header: `Phone`,
        accessor: "address.phone",
        Cell: ({ row }) => row.original.address?.phone ?? "",
      },
      {
        Header: "Created At",
        accessor: "signupDate",
        Cell: ({ row }) =>
          moment(row.original?.signupDate).format("YYYY/MM/DD h:mm a"),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => getUserStatus(row.original?.status),
      },
      {
        Header: "Email Confirmed",
        accessor: "isEmailConfimed",
        Cell: ({ row }) => row.original.isEmailConfirmed ?? "N",
      },
      {
        Header: "User Level",
        accessor: "user_level",
        Cell: ({ row }) => row.original.user_level ?? "",
      },
      {
        Header: "",
        accessor: "ipAddress",
        Cell: ({ row }) => (
          <>
            <UpdateCustomerDialog
              accountNo={row.original.account_no}
              userAccountLevel={row.original.user_level}
              status={row.original.status}
              refreshCustomers={fetchCustomers}
            />
            <CustomerDetailsDialog customer={row.original} />
          </>
        ),
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    [handleLoginClick, fetchCustomers]
  );

  useEffect(() => {
    fetchCustomers();
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, pageSize, searchFilterDebounce]);

  return (
    <div className="reusable-table-content">
      <div className="reusable-table-content-header">
        <h4>All Customers</h4>
      </div>
      <div className="reusable-table-main-content">
        <div style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "50px",
              whiteSpace: "nowrap",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <TableSearchFilterWrapper
                globalSearch={searchFilter}
                setGlobalSearch={(value) => setSearchFilter(value)}
              />
              {/* <TableSelectFilterWrapper
                  emptyBtnName="CARRIER ACCOUNT"
                  btnName={selectedCarrierAccount}
                  list={Shipment_CARRIER_ACCOUNT_MAP}
                  setSelect={(e) => {
                    setSelectedCarrierAccount(e);
                    getShipments();
                  }}
                />
                <TableSelectFilterWrapper
                  emptyBtnName="SHIPMENT TYPE"
                  btnName={selectedShipmentTypeDropdown}
                  list={Object.values(Shipment_TYPE_MAP)}
                  setSelect={(e) => {
                    setSelectedShipmentTypeDropdown(e);
                    getShipments();
                  }}
                /> */}
            </div>
          </div>
        </div>
        <div className="reusable-table-content-wrapper">
          <ReusableControlledTable
            data={customers}
            columns={columns}
            selectedRowIds={selectedRowIds}
            onSelectedRowIdsChange={setSelectedRowIds}
            isLoading={isTableLoading === "Loading"}
            placeholder={
              isTableLoading === "Failed" ? (
                "Failed to retrieve deposits."
              ) : (
                <span>There are no shipments yet.</span>
              )
            }
            placeholderStyle={
              isTableLoading === "Failed" ? { color: "red" } : {}
            }
            sortVal={sortVal}
            setSortVal={(e) => {
              setSortVal(e);
              fetchCustomers();
            }}
            sortType={sortType}
            setSortType={(e) => {
              setSortType(e);
              fetchCustomers();
            }}
            itemsTotal={itemsTotal}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            pageSizes={[50, 100]}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        </div>
      </div>
    </div>
  );
};

export default Customers;
