import Axios from "axios";
import { config, SERVER_URL } from "../environment/env_dev";
import { getFormattedParams } from "../utils/commonFunctions";

type ServiceParameters = {
  role: string;
  pageIndex: number;
  pageSize: number;
  sortVal?: string;
  sortType?: "ASC" | "DESC" | null;
  searchText?: string;
  dateFilter?: string;
};

export const getUsersService = (parameters: ServiceParameters) => {
  const paramString = getFormattedParams(parameters);

  return Axios.get(`${SERVER_URL}/api/User/GetUsers${paramString}`, config);
};

export const updateCustomerAccount = (body) => {
  return Axios.post(`${SERVER_URL}/api/User/UpdateUserAccount`, body, config);
};

export const loginToCustomerAccount = (accountNo: Number) => {
  return Axios.get(
    `${SERVER_URL}/api/Login/CustomerAccountLogin?accountNo=${accountNo}`,
    config
  );
};
