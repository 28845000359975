import ClearIcon from '@material-ui/icons/Clear';

import TableSearchFilter from './TableSearchFilter';
import TableDateFilter from './TableDateFilter';
import TableSelectFilter from './TableSelectFilter';

const TableWrapper = (props) => (<div style={{ width: '100%' }}>{props.children}</div>);
const TableHeaderWrapper = (props) => (<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '50px', whiteSpace: 'nowrap' }}>{props.children}</div>);
const TableFilterWrapper = (props) => (<div style={{ display: 'flex', alignItems: 'center', marginLeft: '-5px' }}>{props.children}</div>);
const TableDropdownFilterWrapper = (props) => (<div style={{ margin: '0px 5px' }}>{props.children}</div>);

const TableSearchFilterWrapper = (props) => {
  return (
    <TableDropdownFilterWrapper>
      <TableSearchFilter {...props} />
    </TableDropdownFilterWrapper>
  );
};

const TableDateFilterWrapper = (props) => {
  return (
    <TableDropdownFilterWrapper>
      <TableDateFilter {...props} />
    </TableDropdownFilterWrapper>
  );
};

const TableSelectFilterWrapper = (props) => {
  return (
    <TableDropdownFilterWrapper>
      <TableSelectFilter {...props} />
    </TableDropdownFilterWrapper>
  );
};

const ClearFilter = (props) => {
  const { onClick } = props;

  return (
    <div
      style={{ display: 'flex', color: '#f50057', cursor: 'pointer' }}
      onClick={onClick}
    >
      <ClearIcon />
      Clear the Filters
    </div>
  );
};

export { TableWrapper };
export { TableHeaderWrapper };
export { TableFilterWrapper };
export { TableDropdownFilterWrapper };
export { TableSearchFilterWrapper };
export { TableDateFilterWrapper };
export { TableSelectFilterWrapper };
export { ClearFilter };
