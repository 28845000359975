import axios from 'axios';

import { SET_TOKEN_STRING, SET_TOKEN_EXPIRE_AT, SET_TOKEN_ACCOUNT_NO } from '../@types/token/tokenAction';

export const setTokenString = (data) => ({
  type: SET_TOKEN_STRING, data,
});

export const setTokenExpireAt = (data) => ({
  type: SET_TOKEN_EXPIRE_AT, data,
});

export const setTokenAccountNo = (data) => ({
  type: SET_TOKEN_ACCOUNT_NO, data,
});

export const setAuthorizationToken = (tokenString) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${tokenString}`;
};

export const addAccessTokenWatcher = () => (dispatch) => {
  axios.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    (error) => {
      const response = error.response;
      const { status } = response;

      if (status === 401) {
        window.location.reload();

        return;
      }

      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    },
  );
};