import React, { useState } from "react";

import { CustomerStat } from "../../@type/Customer";
import { Pagination, PaginChange } from "../../@type/Pagination";
// import { useAlertActions } from "../../redux/actions";
import CustomerStatsTable from "../customer-stats-table/CustomerStatsTable";
import { useDebounceInternal } from "../masterPage/customerSupport/OpenTickets";

const CustomerStats = () => {
  const [isTableLoading] = useState<
    "Loading" | "Failed" | "Success" | "NoItem"
  >("Success");
  const [stats] = useState<CustomerStat[]>([]);
  const [orderBy, setOrderBy] = useState("");
  const [sortColumn, setSortColumn] = useState<string>(null);
  const [sortType, setSortType] = useState<"ASC" | "DESC" | null>(null);
  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [paginChange, setPaginChange] = useState<PaginChange>({
    old: 1,
    new: 1,
  });
  // const [currentPagination, setCurrentPagination] = useState<Pagination>({
  //   prev: null,
  //   next: null,
  //   pageSize: 20,
  //   itemsTotal: 0
  // });
  const [pagination, setPagination] = useState<Pagination>({
    prev: null,
    next: null,
    pageSize: 20,
    itemsTotal: 0,
  });

  useDebounceInternal(globalSearch, 500, setPaginChange);
  // const {openAlertBar} = useAlertActions();
  // const isFirstRun = useRef(true);

  const goToFirstPage = () => {
    setPaginChange({
      old: 1,
      new: 1,
    });
  };
  return (
    <CustomerStatsTable
      customerStats={stats}
      isTableLoading={isTableLoading}
      pagination={pagination}
      setPagination={setPagination}
      setPaginChange={setPaginChange}
      paginChange={paginChange}
      sortType={sortType}
      sortColumn={sortColumn}
      setSortType={setSortType}
      setSortColumn={setSortColumn}
      goToFirstPage={goToFirstPage}
      globalSearch={globalSearch}
      setGlobalSearch={setGlobalSearch}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
    />
  );
};

export default CustomerStats;
