import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useState } from 'react';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { PaginChange } from '../../../../@type/Pagination';

const useStyles = makeStyles(() =>
    createStyles({
        btn: {
            "& .MuiButton-label": {
                textTransform: "none"
            },
            marginRight: 10
        },
        list: {
            padding: 0,
            "& .MuiListItemIcon-root": {
                minWidth: "unset"
            }
        },
    }),
);

interface Props {
    setStatusArr: (value: string[]) => void;
    statusArr: string[];
    setPaginChange: (value: PaginChange) => void;
}

export default function MyTicketsStatusSelection({ setStatusArr, statusArr, setPaginChange }: Props) {
    const classes = useStyles();

    // popover
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    //---------------

    // list Check
    const [checked, setChecked] = useState(statusArr.length === 0
        ? ["All"]
        : statusArr.map(status => status === "OPEN"
            ? "Open"
            : status === "INPROGRESS"
                ? "In Progress"
                : "Closed"));
    const [checkedMsg, setCheckedMsg] = useState(statusArr.length === 0 ?
        "All"
        : getMessage(statusArr));

    const handleToggle = (value: string) => () => {
        const currentIndex = checked.indexOf(value);
        let newChecked = [...checked];
        // reset the page to first page
        setPaginChange({
            old: 1,
            new: 1
        })

        if (currentIndex === -1) {
            if (value === "All" || checked.length === 2) {
                newChecked = ["All"];
                setStatusArr([]);
            } else {
                const arr = newChecked.filter(value => value !== "All");
                newChecked = [...arr];
                newChecked.push(value);
                if (value === "Open") {
                    setStatusArr([...statusArr, "OPEN"]);
                } else if (value === "Closed") {
                    setStatusArr([...statusArr, "CLOSED"]);
                } else if (value === "In Progress") {
                    setStatusArr([...statusArr, "INPROGRESS"]);
                }
            }
        } else if (newChecked.length !== 1) {
            newChecked.splice(currentIndex, 1);
            if (value === "Open") {
                setStatusArr(statusArr.filter(v => v !== "OPEN"));
            } else if (value === "Closed") {
                setStatusArr(statusArr.filter(v => v !== "CLOSED"));
            } else if (value === "In Progress") {
                setStatusArr(statusArr.filter(v => v !== "INPROGRESS"));
            }
        }
        // set status msg
        let msg = "";
        newChecked.forEach((item, index) => {
            if (index === 0) {
                msg += item
            } else {
                msg += `/${item}`
            }
        });
        setCheckedMsg(msg);
        // console.log(newChecked);
        setChecked(newChecked);
    };
    //---------------

    return (
        <>
            <Button
                className={classes.btn}
                variant="outlined"
                color="primary"
                endIcon={<ExpandMoreIcon />}
                aria-describedby={id}
                onClick={handleClick}
            >
                {checkedMsg} Tickets
            </Button>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <List className={classes.list}>
                    {["All", "Open", "Closed", "In Progress"].map((value) => {
                        const labelId = `checkbox-list-label-${value}`;

                        return (
                            <ListItem key={value} role={undefined} dense button onClick={handleToggle(value)}>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        size="small"
                                        checked={checked.indexOf(value) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={value} />
                            </ListItem>
                        );
                    })}
                </List>
            </Popover>
        </>
    );
}

const getMessage = (statusArr: string[]) => {
    const arr = statusArr.length === 0
        ? ["All"]
        : statusArr.map(status => status === "OPEN"
            ? "Open"
            : status === "INPROGRESS"
                ? "In Progress"
                : "Closed");
    let msg = "";
    arr.forEach((item, index) => {
        if (index === 0) {
            msg += item
        } else {
            msg += `/${item}`
        }
    });
    return msg;
}
