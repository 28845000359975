import { CUSTOMER_DASHBOARD } from "./../environment/env_dev";
import { loginToCustomerAccount } from "./../apis/manageCustomersService";
import { useAlertActions } from "../redux/actions";

// Our hook
export default function useCustomerLogin() {
  const { openAlertBar } = useAlertActions();

  const handleLoginClick = async (accountNo) => {
    try {
      const { data } = await loginToCustomerAccount(accountNo);

      if (data.status) {
        const url = `${CUSTOMER_DASHBOARD}/login-success?accessToken=${data.access_token.tokenString}&expireAt=${data.access_token.expireAt}`;

        window.open(url, "_blank").focus();
        openAlertBar(data.message, true);
      } else {
        openAlertBar(data.message, false);
      }
    } catch (error) {
      openAlertBar("unexpected error occured: " + error, false);
    }
  };

  return { handleLoginClick };
}
