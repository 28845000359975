import { AvailableRate } from "../@type/AvailableRate";


export const compareByTransitDay = (a: AvailableRate, b: AvailableRate) => {
    if (a.transitDay === b.transitDay) {
        // price is only important when transitDay are the same
        return a.price.totalCharge - b.price.totalCharge;
    }
    return parseInt(a.transitDay) > parseInt(b.transitDay) ? 1 : -1;
}

export const compareByPrice = (a: AvailableRate, b: AvailableRate) => {
    if (a.price.totalCharge === b.price.totalCharge) {
        // transitDay is only important when prices are the same
        return parseInt(a.transitDay) - parseInt(b.transitDay);
    }
    return a.price.totalCharge > b.price.totalCharge ? 1 : -1;
}

export const getTop3Rates = (rates: AvailableRate[]) => {
    const top3Rates: AvailableRate[] = [];
    const ratesPre1 = [...rates];
    const ratesPre2 = [...rates];
    const ratesPre3 = [...rates];

    // sort each time will sort the rates arr itself and return the sorted arr
    top3Rates.push(ratesPre1.sort(compareByTransitDay)[0]);
    top3Rates.push(ratesPre2.find(rate => rate.service.serviceName.toLowerCase().includes("expedited"))
        ? ratesPre2.sort(compareByPrice).find(rate => rate.service.serviceName.toLowerCase().includes("expedited"))
        : ratesPre2.sort(compareByPrice)[0]);
    top3Rates.push(ratesPre3.sort(compareByPrice)[0]);
    // console.log(ratesPre1);
    // console.log(ratesPre2);
    // console.log(ratesPre3);

    return top3Rates;
}
