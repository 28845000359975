// the library has bug when taking parameter to transform in array item
var objectMapper = require('object-mapper');

export const paginationMapper = (data: any) => objectMapper(data, map);

const map = {
    "cursors.prev": "prev",
    "cursors.next": "next",
    "limit": "pageSize",
    "total_records":"itemsTotal"
}