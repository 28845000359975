import { useDispatch } from 'react-redux';
import { SET_USER } from '../@types/user/userAction';
import { getUserService } from '../../apis/userService';

export const setUser = (data) => ({
  type: SET_USER, data,
});

export function useUserActions() {
  const dispatch = useDispatch();

  const fetchUser = async () => {
    try {
      const result = await getUserService();

      if (!result.data) {
        return null;
      }

      dispatch(setUser(result.data));

      return result.data;
    } catch (err) {
      console.log(err);

      return null;
    }
  };

  return { fetchUser: fetchUser };
}
