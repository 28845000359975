import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr';
import { useNotificationActions } from './notificationActions';
import { SERVER_URL } from '../../environment/env_dev';

export const useSignalrActions = () => {
  const { addNotification } = useNotificationActions();

  const startSignalrConnection = (userId) => {
    const connection = new HubConnectionBuilder()
      .withUrl(`${SERVER_URL}/jobprogress`)
      .configureLogging(LogLevel.Information)
      .build();
    connection.serverTimeoutInMilliseconds = 1000000000;

    connection.on('NotifyTicketUpdate', (notificationObj) => {
      console.log('NotifyTicketUpdate', { notificationObj });

      addNotification(notificationObj);
    });

    connection.start()
      .then(() => {
        connection.invoke('SendTest', 'testYizhi');
        connection.invoke('AddToGroup', userId.toString());
      })
      .catch((err) => { console.error(err.toString()); });
  };
  
  return {
    startSignalrConnection,
  };
};
