import React from "react";
import { FixedSizeList } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";

import { createStyles, makeStyles } from '@material-ui/core/styles';
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem, { ListItemProps } from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import ListItemIcon from "@material-ui/core/ListItemIcon";

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            width: '100%',
        },
        removeFilter: {
            paddingLeft: 10,
            "& .MuiListItemIcon-root": {
                minWidth: 25
            },
            "& .MuiTypography-root": {
                color: "#f50057"
            },
        },
        icon: {
            color: "#f50057"
        },
        divider: {
            height: 1,
            backgroundColor: "#DDDDDD",
            border: "none",
        },
    }),
);

interface Props {
    list: any[];
    filterName?: string;
    selectName?: string;
    style?: object;
    listFormatter?: (i) => {},
    setAnchorEl: (value: React.SetStateAction<HTMLButtonElement>) => void;
    setSelect: (value: React.SetStateAction<any>) => void;
    disableAutoHide?: boolean;
    lazyLoad?: boolean;
    lazyLoadContainerStyle?: object;
    listItemProps?: (listItemValue: any) => Pick<ListItemProps, 'disabled'> | {};
}

export default function DropdownBtnPopoverList(props: Props) {
    const classes = useStyles();
    const {
        setAnchorEl, setSelect, disableAutoHide, lazyLoad, selectName,
        filterName, list, listFormatter, style, lazyLoadContainerStyle,
        listItemProps,
    } = props;

    const _lazyLoadContainerStyle = lazyLoadContainerStyle ? lazyLoadContainerStyle : { width: '300px', height: '300px' };

    const handleClick = (select: any) => {
        if (!disableAutoHide) {
            setAnchorEl(null);
        }

        setTimeout(() => {
            setSelect(select);
        }, 300);
    };

    const removeFilter = () => {
        setSelect(filterName);
        setAnchorEl(null);
    };

    const Item = ({ index, style }) => {
        const select = list[index];
        const i = index;

        return (
            <ListItem
                {...(typeof (listItemProps) === 'function' ? listItemProps(select) : {})}
                key={i}
                style={style}
                selected={select === selectName}
                onClick={() => handleClick(select)}
                button
            >
                {
                    listFormatter && (
                        listFormatter(i)
                    )
                }
                {
                    !listFormatter && (
                        <ListItemText primary={select} />
                    )
                }
            </ListItem>
        );
    };

    return (
        <div className={classes.root} style={{ ...style, maxHeight: lazyLoad ? 'unset' : '300px' }}>
            <List dense={true} aria-label="dropdownList" style={style}>
                {filterName !== selectName
                    ? <>
                        <ListItem onClick={removeFilter} className={classes.removeFilter} button>
                            <ListItemIcon>
                                <NotInterestedIcon className={classes.icon} fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={"Remove Filter"} />
                        </ListItem>
                        <Divider className={classes.divider} />
                    </>
                    : ""
                }
                {
                    lazyLoad && (
                        <div style={_lazyLoadContainerStyle}>
                            <AutoSizer>
                                {({ height, width }) => (
                                    <FixedSizeList
                                        height={height}
                                        width={width}
                                        itemCount={list.length}
                                        itemSize={30}
                                    >
                                        {Item}
                                    </FixedSizeList>
                                )}
                            </AutoSizer>
                        </div>
                    )
                }
                {
                    !lazyLoad && (
                        props.list.map((select, i) => <Item key={`dropdown-item-${i}`} index={i} style={null} />)
                    )
                }
            </List>
        </div>
    );
}