import { createStyles, makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            cursor: "pointer",
            '&:hover': {
                color: "#0099E6",
                textDecoration: "underline",
            },
            display: "flex",
            flexDirection: "row"
        }
    }),
);

interface Props {
    property: string;
    header: string;
    sortType: "ASC" | "DESC" | null;
    sortVal: string;
    sortClick: (property: string) => void,
}

export default function TableSortingHeader({ sortVal, sortType, sortClick, header, property }: Props) {
    const classes = useStyles();

    return (
        <div
            className={classes.root}
            onClick={() => sortClick(property)}
        >
            {header}
            {property === sortVal
                ? sortType === "DESC"
                    ? <ArrowDropDownIcon fontSize="small" />
                    : sortType === "ASC"
                        ? <ArrowDropUpIcon fontSize="small" />
                        : <></>
                : <></>
            }
        </div>
    );
}