import { SET_SELECTED_ROWS, SET_TABLE_LOADING, SET_PAGE_INDEX } from '../@types/transactionHistory/transactionHistoryAction';

const initialState = {
  selectedRows: [],
  isTableLoading: 'Success', // 'Success', 'Loading', 'Failed', 'NoTransaction'
  pageIndexGlobal: 0,
};

export function transactionHistoryReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SELECTED_ROWS:
      return { ...state, selectedRows: action.payload }
    case SET_TABLE_LOADING:
      return { ...state, isTableLoading: action.payload }
    case SET_PAGE_INDEX:
      return { ...state, pageIndexGlobal: action.payload }
    default:
      return state;
  }
}
