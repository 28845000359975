import { useState, useEffect, createContext, useContext } from "react";
import { Carousel } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Link from "@material-ui/core/Link";
import { Person, PersonAdd, ArrowBack } from "@material-ui/icons";

import { useAlertActions } from "../../redux/actions";

import { loginService, registerService } from "../../apis/loginService";

import { isAccessTokenExpired } from "../../functionUtilities/checkAccessTokenExpiry";

import { tokenCookieName, SERVER_URL } from "../../environment/env_dev";

import "bootstrap/dist/css/bootstrap.min.css";
import "./LoginPage.scss";

const LoginPageContext = createContext(null);

const DEFAULT_URL = `http://${window.location.host}/`;

const CAROUSEL_DATA = [
  {
    imgUrl: "/images/login/bg1.jpg",
    title: "Shipment instantly",
    content:
      "Access to a free on-line system used to process shipments instantly.",
  },
  {
    imgUrl: "/images/login/bg2.jpg",
    title: "LTL shipments",
    content:
      "Schedule LTL shipments from anywhere to anywhere in North America including Trans-Border.",
  },
  {
    imgUrl: "/images/login/bg3.jpg",
    title: "Real time",
    content:
      "Real time, user friendly access to very competitive LTL and courier rates.",
  },
  {
    imgUrl: "/images/login/bg1.jpg",
    title: "Shipping label",
    content: "Generates carrier approved BOL and AWB shipping labels.",
  },
  {
    imgUrl: "/images/login/bg2.jpg",
    title: "Tracking",
    content:
      "Shipment tracking, claims processing and statement of account with invoice details.",
  },
  {
    imgUrl: "/images/login/bg3.jpg",
    title: "Save time and money",
    content:
      "Saves your business time and money. Fast and efficient process. Reduce transportation cost and increase shipping productivity.",
  },
];

const useStyles = makeStyles(() =>
  createStyles({
    formTitle: {
      fontWeight: "bold",
    },
    textField: {
      width: "100%",
      margin: "0.5rem 0px",
    },
    checkboxField: {
      marginTop: "0.5rem",
      marginBottom: "0.5rem",
    },
    rememberMeWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    backToLoginWrapper: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
      cursor: "pointer",
    },
    circularProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: "-12px",
      marginLeft: "-12px",
    },
  })
);

const FormWrapper = ({
  children,
  title,
  buttonLabel,
  buttonIsLoading,
  buttonOnClick,
}) => {
  const classes = useStyles();

  return (
    <div className="form-wrapper">
      <div className="form-title">
        <Typography className={classes.formTitle} variant="h4">
          {title}
        </Typography>
      </div>
      <div className="form">{children}</div>
      <div className="form-button">
        <div className="button-wrapper">
          <Button
            variant="contained"
            color="primary"
            disabled={buttonIsLoading}
            onClick={buttonOnClick}
            fullWidth
          >
            {buttonIsLoading && <span>&nbsp;</span>}
            {!buttonIsLoading && <span>{buttonLabel}</span>}
          </Button>
          {buttonIsLoading && (
            <CircularProgress className={classes.circularProgress} size={24} />
          )}
        </div>
      </div>
    </div>
  );
};

const LoginForm = () => {
  const classes = useStyles();

  const {
    openAlertBar,
    redirectRoute,
    loginUsername,
    setLoginUsername,
    loginPassword,
    setLoginPassword,
    rememberMe,
    setRememberMe,
    setShowForgotPasswordPage,
    loading,
    setLoading,
  } = useContext(LoginPageContext);

  const loginHandler = async () => {
    try {
      setLoading(true);

      const result = await loginService({
        user_id: loginUsername,
        password: loginPassword,
      });

      if (result.data.access_token) {
        const { tokenString, expireAt } = result.data.access_token;

        if (!rememberMe) {
          window.localStorage.setItem("accesstoken", tokenString);
          window.localStorage.setItem("expireAt", expireAt);
        } else {
          document.cookie = `${tokenCookieName}=accesstoken=${tokenString}&expireAt=${expireAt}`;
        }

        window.location.href = redirectRoute;
      }

      if (result.data && !result.data.status) {
        openAlertBar(result.data.message, false);
      }
    } catch (err) {
      const errorMessage = err?.response?.data?.title;

      openAlertBar(errorMessage ?? "Something happened", false);
    }

    setLoading(false);
  };

  return (
    <FormWrapper
      title="LOGIN"
      buttonLabel="Login"
      buttonIsLoading={loading}
      buttonOnClick={loginHandler}
    >
      <TextField
        className={classes.textField}
        id="login-email"
        name="email"
        label="Email Address"
        value={loginUsername}
        onChange={(e) => setLoginUsername(e.target.value)}
      />
      <TextField
        className={classes.textField}
        id="login-Password"
        label="Password"
        value={loginPassword}
        type="password"
        onChange={(e) => {
          setLoginPassword(e.target.value);
        }}
        inputProps={{
          autoComplete: "new-password",
          form: {
            autocomplete: "off",
          },
        }}
      />
      <div className={classes.rememberMeWrapper}>
        <FormControlLabel
          className={classes.checkboxField}
          control={
            <Checkbox
              checked={rememberMe}
              onChange={(e) => {
                setRememberMe(e.target.checked);
              }}
              name="login-remember-me"
              color="primary"
            />
          }
          label="Remember this device"
        />
        <Link
          className="link"
          onClick={() => {
            setShowForgotPasswordPage(true);
          }}
        >
          Forgot Password?
        </Link>
      </div>
    </FormWrapper>
  );
};

const RegisterForm = () => {
  const classes = useStyles();

  const {
    openAlertBar,
    setSelectedTab,
    setLoginUsername,
    registerUsername,
    setRegisterUsername,
    registerPassword,
    setRegisterPassword,
    email,
    setEmail,
    phone,
    setPhone,
    loading,
    setLoading,
  } = useContext(LoginPageContext);

  const registerHandler = async () => {
    try {
      setLoading(true);

      const result = await registerService({
        contact_name: registerUsername,
        password: registerPassword,
        email,
        phone,
        VerificationLink: `${SERVER_URL}/api/register/verifyemailaddress`,
      });

      if (!result.data.status) {
        openAlertBar(result.data.message, false);
      }

      if (result.data.status) {
        openAlertBar(result.data.message, true);

        setRegisterUsername("");
        setRegisterPassword("");
        setEmail("");
        setPhone("");

        setLoginUsername(registerUsername);
        setSelectedTab(0);
      }
    } catch (err) {
      const errors = err?.response?.data?.errors;

      openAlertBar(JSON.stringify(errors) ?? "Something happened", false);
    }

    setLoading(false);
  };

  return (
    <FormWrapper
      title="REGISTER"
      buttonLabel="Submit"
      buttonIsLoading={loading}
      buttonOnClick={registerHandler}
    >
      <TextField
        className={classes.textField}
        id="register-username"
        label="Username"
        value={registerUsername}
        onChange={(e) => {
          setRegisterUsername(e.target.value);
        }}
      />
      <TextField
        className={classes.textField}
        id="register-password"
        label="Password"
        value={registerPassword}
        type="password"
        onChange={(e) => {
          setRegisterPassword(e.target.value);
        }}
        inputProps={{
          autoComplete: "new-password",
          form: {
            autocomplete: "off",
          },
        }}
      />
      <TextField
        className={classes.textField}
        id="register-email"
        label="Email"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />
      <TextField
        className={classes.textField}
        id="register-phone"
        label="Phone"
        value={phone}
        onChange={(e) => {
          setPhone(e.target.value);
        }}
      />
    </FormWrapper>
  );
};

const ForgotPasswordForm = () => {
  const classes = useStyles();

  const { openAlertBar, resetEmail, setResetEmail, loading, setLoading } =
    useContext(LoginPageContext);

  const resetHandler = async () => {
    try {
      setLoading(true);
    } catch (err) {
      const errorMessage = err?.response?.data?.title;

      openAlertBar(errorMessage ?? "Something happened", false);
    }

    setLoading(false);
  };

  return (
    <FormWrapper
      title="FORGOT PASSWORD"
      buttonLabel="Send Password Reset Link"
      buttonIsLoading={loading}
      buttonOnClick={resetHandler}
    >
      <TextField
        className={classes.textField}
        id="forgot-email"
        label="Email"
        value={resetEmail}
        onChange={(e) => {
          setResetEmail(e.target.value);
        }}
      />
    </FormWrapper>
  );
};

const LoginPage = (props) => {
  const classes = useStyles();

  const { openAlertBar } = useAlertActions();

  const [redirectRoute, setRedirectRoute] = useState(DEFAULT_URL);
  const [selectedTab, setSelectedTab] = useState(0);

  const [loginUsername, setLoginUsername] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const [registerUsername, setRegisterUsername] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [showForgotPasswordPage, setShowForgotPasswordPage] = useState(false);
  const [resetEmail, setResetEmail] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const qs = queryString.parse(props.location.search);
    const redirect = qs.redirect?.toString() ?? DEFAULT_URL;

    if (!isAccessTokenExpired()) {
      window.location.href = redirect;

      return;
    }

    setRedirectRoute(redirect.toString());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabChange = (e, value) => {
    setSelectedTab(value);
  };

  const generateCarousel = () => {
    const items = [];

    for (let i = 0, ii = CAROUSEL_DATA.length; i < ii; i++) {
      const d = CAROUSEL_DATA[i];
      const { imgUrl, title, content } = d;

      items.push(
        <Carousel.Item key={`carousel-${i}`}>
          <img src={imgUrl} alt={`Slide ${i + 1}`} />
          <Carousel.Caption>
            <h1>{title}</h1>
            <p>{content}</p>
          </Carousel.Caption>
        </Carousel.Item>
      );
    }

    return <Carousel fade>{items}</Carousel>;
  };

  const context = {
    openAlertBar,
    redirectRoute,
    setSelectedTab,

    loginUsername,
    setLoginUsername,
    loginPassword,
    setLoginPassword,
    rememberMe,
    setRememberMe,

    registerUsername,
    setRegisterUsername,
    registerPassword,
    setRegisterPassword,
    email,
    setEmail,
    phone,
    setPhone,

    setShowForgotPasswordPage,
    resetEmail,
    setResetEmail,

    loading,
    setLoading,
  };

  return (
    <LoginPageContext.Provider value={context}>
      <div className="login-page-root">
        <div className="logo">
          <img src="/shipvista.png" alt="shipvista logo" />
        </div>
        <div className="login-page-card-wrapper">
          <div className="card left">
            {generateCarousel()}
            <div className="logo-2">
              <img src="/shipvista.png" alt="shipvista logo" />
            </div>
          </div>
          {!showForgotPasswordPage && (
            <div className="card right">
              <div className="tabs-wrapper">
                <Tabs
                  value={selectedTab}
                  onChange={handleTabChange}
                  variant="fullWidth"
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab icon={<Person />} label="LOGIN" disabled={loading} />
                  <Tab
                    icon={<PersonAdd />}
                    label="REGISTER"
                    disabled={loading}
                  />
                </Tabs>
              </div>
              {selectedTab === 0 && <LoginForm />}
              {selectedTab === 1 && <RegisterForm />}
            </div>
          )}
          {showForgotPasswordPage && (
            <div className="card right">
              <div
                className={classes.backToLoginWrapper}
                onClick={() => {
                  setShowForgotPasswordPage(false);
                }}
              >
                <ArrowBack color="primary" />
                &nbsp;
                <Link className="link">Back to Login</Link>
              </div>
              <ForgotPasswordForm />
            </div>
          )}
        </div>
      </div>
    </LoginPageContext.Provider>
  );
};

export default withRouter(LoginPage);
