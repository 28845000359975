import { useState, useRef, useEffect, useContext, forwardRef } from 'react';
import { useHistory } from 'react-router-dom';
import { ArrowDropDown, Menu, ArrowBack, AccountCircle } from '@material-ui/icons';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';

import MasterPageSidebar from './MasterPageSidebar';

import { MasterPageContext } from './masterPageContext';
import { MENU_MAP, SIDEBAR_MENU_MAP } from './common';
import { useLoseFocus } from '../utilities/useLoseFocus';
import { APP_COLOR } from '../../environment/env_dev';
import MasterPageNotification from './MasterPageNotification';

const MASTER_PAGE_HEADER_STYLE = {
  backgroundColor: APP_COLOR.appLightBlue,
};

const MASTER_PAGE_HEADER_MENU_SELECTED_STYLE = {
  backgroundColor: 'white',
  color: APP_COLOR.appLightBlue,
};

const HAMBURGER_MENU_STYLE = {
  display: 'flex',
  position: 'fixed',
  top: '60px',
  left: '-250px',
  opacity: 0.95,
  zIndex: 99,
  transition: 'all 0.25s',
  WebkitTransition: "all '0.25s'",
};

const HAMBURGER_MENU_STYLE_SHOW = {
  ...HAMBURGER_MENU_STYLE,
  left: 0,
};

const HAMBURGER_MENU_ICON_STYLE = {
  marginLeft: '5px',
  fontSize: '30px',
};

const { ALL: SIDEBAR_MENU_DATA } = SIDEBAR_MENU_MAP;

const RightMenuItemComponent = ({ selected = false, onClick = () => { }, children }, ref) => {
  return (
    <div
      ref={ref}
      className={`master-page-header-menu-right-item${selected ? ' selected' : ''}`}
      style={selected ? MASTER_PAGE_HEADER_MENU_SELECTED_STYLE : {}}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

const RightMenuItem = forwardRef(RightMenuItemComponent);

const MasterPageHeader = ({
  userProfileName = 'MyUser123',
  onLogoClick = () => { },
  onLogoutClick = () => { },
  isMobileView = false,
}) => {
  const history = useHistory();

  const { resolveSelectedMenuKey } = useContext(MasterPageContext);

  const [usernameDropdownVisible, setUsernameDropdownVisible] = useState(false);
  const [notificationDrodownVisible, setNotificationDrodownVisible] = useState(false);
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);

  const usernameDropdownRef = useRef();
  const notificationDropdownRef = useRef();
  const hamburgerMenuRef = useRef();
  const hamburgerMenuLoseFocusRef = useRef(false);

  const [notificationDropdownRefState, setNotificationDropdownRefState] = useState();

  useEffect(() => {
    setShowHamburgerMenu(false);
    setUsernameDropdownVisible(false);
  }, [isMobileView]);

  useEffect(() => {
    setNotificationDropdownRefState(notificationDropdownRef.current);
  }, []); 

  useLoseFocus(hamburgerMenuRef, () => {
    hamburgerMenuLoseFocusRef.current = false;

    if (!showHamburgerMenu) {
      return;
    }

    hamburgerMenuLoseFocusRef.current = true;
    setShowHamburgerMenu(false);
  });

  const onUsernameDropdownClick = () => {
    setUsernameDropdownVisible(!usernameDropdownVisible);
  };

  const renderMenu = () => {
    return (
      <div className="master-page-header-menu">
        <div className="master-page-header-menu-left">
        </div>
        <div className="master-page-header-menu-right">
          <RightMenuItem
            ref={notificationDropdownRef}
            selected={notificationDrodownVisible}
            onClick={() => { setNotificationDrodownVisible(!notificationDrodownVisible); }}
          >
            <MasterPageNotification
              anchorEl={notificationDropdownRefState}
              isMobileView={isMobileView}
              open={notificationDrodownVisible}
              setOpen={(e) => { setNotificationDrodownVisible(e); }}
            />
          </RightMenuItem>
          <RightMenuItem
            ref={usernameDropdownRef}
            selected={usernameDropdownVisible}
            onClick={() => { onUsernameDropdownClick(); }}
          >
            {isMobileView && (<AccountCircle />)}
            {!isMobileView && userProfileName}
            <ArrowDropDown />
          </RightMenuItem>
        </div>
      </div>
    );
  };

  const renderHamburgerMenuIcon = () => {
    return (
      <div
        style={{ display: 'flex', position: 'fixed', cursor: 'pointer' }}
        onClick={() => {
          if (hamburgerMenuLoseFocusRef.current) {
            hamburgerMenuLoseFocusRef.current = false;

            return;
          }

          setShowHamburgerMenu(!showHamburgerMenu);
        }}
      >
        {!showHamburgerMenu && (<Menu style={HAMBURGER_MENU_ICON_STYLE} />)}
        {showHamburgerMenu && (<ArrowBack style={HAMBURGER_MENU_ICON_STYLE} />)}
      </div>
    );
  };

  const getSelectedHamburgerMenuKey = () => {
    const currentRoute = window.location.pathname;

    if (currentRoute === MENU_MAP.CUSTOMER_SUPPORT.route) {
      return MENU_MAP.CUSTOMER_SUPPORT_MY_TICKETS.key;
    }

    return Object.values(MENU_MAP).find((e) => e.route === currentRoute)?.key ?? '';
  };

  const renderHamburgerMenu = () => {
    return (
      <div ref={hamburgerMenuRef}>
        <MasterPageSidebar
          data={SIDEBAR_MENU_DATA}
          selectedKey={getSelectedHamburgerMenuKey()}
          onChange={(key, route) => {
            resolveSelectedMenuKey(route);
            history.push(route);
            setShowHamburgerMenu(false);
          }}
          style={isMobileView && showHamburgerMenu ? HAMBURGER_MENU_STYLE_SHOW : HAMBURGER_MENU_STYLE}
          id="hamburger"
        />
      </div>
    );
  };

  const renderUsernameDropdown = () => {
    return (
      <Popover
        id="username-dropdown"
        open={usernameDropdownVisible}
        anchorEl={usernameDropdownRef.current}
        onClose={() => { setUsernameDropdownVisible(false); }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List dense>
          <ListItem
            button
            onClick={() => {
              onLogoutClick();
              setUsernameDropdownVisible(false);
            }}
          >
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Popover>
    );
  };

  return (
    <div
      className="master-page-header"
      style={MASTER_PAGE_HEADER_STYLE}
    >
      <div className="master-page-header-logo" onClick={onLogoClick}>
        <img src="/shipvista.png" alt="shipvista logo" />
      </div>
      {renderMenu()}
      {isMobileView && renderHamburgerMenuIcon()}
      {renderUsernameDropdown()}
      {renderHamburgerMenu()}
    </div>
  );
};

export default MasterPageHeader;
