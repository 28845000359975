import { BoxesAction, BoxesState, BoxesActions } from "../@types/boxes";

const initialState: BoxesState = {
    boxes: [],
    viewBoxes: [],
    selectedRows: [],
    boxesDetailsOrder: null,
    isTableLoading: "Success",
    boxesPage: null,
    pageIndexGlobal: 0,
};

export function boxesReducer(
    state = initialState,
    action: BoxesAction
): BoxesState {
    switch (action.type) {
        case BoxesActions.LOAD_BOXES_DATA:
            return { ...state, boxes: action.payload }
        case BoxesActions.LOAD_VIEW_BOXES_DATA:
            return { ...state, viewBoxes: action.payload }
        case BoxesActions.SET_SELECTED_ROWS:
            return { ...state, selectedRows: action.payload }
        case BoxesActions.SET_BOXE_DETAILS_BOXE:
            return { ...state, boxesDetailsOrder: action.payload }
        case BoxesActions.SET_BOXES_TABLE_LOADING:
            return { ...state, isTableLoading: action.payload }
        case BoxesActions.SET_BOXES_PAGE:
            return { ...state, boxesPage: action.payload }
        case BoxesActions.SET_PAGE_INDEX:
            return { ...state, pageIndexGlobal: action.payload }
        default:
            return state;
    }
}