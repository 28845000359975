// the library has bug when taking parameter to transform in array item
var objectMapper = require('object-mapper');

export const ticketsMapper = (data: any) => {
    const ticketsPre = objectMapper(data, map);
    const tickets = ticketsPre.map(ticket => {
        const ageNew = daysHoursConverter(ticket.age);
        const resolvedInNew = ticket.resolvedIn ? daysHoursConverter(ticket.resolvedIn) : "Not yet";
        return {
            ...ticket,
            age: ageNew,
            resolvedIn: resolvedInNew
        }
    });
    // console.log(tickets);
    return tickets;
};

const map = {
    "[].id": "[].id",
    "[].summary": "[].summary",
    "[].description": "[].description",
    "[].createdAt": "[].createdAt",
    "[].lastUpdatedAt": "[].lastUpdatedAt",
    "[].age": "[].age",
    "[].status": "[].status",
    "[].attachments[].id": "[].attachments[].id",
    "[].attachments[].fileName": "[].attachments[].fileName",
    "[].isViewed": "[].isViewed",
    "[].createdByName": "[].createdBy",
    "[].createdBy": "[].createdById",
    "[].resolvedIn": "[].resolvedIn",
    "[].assignedToByName": "[].assignee",
    "[].assignedTo": "[].assigneeId",
}

const daysHoursConverter = (data: string) => {
    const days = data.split(".")[0];
    const hours = data.split(".")[1].split(":")[0];
    return `${days}d ${hours}h`;
}