import React from "react";

const styles = {
  body: {
    height: "400px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  } as React.CSSProperties,
};

const attachmentUrl =
  "https://svstaticfiles.blob.core.windows.net/ticket-attachments/";

const ImageDisplayDialog = ({ fileName }) => {
  return (
    <div style={styles.body}>
      <img
        alt={fileName}
        className="img-fluid"
        src={attachmentUrl + fileName}
      />
    </div>
  );
};

export default ImageDisplayDialog;
