import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { PaginChange } from "../../../../@type/Pagination";
// import { PaginChange } from "../../../../@type/Pagination";
import TicketsDialog from "../../../utilities/TicketsDialog";
import NewTicket from "./NewTicket";
import TicketCompletion from "./TicketCompletion";
import TicketItem from "./TicketItem";

interface TicketDialogProps {
    open: boolean;
    setPaginChange: (value: PaginChange) => void;
    setOpen: Dispatch<SetStateAction<boolean>>
}

const TicketDialog = ({
    open,
    setPaginChange,
    setOpen
}: TicketDialogProps) => {
    const [issueNumber, setIssueNumber] = useState(0);
    const [level, setLevel] = useState(0);

    useEffect(() => {
        setLevel(1);
    }, [open]);// eslint-disable-line react-hooks/exhaustive-deps

    return (

        <TicketsDialog
            title={`Create New Tickets`}
            maxWidth="md"
            setOpen={setOpen}
            dialogId="feedback-dialog"
            open={open}
        >
            {(() => {
                switch (level) {
                    case 1:
                        return <NewTicket updateLevel={setLevel} updateIssue={setIssueNumber} />
                    case 2:
                        return <TicketItem updateLevel={setLevel} setPaginChange={setPaginChange} selectedIssue={issueNumber} />
                    case 3:
                        return <TicketCompletion updateLevel={setLevel} />
                    default:
                        return <NewTicket updateLevel={setLevel} updateIssue={setIssueNumber} />
                }
            })()}
        </TicketsDialog>

    );
};

export default TicketDialog;
