import Axios from "axios";
import {config, SERVER_URL} from "../environment/env_dev";

export const getUserService = () => {
    return Axios.get(`${SERVER_URL}/api/User`, config);
};

export const updateUserService = (body) => {
    return Axios.post(`${SERVER_URL}/api/User/UpdateUserDetails`, body, config);
};

export const requestEmailCodeService = () => {
    const domainArr = window.location.href.split("/");
    const link = `${domainArr[0]}//${domainArr[2]}/settings/verify-email`;
    return Axios.get(`${SERVER_URL}/api/User/RequestVerificationEmail?verificationLink=${link}`, config);
};

export const addEmailService = (body: any) => {
    return Axios.post(`${SERVER_URL}/api/User/AddEmail`, body, config);
};

export const verifyEmailService = (token) => {
    return Axios.get(`${SERVER_URL}/api/register/verifyemailaddress?token=${token}`, config);
};

export const getAdminsService = () => {
    return Axios.get(`${SERVER_URL}/api/User/GetUsers?Role=Admin&Role=Employee&Role=HelpdeskAdmin&Limit=20`, config);
}

